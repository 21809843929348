import { ModalWrapper } from './styles/addresidentsuccess-modal.style'

interface AddCaregiverSuccess {
  onSubmit: () => void;
  onCancel: () => void;
  name: string;
};

export const AddCaregiverSuccessModal = ({ onSubmit, onCancel, name }: AddCaregiverSuccess) => {

  return (
    <ModalWrapper>
      <div
        className='modal-resident-success'
      >
        <div
          className="inner"
        >
          <p className="success-title">Success!</p>
          <p className="caregiver-title">
            An invitation has been sent to <span>{name}</span> for PillPez Portal access as a caregiver. Once <span>{name}</span>  accepts the invitation, they may be assigned End-Users.
          </p>
          <div className="buttons-block">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
              className="portal-button"
            >
              Ok
            </a>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};