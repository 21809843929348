import { Columns } from '@PillPez/components';
import { useState, useMemo, useCallback } from 'react';

export type Direction = 'asc' | 'desc'
export type Order<T> = {
    column: number;
    direction: Direction;
    columnName: keyof T;
}

type CommonBetweenPropsAndState<T> = {
    columns: Columns<T>[];
    id?: keyof T;
    searchValue?: string;
    page?: number;
    limit?: number;
    order?: Order<T>;
}

type Props<T> = {
} & CommonBetweenPropsAndState<T>

type OrderForApi = {
    column: number;
    dir: Direction;
}

type Col<T> = {
    data: string;
    name: keyof T;
    searchable: boolean;
    orderable: boolean;
    search?: {
        value: string;
        regex: boolean;
    };
}

export type TableApiPayload<T> = {
    draw: number;
    start: number;
    length: number;
    search?: {
        value: string;
        regex: boolean;
    };
    order?: OrderForApi[];
    columns: Col<T>[]
}

export type TableState<T> = CommonBetweenPropsAndState<T> & {
    payload: TableApiPayload<T>
    setSearch: (search: string) => void;
    setPage: (page: number) => void;
    setLimit: (limit: number) => void;
    orderBy: (column: number, columnName: keyof T, direction: Direction) => void;
    resetView: () => void;
    page: number;
    limit: number;
}

const Defaults = {
    page: 1,
    limit: 10,
}

export const useTableState = <T extends any>(_props: Props<T>): TableState<T> => {
    if (!_props.order) {
        let firstColumnName = _props.columns[0].path;
        _props.order = {
            column: 0,
            columnName: firstColumnName as keyof T,
            direction: 'asc',
        };
    }
    if (!_props.page) {
        _props.page = Defaults.page;
    }
    if (!_props.limit) {
        _props.limit = Defaults.limit;
    }
    const [props, set] = useState<Props<T>>(_props)
    const [initialProps] = useState<Props<T>>(_props)

    const setSearch = useCallback((search: string) => {
        set(props => ({
            ...props,
            searchValue: search,
        }));
    }, []);

    const resetView = useCallback(() => {
        set(initialProps);
    }, []);

    const setPage = useCallback((page: number) => {
        set(props => ({
            ...props,
            page,
        }));
    }, []);

    const setLimit = useCallback((limit: number) => {
        set(props => ({
            ...props,
            limit,
        }));
    }, []);

    const orderBy = useCallback((column: number, columnName: keyof T, direction?: Direction) => {
        set(props => ({
            ...props,
            order: {
                column: column,
                columnName: columnName,
                direction: direction || 'asc',
            },
        }));
    }, []);

    const { columns } = props

    const mapColumnsToApi = useCallback((columns: Columns<T>[]) => {
        return columns.filter(col => typeof col.path === 'string').map((col) => {
            return {
                data: col.path,
                name: col.name,
                searchable: col.searchable || false,
                orderable: col.orderable || false,
                search: {
                    value: props.searchValue || '',
                    regex: false,
                },
            } as Col<T>
        })
    }, [props])

    const mapOrderToApi = useCallback((order: Order<T> | undefined) => {
        if (!order) {
            return undefined;
        }
        return {
            column: columns.findIndex(col => col.path === order.columnName),
            dir: order.direction,
        }
    }, []);

    const payload = useMemo(() => {
        return {
            draw: 1,
            columns: mapColumnsToApi(props.columns),
            order: [mapOrderToApi(props.order)] as OrderForApi[],
            start: (props?.page ? props.page - 1 : Defaults.page - 1) * (props.limit || Defaults.limit),
            length: props.limit || Defaults.limit,
            search: {
                value: props.searchValue || '',
                regex: false,
            }
        }
    }, [props])

    return {
        page: props.page || 1,
        limit: props.limit || Defaults.limit,
        columns,
        payload,
        setSearch,
        setPage,
        setLimit,
        order: props.order,
        orderBy,
        resetView,
    }
}
