import { useMst } from '@PillPez/models';
import {
  ConfirmationMessage,
  SecondaryMessage,
  ConfirmationButtons,
  ModalWrapper,
} from './styles/confirmation-modal.style';
import { useLocation } from 'react-router-dom'

interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  actionFunc: () => void;
  message?: string;
  id?: string;
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { endUserDelete } = useMst();

  return (
    <ModalWrapper>
      <ConfirmationMessage>Are you sure you want to delete this?</ConfirmationMessage>
      <SecondaryMessage>This action cannot be undone</SecondaryMessage>
      <ConfirmationButtons>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.onCancel();

          }}
          className="portal-white-button"
        >
          Cancel
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.onConfirm();
            props.actionFunc();
          }}
          className="portal-button"
        >
          Delete
        </a>
      </ConfirmationButtons>
    </ModalWrapper>
  );
};