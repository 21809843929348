import { types, Instance } from 'mobx-state-tree';
import { getRootEnv } from './env';
export const Screen = types.model({
    name: types.string,
    route: types.string,
})
    .actions(self => ({
        visit() {
            // const navigate = useNavigate();
            console.log(`Visiting ${self.name} -> ${self.route}`);
            getRootEnv(self).history.push(self.route);
        }
    }))
interface IScreen extends Instance<typeof Screen> { }
export const SampleScreensModel = types.model({
    list: types.maybeNull(types.array(Screen)),
}).actions((self) => ({
    switchTo(route: IScreen) {
        route.visit()
    }
}))
