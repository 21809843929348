import { httpClient } from '@PillPez/client';
import { apiErrorsToObject } from '@PillPez/helpers/apiErrorsToObject';
import { AxiosError } from 'axios';
import { types, Instance, flow, toGenerator } from 'mobx-state-tree';
import { AccountFormData } from '../screens/Account';
export type ILoginResponse = {
    access_token: string;
    userData: any;
    user: IUser
}

export type IMeResponse = {
    user: IUser;
}

export enum LoginError {
    NotHandled = 0,
    InvalidCredentials = 1,
    ValidationError = 2,
    NoRoles = 3,
}

export type FieldErrors = {
    [key: string]: string[];
}

export type ApiFieldErrors = {
    location: string;
    msg: string;
    param: string;
    value: string;
}

export type LoginResponse = {
    success: boolean;
    data: ILoginResponse;
}

export type ICaregiver = {
    id?: number;
    allowsNotifications: number;
};

export const LoginErrorModel = types.model({
    error: types.frozen<AxiosError | any>(),
    type: types.frozen<LoginError>(),
    errorPerFields: types.frozen<FieldErrors>(),
})

export const LoginPayload = types.model({
    email: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
}).actions(self => ({
    setEmail(email: string) {
        self.email = email;
    },
    setPassword(password: string) {
        self.password = password;
    },
    payload() {
        return {
            emailAddress: self.email,
            password: self.password,
        }
    }
}));

export interface ILoginPayload extends Instance<typeof LoginPayload> { }
export interface ILoginErrorModel extends Instance<typeof LoginErrorModel> { }

export const UserSettings = types.model({
    test: types.optional(types.maybeNull(types.string), null),
})

export interface IUserSettings extends Instance<typeof UserSettings> { }

export const UserRole = types.model({
    id: types.integer,
    name: types.string,
    description: types.string,
})

export interface IUserRole extends Instance<typeof UserRole> { }

export const User = types.model({
    id: types.maybeNull(types.integer),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    addressLine1: types.maybeNull(types.string),
    addressLine2: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    stateProvince: types.maybeNull(types.string),
    postalCode: types.maybeNull(types.string),
    postalCodePlus4: types.maybeNull(types.string),
    emailAddress: types.maybeNull(types.string),
    primaryPhone: types.maybeNull(types.string),
    smsPhone: types.maybeNull(types.string),
    altPhone: types.maybeNull(types.string),
    settings: types.optional(types.frozen(), {}),
    isActive: types.optional(types.integer, 0),
    allowsNotifications: types.optional(types.integer, 0),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    roles: types.optional(types.array(UserRole), []),
    providerName: types.maybeNull(types.string),
    password: types.maybeNull(types.string),
})

export interface IUser extends Instance<typeof User> { }

export const Auth = types.model({
    loading: types.optional(types.boolean, false),
    token: types.maybeNull(types.string),
    returnTo: types.maybeNull(types.string),
    error: types.maybeNull(LoginErrorModel),
    user: types.maybeNull(User),
    roles: types.optional(types.maybeNull(types.array(UserRole)), null),
    createUserErrors: types.optional(types.frozen<FieldErrors>(), {}),
    updateUserErrors: types.optional(types.frozen<FieldErrors>(), {}),
}).actions((self) => {
    function setToken(token: string) {
        self.token = token;
    }
    function setReturnTo(returnTo: string | null) {
        self.returnTo = returnTo;
    }
    function setError(error: ILoginErrorModel | null) {
        self.error = error;
    }
    function logout() {
        self.token = null;
        self.user = null;
    }
    function setUser(user: IUser | null) {
        if (user?.settings && typeof user?.settings !== 'object') {
            user.settings = null;
        }
        self.user = user;
    }
    function checkRoles(roles: string | string[], strict: boolean = false) {
        if (!self.user) {
            return false;
        }
        let checkRoles = !Array.isArray(roles) ? [roles] : roles;
        if (self.user.roles) {
            let userRoles = self.user.roles.map(r => r.name);
            let hasRequiredRoles = checkRoles[strict ? 'every' : 'some'](r => userRoles.includes(r));
            return hasRequiredRoles;
        }
        return false;
    }
    function isSuperAdmin() {
        if (!self.user) {
            return false;
        }
        if (self.user.roles) {
            let userRoles = self.user.roles.map(r => r.name);
            return userRoles.includes('superAdmin');
        }
        return false;
    }
    return {
        setToken,
        setReturnTo,
        setError,
        logout,
        setUser,
        checkRoles,
        isSuperAdmin,
    }
}).actions(self => {

    function* login(model: ILoginPayload) {
        self.loading = true;
        self.setError(null);
        try {
            const response = yield* toGenerator(httpClient.post<ILoginResponse>('users/login', model.payload()));
            console.log({ data: response.data })
            self.loading = false;
            return { success: true, data: response.data } as LoginResponse;
        } catch (error: unknown) {
            self.loading = false;
            if (error instanceof AxiosError) {
                let baseError = { error, type: LoginError.NotHandled, errorPerFields: {} as FieldErrors };
                if (error?.response?.status === 401) {
                    baseError.type = LoginError.InvalidCredentials;
                } else if (error?.response?.status === 422) {
                    baseError.type = LoginError.ValidationError;
                    let { errors } = error.response.data
                    if (errors) {
                        baseError.errorPerFields = apiErrorsToObject(errors);
                    }
                }
                self.setError(LoginErrorModel.create(baseError));
            } else {
                self.setError(LoginErrorModel.create({ error: error, type: LoginError.NotHandled, errorPerFields: {} as FieldErrors }));
            }
            return { success: false } as LoginResponse;
        }
    }

    function* getUserDetails() {
        try {
            const response = yield* toGenerator(httpClient.get<IMeResponse>('users/me'));
            self.user = response.data.user;
            return response.data.user;
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return null;
            }
            return null;
        }
    }

    function* getRoles() {
        try {
            const response = yield* toGenerator(httpClient.get<{ roles: IUserRole[] }>('roles'));
            if (response.data) {
                // @ts-ignore
                self.roles = response.data.roles;
                return response.data;
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return null;
            }
            return null;
        }
    }
    interface CreateUserProps { user: IUser, roles?: number[] }
    interface CreateUserWithProvider extends CreateUserProps { provider: number; caregiver: never; }
    interface CreateUserWithCaregiver extends CreateUserProps { caregiver: number; provider: never; }

    function* createUser({ user, roles, ...rest }: CreateUserProps | CreateUserWithCaregiver | CreateUserWithProvider) {
        try {
            self.createUserErrors = {}
            const response = yield* toGenerator(httpClient.post<{ status: 'Success' | 'Fail', message: string, user: IUser }>('users', { ...user }));
            if (response.data.status === 'Success') {
                let message = response.data.message;
                let user = response.data.user;
                console.log({ message, user, roles })
                if (roles && roles.length > 0) {
                    const responseRoles = yield* toGenerator(httpClient.put<{ status: 'Success' | 'Fail' }>(`users/${user.id}/roles`, { roles: roles.map(role => ({ id: role })) }));
                    if (responseRoles.data.status === 'Success' && 'provider' in rest) {
                        const responseProvider = yield* toGenerator(httpClient.post<{ status: 'Success' | 'Fail' }>(`providers/${rest.provider}/accountUser/${user.id}`));
                        if (responseProvider.data.status === 'Success') {
                            return { success: true, message, user };
                        } else {
                            return { success: true, message, user };
                        }
                    } else {
                        return { success: true, message, user };
                    }
                } else {
                    return { message, user }
                }
            }
        } catch (error: unknown) {
            console.log({ error })
            if (error instanceof AxiosError) {
                let baseError = { error, type: LoginError.NotHandled, errorPerFields: {} as FieldErrors };
                if (error?.response?.status === 422) {
                    let { errors } = error.response.data
                    if (errors) {
                        self.createUserErrors = apiErrorsToObject(errors);
                    }
                }
                self.setError(LoginErrorModel.create(baseError));
            }
            return null;
        }
    }

    function* updateCurrentUser(data: AccountFormData) {
        try {
            self.updateUserErrors = {}
            const response = yield* toGenerator(httpClient.put<{ status: 'Success' | 'Fail', message: string }>('users/me', {
                ...self.user,
                ...data,
            }));
            if (response.data.status === 'Success') {
                self.user = { ...self.user, ...data } as IUser;
                let message = response.data.message;
                let status = response.data.status;
                return { message, status }
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                if (error?.response?.status === 422) {
                    let { errors } = error.response.data
                    if (errors) {
                        self.updateUserErrors = apiErrorsToObject(errors);
                    }
                }
                return { message: error.message, status: 'Fail' }
            };
        }
        return { message: 'Failure saving user account data', status: 'Fail' }
    }

    function* getCurrentCaregiver() {
        try {
            const response = yield* toGenerator(httpClient.get<{ user: ICaregiver }>('caregivers/me'));
            if (response.data) {
                return response;
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return null;
            }
            return null;
        }
    }

    function* updateCaregiverNotifications(state: boolean) {
        try {
            const response = yield* toGenerator(httpClient.put<{ status: 'Success' | 'Fail', message: string }>(`caregivers/me/sms`, {
                allowsNotifications: state
            }));
            if (response.data.status === 'Success') {
                let message = response.data.message;
                let status = response.data.status;
                return { message, status }
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return { message: error.message, status: 'Fail' }
            };
        }
        return { message: 'Failure saving user account data', status: 'Fail' }
    }

    function* changePassword(newPassword: string) {
        try {
            const response = yield* toGenerator(httpClient.put<{ status: 'Success' | 'Fail', message: string }>(`users/me/password`, {
                password: newPassword
            }));
            if (response.data.status === 'Success') {
                let message = response.data.message;
                let status = response.data.status;
                return { message, status }
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                if (error?.response?.status === 422) {
                    let { errors } = error.response.data
                    if (errors) {
                        return { status: 'Fail', errors: apiErrorsToObject(errors) }
                    }
                }
                return { message: error.message, status: 'Fail' }
            };
        }
        return { message: 'Failure saving user account data', status: 'Fail' }
    }

    function* validateCaregiverUniqueId(id: string) {
        try {
            const response = yield* toGenerator(httpClient.get<{ status: 'Success' | 'Fail', message: string, caregiver: ICaregiver }>(`caregivers/uniqueId/${id}`));
            if (response.data.caregiver) {
                let message = response.data.message;
                let status = response.data.status;
                return { message, status: 'Success', caregiver: response.data.caregiver }
            }
        } catch (error: unknown) {
            if (error instanceof AxiosError) {
                return { message: error.message, status: 'Fail' }
            };
        }
        return { message: 'Failure getting user account data', status: 'Fail' }
    }


    return {
        login: flow(login),
        getUserDetails: flow(getUserDetails),
        getRoles: flow(getRoles),
        createUser: flow(createUser),
        updateCurrentUser: flow(updateCurrentUser),
        getCurrentCaregiver: flow(getCurrentCaregiver),
        updateCaregiverNotifications: flow(updateCaregiverNotifications),
        changePassword: flow(changePassword),
        validateCaregiverUniqueId: flow(validateCaregiverUniqueId),
    }
});
export interface IAuth extends Instance<typeof Auth> { }
