import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { ActionColumn } from './ActionColumn';
import arr_prev from '@PillPez/assets/img/portal/arr_prev.png';
import arr_next from '@PillPez/assets/img/portal/arr_next.png';
import { TableState } from '@PillPez/hooks/useTableState';
import { useEffect, useState } from 'react';
import { ReactComponent as WifiConnected } from '@PillPez/assets/img/wifi-online.svg';

export type Columns<T> = {
  path: string | ((row: T) => string | JSX.Element);
  name: string;
  searchable?: boolean;
  orderable?: boolean;
  hide? : boolean;
  search?: {
    value?: string;
    regex?: boolean;
  };
};

export type PaginationInfo = {
  recordsTotal: number;
  recordsFiltered: number;
};

export interface TableProps<T> {
  id: string;
  tableState?: TableState<T>;
  loadingData?: boolean;
  customStyle?: string;
  columns: Columns<T>[];
  resetView?: boolean;
  data: T[];
  pagination?: PaginationInfo;
  hidePagination?: boolean;
  noDataMessage?: string;
  recordName?: string;
  recordNamePlural?: string;
  action?: () => void;
  actionType?: string;
  onClickEdit?: (id?: string) => void;
}

export const Table = observer(
  <T extends unknown>({
    id,
    loadingData,
    customStyle = '',
    columns,
    resetView = true,
    data = [],
    pagination,
    hidePagination,
    noDataMessage,
    recordName = 'record',
    recordNamePlural = 'records',
    action,
    actionType,
    tableState,
    onClickEdit,
  }: TableProps<T>) => {
    const [lastNumberOfRows, setLastNumberOfRows] = useState(10);
    const pageSize = tableState?.limit || 10;
    const pageNumber = tableState?.page || 1;
    const pages =
      pagination?.recordsTotal && pagination?.recordsTotal > 0
        ? Math.ceil(pagination?.recordsTotal / pageSize)
        : Math.trunc(data.length / pageSize) + 1;
    useEffect(() => {
      if (!loadingData && data.length > 0) {
        setLastNumberOfRows(data.length);
      }
    }, [data, loadingData]);

    if (!tableState) {
      console.warn('TableState is not defined for table');
      return null;
    }
    return (
      <>
        <table
          className={classNames(`${customStyle}`, {
            busy: loadingData,
          })}
        >
          <thead>
            <tr>
              {columns.map(({ path, name, orderable, hide }, idx) => (
                path !== 'id' && hide !== true && (
                  <td
                    key={typeof path === 'string' ? path : `col-${idx}`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (orderable) {
                        tableState.orderBy(
                          idx,
                          path as keyof T,
                          tableState.order?.columnName === path &&
                            tableState.order?.direction === 'asc'
                            ? 'desc'
                            : 'asc'
                        );
                      }
                    }}
                  >
                    <span
                      className={classNames({
                        orderable: orderable,
                        activeSort: tableState.order?.columnName === path,
                        asc:
                          tableState.order?.columnName === path &&
                          tableState.order?.direction === 'asc',
                        desc:
                          tableState.order?.columnName === path &&
                          tableState.order?.direction === 'desc',
                      })}
                    >
                      {name}
                    </span>
                  </td>
                )
              ))}
              <td className="last-column">
                {resetView && (
                  <span
                    onClick={() => {
                      tableState.resetView();
                    }}
                  >
                    Reset view
                  </span>
                )}
              </td>
            </tr>
          </thead>
          <tbody>
            {loadingData &&
              [...Array(lastNumberOfRows)].map((el, i) => (
                <tr key={i}>
                  {columns.map((el: any, i: number) => (
                    <td key={i} className="loading-field">
                      <span></span>
                    </td>
                  ))}
                  {action && actionType && (
                    <ActionColumn action={action} actionType={actionType} />
                  )}
                </tr>
              ))}
            {!loadingData &&
              data.map((rowData: any) => (
                <tr key={rowData[id]}>
                  {columns.map(({ path, hide }, idx: number) => (
                    path !== 'id' &&  hide !== true && (
                      <td
                        key={
                          typeof path === 'function'
                            ? `${rowData[id]}${idx}`
                            : path
                        }
                        className={`${path === 'emailAddress' && 'email-address'
                          }`}
                      >
                        {path === 'emailAddress' ? (
                          <a href={`mailto:${rowData[path]}`}>
                            <span>
                              {rowData[path]}
                            </span>
                            </a>
                        ) : (
                        <span>
                          {typeof path === 'function' ? (
                            path(rowData) || ''
                          ) : rowData[path] == 'online' ? (
                            <div className="device-status">
                              {<WifiConnected />} {rowData[path]}
                            </div>
                          ) : (
                            rowData[path]
                          )}
                        </span>
                        )}
                      </td>
                    )
                  ))}
                  {action && actionType && (
                    <ActionColumn
                      action={action}
                      actionType={actionType}
                      accountId={rowData.id}
                      onClickEdit={onClickEdit}
                    />
                  )}
                </tr>
              ))}
            {data?.length === 0 && !loadingData && (
              <tr className="no-data">
                <td colSpan={columns.length + 1} className="text-center">
                  <p>{noDataMessage ? noDataMessage : 'No data'}</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!hidePagination && (
          <div className="pagination">
            <ul>
              <li>
                <a
                  onClick={() =>
                    pageNumber > 1 && tableState.setPage(pageNumber - 1)
                  }
                  className={pageNumber === 1 ? 'disabled' : ''}
                >
                  <img src={arr_prev} alt="" />
                </a>
              </li>
              {pages <= 10 &&
                [...Array(pages).keys()].map((_, i) => {
                  return (
                    <li key={i}>
                      <a
                        className={pageNumber === i + 1 ? 'current' : ''}
                        onClick={() => tableState.setPage(i + 1)}
                      >
                        {i + 1}
                      </a>
                    </li>
                  );
                })}
              {pages > 10 && (
                <>
                  {paginationGen(pageNumber, pages).map((page, idex) => {
                    return (
                      <li key={`${page}${idex}`}>
                        <a
                          className={pageNumber === page ? 'current' : ''}
                          onClick={() =>
                            page !== '...' &&
                            typeof page == 'number' &&
                            tableState.setPage(page)
                          }
                        >
                          {page}
                        </a>
                      </li>
                    );
                  })}
                </>
              )}
              <li>
                <a
                  onClick={() =>
                    pageNumber < pages && tableState.setPage(pageNumber + 1)
                  }
                  className={pageNumber === pages ? 'disabled' : ''}
                >
                  <img src={arr_next} alt="" />
                </a>
              </li>
            </ul>
            {pagination && (
              <p className="show">
                {data.length > 0 && (
                  <>
                    showing {tableState.payload.start + 1} to{' '}
                    {pageNumber === pages
                      ? pagination.recordsTotal
                      : pagination.recordsFiltered * pageNumber}{' '}
                    {pagination.recordsTotal > 1
                      ? recordNamePlural
                      : recordName}{' '}
                    (<span>{pagination.recordsTotal} total</span>)
                  </>
                )}
              </p>
            )}
            {!pagination && (
              <p className="show">
                showing <span>{data.length}</span>{' '}
                {data.length > 1 ? recordNamePlural : recordName}
              </p>
            )}
          </div>
        )}
      </>
    );
  }
);

function paginationGen(c: number, m: number) {
  let current = c,
    last = m,
    delta = 2,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
}
