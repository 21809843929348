import { PortalFooter, PortalHeader } from '@PillPez/components';
import { tokenProtected } from '@PillPez/hooks/tokenProtected';
import { observer } from 'mobx-react-lite';
import InputMask from 'react-input-mask';

const ProviderAccount = () => {
  return (
    <div id="provider" className="portal-screen provider">
      <PortalHeader />
      <div className="content">
        <form action="">
          <div className="inner">
            <div className="inner-heading">
              <p className="title">Your account</p>
            </div>
            <div className="form-inner">
              <div className="form-row">
                <p className="title">PROVIDER NAME</p>
                <div className="inputs">
                  <div className="input--group">
                    <input
                      type="text"
                      name="provider_name"
                      defaultValue="Portland Home Nursing"
                    />
                  </div>
                </div>
              </div>
              <div className="form-row two-block">
                <p className="title">Main Contact</p>
                <div className="inputs">
                  <div className="input--group">
                    <label htmlFor="">First Name</label>
                    <input type="text" name="name" defaultValue="Harry" />
                  </div>
                  <div className="input--group">
                    <label htmlFor="">Last Name</label>
                    <input type="text" name="surname" defaultValue="Jones" />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <p className="title">Email address</p>
                <div className="inputs">
                  <a href="#" className="account-email">
                    HJ@account.com
                  </a>
                </div>
              </div>
              <div className="form-row two-block">
                <p className="title">Phone Numbers</p>
                <div className="inputs">
                  <div className="input--group">
                    <label htmlFor="">
                      Primary Number <span className="required">*</span>
                    </label>
                    <InputMask
                      className="masked"
                      type="tel"
                      name="primary"
                      mask="(999)-999-9999"
                      placeholder="(___)___-____"
                    />
                  </div>
                  <div className="input--group">
                    <label htmlFor="">Alternate Number </label>
                    <InputMask
                      className="masked"
                      type="tel"
                      name="primary"
                      mask="(999)-999-9999"
                      placeholder="(___)___-____"
                    />
                  </div>
                </div>
              </div>
              <div className="form-row password">
                <p className="title">Password</p>
                <div className="inputs">
                  <div className="input--group">
                    <input
                      type="password"
                      name="password"
                      defaultValue="qweqe228"
                      required
                    />
                  </div>
                  <button className="portal-white-button change-pass-btn">
                    change
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="btns-block">
            <a href="#" className="cancel">
              Cancel
            </a>
            <button className="portal-button">Save</button>
          </div>
        </form>
      </div>
      <PortalFooter />
    </div>
  );
};

// export const ProviderAccountScreen = observer(tokenProtected(ProviderAccount));
export const ProviderAccountScreen = observer(ProviderAccount);
