// import { observer } from 'mobx-react-lite';
import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMst } from '@PillPez/models';
import InputMask from 'react-input-mask';
import {
  ActionButton,
  InputsWrapper,
  InputField,
  InputFieldContent,
  ButtonsWrapper,
  DetailsData,
  DetailsHeader,
  DataRow,
  DataTitle,
  Title,
  ErrorsWrapper,
} from './styles';
import { ReactComponent as EditBtn } from "@PillPez/assets/img/portal/edit-table.svg";
import { ReactComponent as WifiConnected } from "@PillPez/assets/img/wifi-online.svg";
import { ReactComponent as WifiDisconnected } from "@PillPez/assets/img/wifi-offline.svg";
import { Notification } from '../Notification';
import { UpdateEndUserPayload } from '@PillPez/models/Endusers';
import classNames from 'classnames';

interface DetailsProps {
  userData: any;
  actionFunc: any;
};

type FormData = {
  firstName: string;
  lastName: string;
  uniqueId: string;
  primaryPhone: string;
  addressLine1: string;
  wantsNotifications: number;
  emailAddress: string;
};

export const Details = ({ userData, actionFunc }: DetailsProps) => {
  const [editMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState<boolean>();
  const [smsUpdated, setSmsUpdated] = useState(false);
  const [wantsNotifications, setWantsNotifications] = useState<boolean>(userData.wantsNotifications)

  const Input = editMode ? InputField : InputFieldContent;
  const { enduserId } = useParams();
  const { endUserUpdate, endUserNotif, auth } = useMst();
  console.log(userData);

  useEffect(() => {
    document.body.style.overflow = 'unset';
    userData.wantsNotifications === 0 ? setWantsNotifications(false) : setWantsNotifications(true)
  }, [])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    shouldFocusError: false,
  });

  const onFormSubmit = handleSubmit(async (data) => {
    console.log(data);
    if (endUserUpdate.loading) return;
    const model = UpdateEndUserPayload.create({ ...userData, ...data });
    let resp = await endUserUpdate.updateEndUser(enduserId as string, model)
    if (resp?.data?.status === 'Success') {
      setEditMode(false);
      setErrorMessage(false);
      actionFunc();
    } else {
      setErrorMessage(true);
    }
    console.log(resp);
  });

  const updateNotifications = async (payload: any) => {
    if (endUserUpdate.loading) return;
    let resp = await endUserNotif.enduserNotifications(enduserId as string, payload);
    actionFunc();
  }

  return (
    <>
      <form action="" onSubmit={onFormSubmit}>
        <DetailsData>
          <DetailsHeader className="data-header">
            <div className="left">
              <Title className="title">End-User details</Title>
              {!auth.checkRoles('caregiver') && (
                <ActionButton onClick={() => setEditMode(!editMode)}>
                  <EditBtn />
                </ActionButton>
              )}
            </div>
            {userData?.devices && userData?.devices.map((device: any) => {
              return (
                device.status === 'offline' ? (
                  <div className="right" key={device.id}>
                    <p className="online">Offline</p>
                    <WifiDisconnected />
                  </div>
                )
                  : (
                    <div className="right" key={device.id}>
                      <p className="online">Online</p>
                      <WifiConnected />
                    </div>
                  )
              )
            })}
          </DetailsHeader>
          <DataRow>
            <DataTitle>END-USER NAME</DataTitle>
            <InputsWrapper>
              <ErrorsWrapper
                className={classNames('', {
                  'input--group--error':
                    errors.firstName && editMode
                })}
              >
                <Input
                  type="text"
                  defaultValue={userData?.firstName && (userData?.firstName || '')}
                  placeholder={editMode ? "Jane" : ""}
                  {...register('firstName', {
                    required: true,
                  })}

                  aria-autocomplete="list"
                  disabled={!editMode} />
                {editMode && errors.firstName && errors.firstName.type === 'required' && (
                  <span className="error">First Name is required</span>
                )}
              </ErrorsWrapper>
              <ErrorsWrapper
                className={classNames('', {
                  'input--group--error':
                    errors.lastName && editMode
                })}
              >
                <Input
                  type="text"
                  defaultValue={userData?.lastName && (userData?.lastName || '')}
                  placeholder={editMode ? "Cooper" : ""}
                  {...register('lastName', {
                    required: true,
                  })}
                  aria-autocomplete="list"
                  disabled={!editMode} />
                {editMode && errors.lastName && errors.lastName.type === 'required' && (
                  <span className="error">Last Name is required</span>
                )}
              </ErrorsWrapper>
            </InputsWrapper>

          </DataRow>
          <DataRow>
            <DataTitle>END-USER ID#</DataTitle>
            <ErrorsWrapper
              className={classNames('', {
                'input--group--error':
                  errors.uniqueId && editMode
              })}
            >
              <Input
                type="text"
                defaultValue={userData?.uniqueId && (userData?.uniqueId || '')}
                placeholder={editMode ? "555-555-5555555-555" : ""}
                {...register('uniqueId', {
                  required: true,
                })}
                // readOnly
                aria-autocomplete="list"
                disabled={!editMode}
              />
              {editMode && errors.uniqueId && errors.uniqueId.type === 'required' && (
                <span className="error">End-user #ID is required</span>
              )}
            </ErrorsWrapper>
          </DataRow>
          <DataRow>
            <DataTitle>Phone Number</DataTitle>
            <ErrorsWrapper
              className={classNames('', {
                'input--group--error':
                  errors.primaryPhone && editMode
              })}
            >
              <InputMask
                className="masked"
                type="tel"
                key={`${Math.floor((Math.random() * 1000))}-min`}
                defaultValue={userData?.primaryPhone && userData?.primaryPhone || ''}
                placeholder={editMode ? "555-555-5555" : ""}
                mask="(999)-999-9999"
                {...register('primaryPhone', {
                  required: true,
                  // pattern: /^.\[2-9]\d{0,14}$/,
                  maxLength: 14
                })}
                aria-autocomplete="list"
                disabled={!editMode} />
              {editMode && errors.primaryPhone && errors.primaryPhone.type === 'required' && (
                <span className="error">Phone number is required</span>
              )}
              {editMode && errors.primaryPhone && errors.primaryPhone.type === 'pattern' && (
                <span className="error">Phone number cannot start with 0 or 1</span>
              )}
              {editMode && errors.primaryPhone && errors.primaryPhone.type === 'maxLength' && (
                <span className="error">Phone number cannot exceed 10 digits</span>
              )}
            </ErrorsWrapper>
          </DataRow>
          <DataRow>
            <DataTitle>Address</DataTitle>
            <ErrorsWrapper
              className={classNames('', {
                'input--group--error':
                  errors.addressLine1 && editMode
              })}
            >
              <Input
                type="text"
                defaultValue={userData?.addressLine1 && (userData?.addressLine1 || '')}
                placeholder={editMode ? "addres 35 example" : ""}
                {...register('addressLine1', {
                  required: true,
                })}
                aria-autocomplete="list"
                disabled={!editMode} />
              {editMode && errors.addressLine1 && errors.addressLine1.type === 'required' && (
                <span className="error">Address is required</span>
              )}
            </ErrorsWrapper>
          </DataRow>
          <DataRow>
            <DataTitle>Email Address</DataTitle>
            <ErrorsWrapper
              className={classNames('', {
                'input--group--error':
                  errors.emailAddress && editMode
              })}
            >
              <Input
                type="email"
                defaultValue={userData?.emailAddress && (userData?.emailAddress || '')}
                placeholder={editMode ? "email@domain.com" : ""}
                {...register('emailAddress', {
                  required: true,
                })}
                aria-autocomplete="list"
                disabled={!editMode}
              />
              {editMode && errors.emailAddress && errors.emailAddress.type === 'required' && (
                <span className="error">Email is required</span>
              )}
            </ErrorsWrapper>
          </DataRow>
        </DetailsData>
        {errorMessage && <span>ERROR</span>}
        {editMode &&
          <ButtonsWrapper>
            <a href=""
              className="portal-cancel"
              onClick={(e) => {
                e.preventDefault();
                setEditMode(false);
                reset({
                  firstName: userData?.firstName,
                  lastName: userData?.lastName,
                  uniqueId: userData?.uniqueId,
                  primaryPhone: userData?.primaryPhone,
                  addressLine1: userData?.addressLine1,
                })
              }
              }>Cancel</a>
            <button
              className="portal-button"
            >Save</button>
          </ButtonsWrapper>
        }
        {auth.checkRoles('caregiver') && (
          <Notification
            text="Receive phone calls"
            info={
              'If ON, this end user will receive a phone call when they have not dispensed a scheduled pill'
            }
            state={userData.wantsNotifications}
            onChange={(e) => {
              updateNotifications({ ...userData, wantsNotifications: e })
              setWantsNotifications(!wantsNotifications)
            }}
          >
            {smsUpdated && (
              <div className="bg-green-400 p-3 rounded mt-3">
                Notification settings updated
              </div>
            )}
          </Notification>
        )}
      </form >
    </>
  )
}