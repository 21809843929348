import { useNavigate } from 'react-router';
import { ModalWrapper } from './styles/addresidentsuccess-modal.style'

interface AddEndUserSuccess {
  onSubmit: () => void;
  onCancel: () => void;
  name: string;
  id?: string;
};

export const AddEndUserSuccessModal = ({ onSubmit, onCancel, name, id }: AddEndUserSuccess) => {
  const navigate = useNavigate();

  return (
    <ModalWrapper>
      <div
        className='modal-resident-success'
      >
        <div
          className="inner"
        >
          <p className="success-title">Success!</p>
          <p className="title">
            <span>{name}</span> has been added as a end-user
          </p>
          <div className="buttons-block">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onCancel();
              }}
              className="not-right-now-button"
            >
              Not Right Now
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                // onCancel();
                navigate(`/enduser/${id}/details`)
              }}
              className="portal-button"
            >
              Review End-User Details
            </a>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};