import { ApiFieldErrors, FieldErrors } from "@PillPez/models/Auth";

export const apiErrorsToObject = (errors: ApiFieldErrors[]) => {
    let errorPerFields: FieldErrors = {};
    errors.forEach((error: ApiFieldErrors) => {
        if (!errorPerFields[error.param]) {
            errorPerFields[error.param] = [];
        }
        errorPerFields[error.param].push(error.msg);
    }
    );
    return errorPerFields;
}
