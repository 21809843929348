import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import { Provider, rootStore } from '@PillPez/models/Root';
import { PillPezRouter } from './Router';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { globalHistory } from './models/env';


console.log("Environment: ", process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HistoryRouter history={globalHistory}>
    <Provider value={rootStore}>
      <PillPezRouter />
    </Provider>
  </HistoryRouter>
);
