import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 160, 223, 0.24);
  z-index: 500;
`;

export const StyledModal = styled.div`
  border: 2px solid rgba(102, 112, 128, 0.07);
  box-shadow: 0 4px 26px rgb(0 46 111 / 6%);
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;
  border-radius: 10px;
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
`;

export const HeaderText = styled.div`
  color: #fff;
  align-self: center;
  color: lightgray;
`;

export const CloseButton = styled.button`
  font-size: 0.8rem;
  border: none;
  border-radius: 3px;
  margin-left: 0.5rem;
  background: none;
  :hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;
