import { httpClient } from '@PillPez/client';
import { types, Instance, toGenerator, flow } from 'mobx-state-tree';
import { PaginatedResponse, PaginationData } from '@PillPez/types';


export type IDevicesList = {
  uniqueId?: string;
  status?: string;
}

export type IDevicesListResponse = {
  data: IDevicesList[];
  devices?: any;
}

export type DevicesListResponse = {
  data: IDevicesListResponse;
}

export const DevicesListData = types.model({
})

export const DevicesList = types.model({
  data: types.optional(types.array(DevicesListData), []),
  pagination: types.maybe(PaginationData),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* devicesList() {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.get(`devices`));
      self.loading = false;
      return { data: response.data } as DevicesListResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    devicesList: flow(devicesList),
  }
})

export type IDevice = {
}

export type ICreateDeviceResponse = {
  data: IDevice[];
  status: string;
}

export type CreateDeviceResponse = {
  data: ICreateDeviceResponse;
}

export const DeviceData = types.model({
})
export const CreateDevicePayload = types.model({
  type: types.optional(types.string, ''),
  active: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
  uniqueId: types.optional(types.string, ''),
}).actions(self => ({
  setType(type: string) {
    self.type = type;
  },
  setActive(active: string) {
    self.active = active;
  },
  setUniqueId(uniqueId: string) {
    self.uniqueId = uniqueId;
  },
  setStatus(status: string) {
    self.status = status;
  },
  payload() {
    return {
      type: self.type,
      active: self.active,
      status: self.status,
      uniqueId: self.uniqueId,
    }
  }
}));

export interface ICreateDevicePayload extends Instance<typeof CreateDevicePayload> { }


export const CreateDevice = types.model({
  data: types.optional(types.array(DeviceData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* createDevice(model: ICreateDevicePayload) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.post(`devices`, model.payload()));
      self.loading = false;
      return { data: response.data } as CreateDeviceResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    createDevice: flow(createDevice),
  }
})


export type IDeleteDeviceResponse = {
  data: IDevice[]
}

export type DeleteDeviceResponse = {
  data: IDeleteDeviceResponse;
}

export const DeleteDeviceData = types.model({})
export const DeleteDevice = types.model({
  data: types.optional(types.array(DeleteDeviceData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* deleteDevice(id: string | undefined) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.delete(`devices/${id}`));
      self.loading = false;
      return { data: response.data } as DeleteDeviceResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    deleteDevice: flow(deleteDevice),
  }
})


export type IDetachDeviceResponse = {
  data: IDevice[]
}

export type DetachDeviceResponse = {
  data: IDetachDeviceResponse;
}

export const DetachDeviceData = types.model({})
export const DetachDevice = types.model({
  data: types.optional(types.array(DetachDeviceData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* detachDevice(id: string | undefined, enduserId: string | undefined) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.delete(`devices/${id}/endusers/${enduserId}`));
      self.loading = false;
      return { data: response.data } as DetachDeviceResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    detachDevice: flow(detachDevice),
  }
})


export type IAttachDeviceResponse = {
  data: IDevice[]
  status: string;
}

export type AttachDeviceResponse = {
  data: IAttachDeviceResponse;
}

export const AttachDeviceData = types.model({})
export const AttachDevice = types.model({
  data: types.optional(types.array(AttachDeviceData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* attachDevice(id: string | undefined, enduserId: string | undefined) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.put(`devices/${id}/endusers/${enduserId}`));
      self.loading = false;
      return { data: response.data } as AttachDeviceResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    attachDevice: flow(attachDevice),
  }
})

export type IAttachUniqueDeviceResponse = {
  data: IDevice[]
  status: string;
}

export type AttachUniqueDeviceResponse = {
  data: IAttachUniqueDeviceResponse;
}

export const AttachUniqueDeviceData = types.model({})
export const AttachUniqueDevice = types.model({
  data: types.optional(types.array(AttachUniqueDeviceData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* attachUniqueDevice(id: string | undefined, deviceuniqueid: string | undefined) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.put(`endusers/${id}/deviceuniqueid/${deviceuniqueid}`));
      self.loading = false;
      return { data: response.data } as AttachUniqueDeviceResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    attachUniqueDevice: flow(attachUniqueDevice),
  }
})