import { httpClient } from '@PillPez/client';
import { types, toGenerator, flow } from 'mobx-state-tree';
export const SampleData = types.model({})
export const Testing = types.model({
    data: types.optional(types.array(SampleData), []),
}).actions((self) => {
    function* callApi() {
        try {
            const response = yield* toGenerator(httpClient.get('residents'));
            console.log({ data: response.data })
        } catch (error) {
            console.log(error);
        }
    }
    return {
        callApi: flow(callApi),
    }
})
