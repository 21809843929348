import styled, { keyframes } from 'styled-components';

const showIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const fadeIn = keyframes`
0% {
  background: transparent;
}
100% {
  background: #F5F5F7;
}
`;

const fadeOut = keyframes`
0% {
  background: #F5F5F7;
}
100% {
  background: transparent;
}
`;

export const ActionButton = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
`;

export const InputField = styled.input`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #002E6F;
  height: 24px;
  padding: 0 25px;
  background: #F5F5F7;
  border: 1px solid #F5F5F7;
  border-radius: 7px;
  animation: .2s ease-in 0s 1 ${fadeIn};
  width: 99%;
`;

export const InputFieldContent = styled(InputField)`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  border-color: transparent;
  background: none;
  animation: .2s ease-in 0s 1 ${fadeOut};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin: 24px 0;
  animation: .3s ease-in 0s 1 ${showIn};


  .portal-button {
    width: 90px;
    height: 42px;
    margin-left: 15px;
  }
`;

export const DetailsData = styled.div`
  background: #FFFFFF;
  box-shadow: 0 12px 26px rgb(16 30 115 / 6%);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 29px;
`;

export const DetailsHeader = styled.div`
  background-color: #FAFAFA;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 0 35px;

  .left,
  .right {
    display: flex;
    align-items: center;

    .online {
      font-size: 14px;
      line-height: 22px;
      margin-right: 10px;
    }

    svg {
      width: 42px;
      height: 40px;
    }
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DataRow = styled.div`
  display: flex;
  align-items: center;
  min-height: 54px;
  position: relative;
  padding: 18px 35px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 120%;
    height: 2px;
    background-color: #EFEFEF;
    transform: translateX(-50%);
  }

  & input[type="tel"]:disabled {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    border-color: transparent;
    background: none;
    animation: .2s ease-in 0s 1 ${fadeOut};
  }

  & input[type="tel"] {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #002E6F;
    height: 24px;
    padding: 0 25px;
    background: #F5F5F7;
    border: 1px solid #F5F5F7;
    border-radius: 7px;
    animation: .2s ease-in 0s 1 ${fadeIn};
  }

  .alternative {
    display: flex;
    .data-value {
      font-size: 14px;
    }
    .col {
        &:last-child {
            margin-left: 250px;
        }
        p.data-value {
          color: #999EA1;
            span {
                letter-spacing: 0.5px;
                color: #002E6F;
                margin-right: 5px;
            }
        }
    }
}
`;

export const DataTitle = styled.p`
  font-weight: 700;
  min-width: 235px;
  font-size: 14px !important;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  opacity: 0.75;
`;

export const Title = styled.p`
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-right: 25px;
`;

export const ErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & span {
    position: absolute;
    top: -2px;
    font-size: 12px;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SaveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 27px;
  background: #3198CD;
  border-radius: 31px;
  font-family: 'Inter';
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 0;
`;

export const EditPopupCommon = styled.div`
  display: flex;
`;

export const EditPopup = styled.form`
  display: flex;
  flex-direction: column;
  width: 698px;
  background: #ffffff;
  height: 56px;
  position: absolute;
  right: -2px;
  top: 0;
  z-index: 2;

  .error {
    margin-top: -20px;
    z-index: 2;
    margin-left: 10px;
    color: red;
  }

  & .close-edit {
    position: absolute;
    right: -35px;
    top: -31px;
  }

  .time-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    padding-left: 4px;
    z-index: 1;

    & span {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 14px;
      letter-spacing: 0.2em;
      color: #002E6F;
    }

    & input {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.02em;
      color: #002E6F;
      background: #F5F5F7;
      border-radius: 6px;
      border: none;
      padding: 3px 6px;
    }
  }
`;

export const EditPopupAux = styled.div`
  display: flex;
  justify-content: center;
  background: #ffffff;
  min-height: 150px;
  box-shadow: 0px 4px 26px rgba(0, 46, 111, 0.06);
  z-index: 2;
`;

export const EditPopupAuxItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px;

  & p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 22px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #002E6F;
    margin-bottom: 8px;
  }

  & input,
  & textarea {
    background: #F5F5F7;
    border-radius: 6px;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #002E6F;
  }
  
  & input {
    width: 205px;
    height: 28px;
  }

  & textarea {
    width: 290px;
    min-height: 78px;
  }
`;