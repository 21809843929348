import { useMst } from '@PillPez/models';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { ModalWrapper } from './styles/addresidentsuccess-modal.style'

interface AddCaregiverSuccess {
  onSubmit: () => void;
  onCancel: () => void;
  actionFunc: any;
};

type FormData = {
  caregiverId: string;
};

export const AddCareTeamModal = ({ onSubmit, onCancel, actionFunc }: AddCaregiverSuccess) => {
  const navigate = useNavigate();
  const { getCaregivers, caregiverAttachToEnduser } = useMst();
  const { enduserId } = useParams();
  const [caregivers, setCaregivers] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<boolean>();

  useEffect(() => {
    const getCaregiversList = async () => {
      if (getCaregivers.loading) return;
      let resp = await getCaregivers.getCaregivers();
      console.log(resp);
      setCaregivers(resp?.data?.caregivers || []);
    };
    getCaregiversList();
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    shouldFocusError: false,
  });

  const onFormSubmit = handleSubmit(async (data) => {
    console.log(data);
    if (caregiverAttachToEnduser.loading) return;
    let resp = await caregiverAttachToEnduser.attachCaregiverToEnduser(data.caregiverId, enduserId);
    console.log(resp?.data);
    if (resp?.data?.status === 'Success') {
      onSubmit();
      actionFunc();
      setErrorMessage(false)
    } else {
      setErrorMessage(true);
    }
  });

  return (
    <>
      <div
        className='modal-add-care-team-member'
      >
        <div className="inner">
          <p className="title">Assign a Caregiver</p>
          <form action="" onSubmit={onFormSubmit}>
            <div className="row">
              <p className="name">
                Name<span>*</span>
              </p>
              <select
                {...register('caregiverId', {
                  required: true,
                })}
              >
                {caregivers.map((caregiver: any) => {
                  return (
                    <option key={caregiver.id} value={caregiver.id}>{caregiver.lastName}, {caregiver.firstName}</option>
                  )
                })}
              </select>
            </div>
            <p className="info">
              Need to add a caregiver to your organization?  Go to your
              <a
                href=""
                onClick={() => {
                  navigate('/caregiver-summary')
                }}>caregiver summary page</a>
            </p>
            <div className="buttons-block">
              <a href="" className="cancel" onClick={(e) => {
                e.preventDefault();
                onCancel();
              }}>Cancel</a>
              <button className="portal-button">Save</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};