import styled from 'styled-components';

export const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;

  .portal-white-button,
  .portal-button {
    width: 170px;
    height: 40px;
  }

  .portal-button {
    justify-content: center;
    margin-left: 10px;
  }
`;

export const Message = styled.div`
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: center;
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 43px 35px 28px;


  .inner {
      top: 585px;
      
      transition: .3s all ease;
      width: 965px;
      p.heading {
          font-family: "Libre Baskerville", sans-serif;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #0B508F;
      }
      form {
          margin-top: 25px;
          .input--group--error {
            label {
              color: #ff0000;
            }
            input {
              border-color: #ff0000;
            }
          }
          .error {
            color: #ff0000;
            margin-top: 5px;
          }
          
          .form-row {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #EFEFEF;
              height: 111px;
              .left {
                  p.title {
                      opacity: 0.75;
                      font-weight: 700;
                      text-transform: uppercase;
                      margin-bottom: 2px;
                      font-size: 18px;
                      span {
                          color: red;
                      }
                  }
                  a.info {
                      font-family: "Open Sans", sans-serif;
                      font-size: 14px;
                      text-decoration: underline;
                      color: #259FDA;
                  }
              }
              .inputs {
                display: flex;
                justify-content: space-between;
                width: 627px;
                .input--group {
                    width: 298px;
                  label {
                    font-size: 14px;
                    margin-bottom: 5px;
                  }
                  input {
                    height: 54px;
                  }
              }
              &.single {
                  .input--group {
                      width: 100%;
                } 
              }
            }
        }
          .buttons-row {
              margin-top: 23px;
              display: flex;
              align-items: center;
              a.cancel {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 19px;
                  text-decoration-line: underline;
            }
              button.portal-button {
                  margin-left: 15px;
                  height: 42px;
                  width: 90px;
            }
        }
    }
}
  
`;

export const ConfirmationMessage = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -1px;
  color: #002E6F;
  justify-content: center;
`;

export const SecondaryMessage = styled.p`
  margin-top: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #002E6F;
  letter-spacing: 0.4px;
  text-align: center;
  justify-content: center;
`;

