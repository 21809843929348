import { useMst } from '@PillPez/models';
import { useState } from 'react';
import {
  ConfirmationMessage,
  SecondaryMessage,
  ConfirmationButtons,
  ModalWrapper,
} from './styles/confirmation-modal.style';

interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  message?: string;
  id?: string;
}

export const DeleteScheduleEventConfirmationModal = (props: ConfirmationModalProps) => {
  const { deleteEvent } = useMst();
  const [errorMessage, setErrorMessage] = useState<boolean>();
  const deleteScheduleEvent = async (id: string | undefined) => {
    let resp = await deleteEvent.deleteEvent(id);
    if (resp?.data?.status === 'Success') {
      setErrorMessage(false);
      props.onConfirm();
      window.location.reload()
    } else {
      setErrorMessage(true);
    }

    console.log(resp);
  }

  return (
    <ModalWrapper>
      <ConfirmationMessage>Are you sure you want to delete this?</ConfirmationMessage>
      <SecondaryMessage>This action cannot be undone</SecondaryMessage>
      {errorMessage && <div style={{ color: 'red', textAlign: 'center' }}>Event cannot be deleted!</div>}
      <ConfirmationButtons>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.onCancel();

          }}
          className="portal-white-button"
        >
          Cancel
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            deleteScheduleEvent(props.id)
            console.log(props.id)
          }}
          className="portal-button"
        >
          Delete
        </a>
      </ConfirmationButtons>
    </ModalWrapper >
  );
};