import { Notification, PortalFooter, PortalHeader } from '@PillPez/components';
import { observer } from 'mobx-react-lite';
import InputMask from 'react-input-mask';

const CaregiverAccount = () => {
  return (
    <div id="care-giver" className="portal-screen provider care-giver">
      <PortalHeader />
      <PortalFooter />
    </div>
  );
};

export const CaregiverAccountScreen = observer(CaregiverAccount);
