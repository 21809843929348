import styled from 'styled-components';

export const ModalWrapper = styled.div`
  width: 1035px;
  height: 525px;
  padding: 43px 115px 28px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ConfirmationMessage = styled.p`
  font-family: "Libre Baskerville", sans-serif;
  font-weight: 400;
  font-size: 50px;
  line-height: 62px;
  letter-spacing: -1px;
  color: #002E6F;
  justify-content: center;
`;

export const SecondaryMessage = styled.div`
  margin-top: 18px;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #002E6F;
  letter-spacing: 0.4px;
  text-align: center;
  justify-content: center;
  font-family: "Inter", sans-serif;

  & span {
    font-weight: 700;
    font-size: 30px;
  }
`;


export const TertiaryMessage = styled.div`
  margin-top: 18px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  font-style: italic;
  color: #002E6F;
  letter-spacing: 0.4px;
  text-align: left;
  max-width: 615px;
  justify-content: center;
  font-family: "Inter", sans-serif;

  & span {
    font-weight: 700;
    font-size: 30px;
  }
`;

export const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 54px;

  .portal-cancel {
    margin-right: 27px;
  }

  .portal-button {
    width: 250px;
    height: 49px;
    justify-content: center;
  }
`;