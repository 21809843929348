import { ReactComponent as EyeClosed } from '@PillPez/assets/img/portal/eyeclosed.svg';
import { observer } from 'mobx-react-lite';
import { ChangeEventHandler, useState } from 'react';
import classNames from 'classnames';
import { InternalFieldName, UseFormRegisterReturn } from 'react-hook-form';
import styled from 'styled-components';

type Props<T extends string> = {
  label?: string;
  placeholder?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
  validation?: UseFormRegisterReturn<T>;
  hasError?: boolean;
  children?: React.ReactNode;
};

const Content = styled.div`
  &.input--group--error {
    label {
      color: #ff0000;
    }
    input {
      border-color: #ff0000;
    }
    .error {
      color: #ff0000;
      margin-top: 5px;
    }
    .show-pass {
      bottom: 45px !important;
    }
  }
`;

export const PasswordField = observer(function <T extends InternalFieldName>({
  label,
  placeholder,
  value,
  onChange,
  required,
  validation,
  hasError = false,
  children,
}: Props<T>) {
  const [show, setShow] = useState(false);
  const type = show ? 'text' : 'password';
  return (
    <Content
      className={classNames('input--group', {
        'input--group--error': hasError,
      })}
    >
      {typeof label !== 'undefined' && (
        <label htmlFor="">
          {label} {required && <span className="required">*</span>}
        </label>
      )}
      <input
        type={type}
        placeholder={placeholder ? placeholder : ''}
        defaultValue={value}
        {...(validation
          ? validation
          : { onChange: onChange, required: required })}
      />
      {children}
      <span className={classNames('show-pass', { opacity: show })}>
        <EyeClosed
          onClick={(e) => {
            setShow(!show);
          }}
        />
      </span>
    </Content>
  );
});
