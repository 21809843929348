import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

type Props = {
  title: string;
  secondaryTitle: string;
  buttonText: string;
  action?: any;
  href?: string;
};

export const ItemBox = observer(function ({
  title,
  secondaryTitle,
  buttonText,
  action,
  href,
}: Props) {
  const navigate = useNavigate();
  return (
    <div className="item">
      <div className="desc">
        <p className="title">{title}</p>
        <p className="second">{secondaryTitle}</p>
      </div>
      <a
        href={href}
        onClick={
          action
            ? action
            : (e) => {
                e.preventDefault();
                navigate({ pathname: href });
              }
        }
        className="portal-button add-resident-button"
      >
        {buttonText}
      </a>
    </div>
  );
});
