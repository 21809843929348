import { Instance, onSnapshot, types } from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import { rootEnv } from './env';
import { SampleScreensModel } from './SampleScreens';
import { Auth } from './Auth';
import { Testing } from './Testing';
import {
  EndUsers,
  EndUsersList,
  ViewEndUser,
  UpdateEndUser,
  EnduserNotifications,
  CreateEndUser,
  DeleteEndUser,
  GetEndUserSchedule,
} from './Endusers'
import { RootSuperModel } from './Super/RootSuperModel';
import { CaregiverList, ViewCaregiver, UpdateCaregiver, CreateCaregiver, DeleteCaregiver, GetCaregivers, AttachCaregiverToEnduser, DetachCaregiverToEnduser } from './Caregivers';
import { CreateDevice, DevicesList, DeleteDevice, DetachDevice, AttachDevice, AttachUniqueDevice } from './Devices';
import { Events, AddEvent, GetEvent, DeleteEvent, UpdateEvent } from './Events';
import { Search } from './Search';

const RootModel = types
  .model({
    sample_screens: types.maybeNull(SampleScreensModel),
    auth: types.optional(Auth, {}),
    testing: types.optional(Testing, {}),
    events: types.optional(Events, {}),
    addEvent: types.optional(AddEvent, {}),
    getEvent: types.optional(GetEvent, {}),
    updateEvent: types.optional(UpdateEvent, {}),
    deleteEvent: types.optional(DeleteEvent, {}),
    createDevice: types.optional(CreateDevice, {}),
    deleteDevice: types.optional(DeleteDevice, {}),
    detachDevice: types.optional(DetachDevice, {}),
    attachDevice: types.optional(AttachDevice, {}),
    attachUniqueDevice: types.optional(AttachUniqueDevice, {}),
    devicesList: types.optional(DevicesList, {}),
    endUsers: types.optional(EndUsers, {}),
    endUsersList: types.optional(EndUsersList, {}),
    endUserView: types.optional(ViewEndUser, {}),
    endUserUpdate: types.optional(UpdateEndUser, {}),
    endUserNotif: types.optional(EnduserNotifications, {}),
    endUserCreate: types.optional(CreateEndUser, {}),
    endUserDelete: types.optional(DeleteEndUser, {}),
    endUserSchedule: types.optional(GetEndUserSchedule, {}),
    caregiverList: types.optional(CaregiverList, {}),
    getCaregivers: types.optional(GetCaregivers, {}),
    caregiverCreate: types.optional(CreateCaregiver, {}),
    caregiverDelete: types.optional(DeleteCaregiver, {}),
    caregiverAttachToEnduser: types.optional(AttachCaregiverToEnduser, {}),
    caregiverDetachToEnduser: types.optional(DetachCaregiverToEnduser, {}),
    viewCaregiver: types.optional(ViewCaregiver, {}),
    updateCaregiver: types.optional(UpdateCaregiver, {}),
    superStore: types.optional(RootSuperModel, {}),
    search: types.optional(Search, {}),
  })
  .actions((self) => ({}));

const defaultState = {
  sample_screens: {
    list: [
      { name: 'Login', route: '/login' },
      { name: 'Account set up', route: '/account-set-up' },
      { name: 'Provider account', route: '/provider-account' },
      { name: 'Caregiver account', route: '/caregiver-account' },
      { name: 'Enduser Summary', route: '/enduser-summary' },
      { name: 'Enduser Details', route: '/enduser/details' },
      { name: 'Caregiver Summary', route: '/caregiver-summary' },
      { name: 'Caregiver Details', route: '/caregiver' },
    ]
  },
  auth: {
    loading: false,
  }
}
let initialState = RootModel.create(defaultState, rootEnv);

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  window.resetState = () => {
    localStorage.removeItem('rootState');
    window.location.reload()
  }
}

const data = localStorage.getItem('rootState');
if (data) {
  const json = JSON.parse(data);
  if (RootModel.is(json)) {
    let newState = { ...defaultState, auth: { ...defaultState.auth, token: json.auth?.token, user: json.auth?.user } };
    initialState = RootModel.create(newState, rootEnv);
  }
}

export const rootStore = initialState;

onSnapshot(rootStore, (snapshot) => {
  // console.log('Snapshot: ', snapshot);
  localStorage.setItem('rootState', JSON.stringify(snapshot));
});

export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);

export const Provider = RootStoreContext.Provider;
export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
