import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TableBasket } from '@PillPez/assets/img/portal/table-basket.svg';
import { ReactComponent as EditBtn } from '@PillPez/assets/img/portal/edit-table.svg';
import { Modal } from '@PillPez/components/Modals/DefaultModal/Modal';
import { useModal } from '@PillPez/components/Modals/DefaultModal/useModal';
import { SummaryConfirmationModal } from '@PillPez/components/Modals/SummaryConfirmationModal';
import { DeleteDeviceConfirmationModal } from '@PillPez/components/Modals/DeviceConfirmationModal';
import { DeleteCareTeamConfirmationModal } from '@PillPez/components/Modals/CareTeamConfirmationModal';
import { useMst } from '@PillPez/models';

type Props = {
  accountId?: string;
  action: () => void;
  actionType: string;
  onClickEdit?: (id?: string) => void;
};

export const ActionColumn = observer(function ({
  accountId,
  action,
  actionType,
  onClickEdit,
}: Props) {
  const navigate = useNavigate();
  const { auth } = useMst();
  const { isShown: modalDeleteOpened, toggle: modalDeleteToggle } = useModal();
  let ModalDisplayed;

  switch (actionType) {
    case 'deleteDevice':
      ModalDisplayed = DeleteDeviceConfirmationModal;
      break;
    case 'deleteCareTeam':
      ModalDisplayed = DeleteCareTeamConfirmationModal;
      break;
    default:
      ModalDisplayed = SummaryConfirmationModal;
      break;
  }

  const actionButtons = () => {
    if (actionType.includes('edit') && actionType.includes('review')) {
      return (
        <>
          <a
            href=""
            className="portal-button"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/caregiver/${accountId}`);
            }}
          >
            Review Caregiver
          </a>
          <a
            href=""
            className="edit-table-row-btn"
            onClick={(e) => {
              e.preventDefault();
              if (onClickEdit) {
                onClickEdit(accountId);
              } else {
                navigate(`/caregiver/${accountId}?editMode=true`);
              }
            }}
          >
            <EditBtn />
          </a>
        </>
      );
    } else if (actionType.includes('review')) {
      return (
        <a
          href=""
          className="portal-button"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/enduser/${accountId}/details`);
          }}
        >
          Review End-user
        </a>
      );
    } else if (actionType.includes('edit')) {
      return (
        <a
          href=""
          className="edit-table-row-btn"
          onClick={(e) => {
            e.preventDefault();
            if (onClickEdit) {
              onClickEdit(accountId);
            } else {
              navigate(`/caregiver/${accountId}?editMode=true`);
            }
          }}
        >
          <EditBtn />
        </a>
      );
    }
  };

  return (
    <>
      <td className="last-col">
        <div className="btns">
          {actionButtons()}
          {auth.checkRoles('provider') && (
            <a
              onClick={() => {
                modalDeleteToggle();
              }}
              className="delete-btn"
            >
              <TableBasket />
            </a>
          )}
        </div>
      </td>

      <Modal
        isShown={modalDeleteOpened}
        hide={modalDeleteToggle}
        modalContent={
          <ModalDisplayed
            id={accountId}
            onConfirm={modalDeleteToggle}
            onCancel={modalDeleteToggle}
            actionFunc={action}
          />
        }
      />
    </>
  );
});
