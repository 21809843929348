import { httpClient } from '@PillPez/client';
import { TableApiPayload } from '@PillPez/hooks/useTableState';
import { PaginatedResponse, PaginationData } from '@PillPez/types';
import { types, Instance, toGenerator, flow } from 'mobx-state-tree';
import { StringLiteralLike } from 'typescript';
import { TableState } from '../hooks/useTableState';

export interface IEndUserSettings {
  key1: string;
}

export interface ICareTeam {
  id: number;
  firstName: string;
  lastName: string;
  primaryPhone: number;
  altPhone: any;
  emailAddress: string;
}

export interface IEndUser {
  id: number;
  firstName: string;
  lastName: string;
  nickName: string;
  addressLine1: string;
  caregivers: ICareTeam;
  addressLine2: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  postalCodePlus4: string;
  primaryPhone: string;
  uniqueId: string;
  pin: string;
  wantsNotifications: number;
  settings: IEndUserSettings;
  adherence: string;
  createdAt: Date;
  updatedAt?: Date;
  devices: any;
}

export type IGetEndUsersResponse = {  
  endusers: IEndUser[]
}

export type GetEndUsersResponse = {
  data: IGetEndUsersResponse
}

export const EndUsersData = types.model({})
export const EndUsers = types.model({
  data: types.optional(types.array(EndUsersData), []),
  pagination: types.maybe(PaginationData),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* endusers(payload: TableApiPayload<IEndUser>) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.post<PaginatedResponse<IEndUser>>('endusers/table', payload));
      self.loading = false;
      self.data = response.data.data as unknown as any;
      self.pagination = {
        recordsFiltered: response.data.recordsFiltered,
        recordsTotal: response.data.recordsTotal,
      }
      return response.data
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    getEndUsersTable: flow(endusers),
  }
})

export interface IGetEndUsersData extends Instance<typeof EndUsersData> { }

// GET EndUserS
export type IGetEndUsersListResponse = {
  data: IEndUser[];
  endusers: IEndUser[];
  caregivers?: ICareTeam[];
}

export type GetEndUsersListResponse = {
  data: IEndUser[]
}
export const EndUsersListData = types.model({
  id: types.maybe(types.number),
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  emailAddress: types.maybe(types.string),
  adherence: types.maybe(types.string),
})
export const EndUsersList = types.model({
  data: types.optional(types.array(EndUsersListData), []),
  pagination: types.maybe(PaginationData),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* endusersList(payload: TableApiPayload<IEndUser>) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.post<PaginatedResponse<IEndUser>>('/endusers/table', payload));
      self.loading = false;
      self.data = response.data.data as unknown as any;
      self.pagination = {
        recordsFiltered: response.data.recordsFiltered,
        recordsTotal: response.data.recordsTotal,
      }
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    getEndUsersList: flow(endusersList),
  }
})

// View END-USER
export type IViewEndUserListResponse = {
  data: IEndUser[];
  enduser: IEndUser;
}

export type ViewEndUserListResponse = {
  data: IViewEndUserListResponse
}
export const ViewEndUserData = types.model({})
export const ViewEndUser = types.model({
  data: types.optional(types.array(ViewEndUserData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* viewEndUser(id: string) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.get(`/endusers/${id}`));
      self.loading = false;
      return { data: response.data } as ViewEndUserListResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    viewEndUser: flow(viewEndUser),
  }
})



export type IEnduserNotificationsListResponse = {
  data: IEndUser[];
  enduser: IEndUser;
}

export type EnduserNotificationsListResponse = {
  data: IEnduserNotificationsListResponse
}
export const EnduserNotificationsData = types.model({})
export const EnduserNotifications = types.model({
  data: types.optional(types.array(EnduserNotificationsData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* enduserNotifications(id: string, payload: any) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.put(`/endusers/${id}`, payload));
      self.loading = false;
      return { data: response.data } as EnduserNotificationsListResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    enduserNotifications: flow(enduserNotifications),
  }
})

// Update RESIDENT
export type IUpdateEndUserResponse = {
  data: IEndUser[]
  status: string;
}

export type UpdateEndUserResponse = {
  data: IUpdateEndUserResponse;
}

export const UpdateEndUserPayload = types.model({
  firstName: types.optional(types.string, ''),
  lastName: types.optional(types.string, ''),
  uniqueId: types.optional(types.string, ''),
  primaryPhone: types.maybeNull(types.string),
  addressLine1: types.optional(types.string, ''),
  emailAddress: types.maybeNull(types.string),
  notes: types.optional(types.string, ''),
  wantsNotifications: types.optional(types.boolean, false),
}).actions(self => ({
  setFirstName(firstName: string) {
    self.firstName = firstName;
  },
  setLastName(lastName: string) {
    self.lastName = lastName;
  },
  setUniqueId(uniqueId: string) {
    self.uniqueId = uniqueId;
  },
  setEmailAddress(emailAddress: string) {
    self.emailAddress = emailAddress;
  },
  setNotes(notes: string) {
    self.notes = notes;
  },
  setPrimaryPhone(primaryPhone: string) {
    self.primaryPhone = primaryPhone;
  },
  setAddressLine1(addressLine1: string) {
    self.addressLine1 = addressLine1;
  },
  setWantsNotifications(wantsNotifications: boolean) {
    self.wantsNotifications = wantsNotifications;
  },
  payload() {
    return {
      firstName: self.firstName,
      lastName: self.lastName,
      uniqueId: self.uniqueId,
      primaryPhone: self.primaryPhone,
      addressLine1: self.addressLine1,
      wantsNotifications: self.wantsNotifications,
      emailAddress: self.emailAddress,
      notes: self.notes,
    }
  }
}));

export interface IUpdateEndUserPayload extends Instance<typeof UpdateEndUserPayload> { }

export const UpdateEndUserData = types.model({})
export const UpdateEndUser = types.model({
  data: types.optional(types.array(UpdateEndUserData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* updateEndUser(id: string, model: IUpdateEndUserPayload) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.put(`endusers/${id}`, model.payload()));
      self.loading = false;
      return { data: response.data } as UpdateEndUserResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    updateEndUser: flow(updateEndUser),
  }
})

// Create RESIDENT
export type ICreateEndUserResponse = {
  data: IEndUser[];
  status: string;
  enduser: any;
}

export type CreateEndUserResponse = {
  data: ICreateEndUserResponse;
  success: boolean;
  error: any;
}

export const CreateEndUserPayload = types.model({
  firstName: types.optional(types.string, ''),
  lastName: types.optional(types.string, ''),
  uniqueId: types.optional(types.string, ''),
  deviceId: types.optional(types.string, ''),
}).actions(self => ({
  setFirstName(firstName: string) {
    self.firstName = firstName;
  },
  setLastName(lastName: string) {
    self.lastName = lastName;
  },
  setUniqueId(uniqueId: string) {
    self.uniqueId = uniqueId;
  },
  setDeviceId(deviceId: string) {
    self.deviceId = deviceId;
  },
  payload() {
    return {
      firstName: self.firstName,
      lastName: self.lastName,
      uniqueId: self.uniqueId,
      deviceId: self.deviceId,
    }
  }
}));

export interface ICreateEndUserPayload extends Instance<typeof CreateEndUserPayload> { }


export const CreateEndUserData = types.model({})
export const CreateEndUser = types.model({
  data: types.optional(types.array(CreateEndUserData), []),
  loading: types.optional(types.boolean, false),
  error: types.maybeNull(types.boolean),
}).actions((self) => {
  function* createEndUser(model: ICreateEndUserPayload) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.post('endusers', model.payload()));
      self.loading = false;
      return { data: response.data } as CreateEndUserResponse
    } catch (error: unknown) {
      self.loading = false;
      console.log(error);
      return { success: false, error: error } as CreateEndUserResponse
    }
  }
  return {
    createEndUser: flow(createEndUser),
  }
})

// Delete End-User
export type IDeleteEndUserResponse = {
  data: IEndUser[]
}

export type DeleteEndUserResponse = {
  data: IDeleteEndUserResponse;
}

export const DeleteEndUserData = types.model({})
export const DeleteEndUser = types.model({
  data: types.optional(types.array(DeleteEndUserData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* deleteEndUser(id: string) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.delete(`endusers/${id}`));
      self.loading = false;
      return { data: response.data } as DeleteEndUserResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    deleteEndUser: flow(deleteEndUser),
  }
})

export interface IEndUserSchedule {
  days: object
}

export type IGetEndUserScheduleResponse = {
  data: IEndUserSchedule[]
  schedule: any;
  days: any;
}

export type GetEndUserScheduleResponse = {
  data: IGetEndUserScheduleResponse;
}

export const GetEndUserScheduleData = types.model({})
export const GetEndUserSchedule = types.model({
  data: types.optional(types.array(GetEndUserScheduleData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* getEndUserSchedule(id: string | undefined, startDate: string) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.get(`endusers/${id}/schedule`, {
        params: {
          startDate: startDate
        }
      }));
      self.loading = false;
      return { data: response.data } as GetEndUserScheduleResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    getEndUserSchedule: flow(getEndUserSchedule),
  }
})
