import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useMst } from '@PillPez/models/Root';
import { useTableState } from '@PillPez/hooks/useTableState';
import {
  PortalFooter,
  PortalHeader,
  ItemBox,
  Table,
  Columns,
} from '@PillPez/components';
import { useModal } from '@PillPez/components/Modals/DefaultModal/useModal';
import { Modal } from '@PillPez/components/Modals/DefaultModal/Modal';
import { AddCaregiverModal } from '@PillPez/components/Modals/AddCaregiverModal';
import { AddCaregiverSuccessModal } from '@PillPez/components/Modals/AddCaregiverSuccessModal';
import { ProgressBar } from '@PillPez/components/UI/ProgressBar';
import { ICaregiver } from '@PillPez/models/Caregivers';
import { IEndUser } from '@PillPez/models/Endusers';
import { IPaginationData } from '@PillPez/types';
import { getCurrentDate } from '@PillPez/helpers/functions';

const CaregiverSummary = () => {
  const { isShown: modalDeleteOpened, toggle: modalDeleteToggle } = useModal();
  const {
    isShown: modalCaregiverOpened,
    toggle: modalCaregiverToggle
  } = useModal();
  const [enduserData, setEnduserData] = useState<IEndUser[]>([]);
  const [paginationData, setPaginationData] = useState<IPaginationData>({
    recordsTotal: 0,
    recordsFiltered: 0,
  });
  const { isShown: modalOpened, toggle: modalSuccessToggle } = useModal();
  const { caregiverList, endUsers, auth } = useMst();
  const [name, setName] = useState('')
  const createCaregiver = (name: string) => {
    modalSuccessToggle();
    modalCaregiverToggle();
    setName(name as string);
  }

  const navigate = useNavigate();

  const stateCaregivers = useRef({
    columns: [
      {
        path: 'firstName',
        name: 'First Name',
        searchable: true,
        orderable: true,
      },
      {
        path: 'id',
        name: 'ID',
        searchable: true,
        orderable: true,
      },
      {
        path: 'lastName',
        name: 'Last Name',
        searchable: true,
        orderable: true
      },
      {
        path: 'numberOfUsers',
        name: '# of End-Users',
        orderable: true,
        searchable: true,
      },
      {
        path: 'emailAddress',
        name: 'Email Address',
        searchable: true,
        orderable: true,
      },
    ] as Columns<ICaregiver>[],
  });
  const stateEndUsers = useRef({
    columns: [
      {
        path: 'firstName',
        name: 'First Name',
        searchable: true,
        orderable: true,
      },
      {
        path: 'lastName',
        name: 'Last Name',
        searchable: true,
        orderable: true,
      },
      {
        path: 'uniqueId',
        name: 'End-User ID',
        searchable: true,
        orderable: true,
      },
      {
        path: 'id',
        name: 'ID',
        searchable: true,
        orderable: true,
      },
      {
        // path: (el) => `${auth.user?.firstName} ${auth.user?.lastName}`,
        path: 'caregiver',
        name: 'Primary Caregiver',
        searchable: true,
        orderable: true,
      },
      { path: 'adherence', hide: true },
      {
        path: (row) => <ProgressBar progress={row.adherence} />,
        name: 'Adherence Score',
        searchable: true,
        orderable: true,
      },
    ] as Columns<IEndUser>[],
  });

  const tableStateCaregivers = useTableState(stateCaregivers.current);
  const tableStateEndUsers = useTableState(stateEndUsers.current);

  useEffect(() => {
    if (auth.checkRoles('provider')) {
      caregiverList.getCaregiversTable(tableStateCaregivers.payload);
    }
  }, [tableStateCaregivers.payload, modalOpened, modalDeleteOpened]);

  useEffect(() => {
    console.log(tableStateEndUsers.payload)
    console.log(tableStateEndUsers.columns)
    // if (auth.checkRoles('caregiver')) {
    //   endUsers.getEndUsersTable(tableStateEndUsers.payload);
    //   // console.log(endUsersList.getEndUsersList(tableStateEndUsers.payload))
    // }
    if (auth.checkRoles('caregiver')) {
      endUsers.getEndUsersTable(tableStateEndUsers.payload).then((res) => {
        setEnduserData((res?.data || []) as IEndUser[]);
        setPaginationData({
          recordsTotal: res?.recordsTotal || 0,
          recordsFiltered: res?.recordsFiltered || 0,
        });
      });
    }
  }, [tableStateEndUsers.payload]);

  return (
    <div id="caregiver-summary" className="portal-screen caregiver-summary">
      <PortalHeader />
      <div className="content">
        <h1>
          The dashboard for {auth?.user?.firstName} {auth?.user?.lastName}
        </h1>
        {/* <p className="secondary">Your Dashboard for {getCurrentDate()}</p> */}
        {auth.checkRoles('provider') && (
          <div className="add-new-block">
            <ItemBox
              title="Add New Caregiver"
              secondaryTitle="Have caregiver’s email ready"
              buttonText="+ Add Caregiver"
              action={(e: any) => {
                e.preventDefault();
                modalCaregiverToggle();
              }}
            />
            <ItemBox
              title="Your End-Users"
              secondaryTitle="Add, edit, and view your end-users"
              buttonText="Manage End-users"
              href="/enduser-summary"
            />
          </div>
        )}
        <div className="residents-block">
          {auth.checkRoles('provider') && (
            <>
              <h2>Your Caregivers</h2>
              <Table
                id="id"
                columns={tableStateCaregivers.columns}
                loadingData={caregiverList.loading}
                data={caregiverList.data as ICaregiver[]}
                action={modalDeleteToggle}
                tableState={tableStateCaregivers}
                actionType="edit review"
                pagination={caregiverList.pagination}
              />
            </>
          )}
          {auth.checkRoles('caregiver') && (
            <>
              <h2>Your End-users</h2>
              <Table
                id="id"
                columns={tableStateEndUsers.columns}
                loadingData={endUsers.loading}
                data={enduserData}
                action={modalDeleteToggle}
                tableState={tableStateEndUsers}
                actionType="review"
                pagination={paginationData}
                onClickEdit={(id) => {
                  navigate(`/enduser/${id}/details`);
                }}
              />
            </>
          )}
        </div>
      </div>

      <PortalFooter />

      <Modal
        isShown={modalCaregiverOpened}
        hide={modalCaregiverToggle}
        modalContent={
          <AddCaregiverModal
            onSubmit={createCaregiver}
            onCancel={modalCaregiverToggle}
          />
        }
      />

      <Modal
        isShown={modalOpened}
        hide={modalSuccessToggle}
        modalContent={
          <AddCaregiverSuccessModal
            onSubmit={modalSuccessToggle}
            onCancel={modalSuccessToggle}
            name={name}
          />
        }
      />
    </div>
  );
};

export const CaregiverSummaryScreen = observer(CaregiverSummary);
