import { observer } from 'mobx-react-lite';
import { ReactComponent as FooterBg } from '@PillPez/assets/img/portal/footer-bg.svg';
import { ReactComponent as Twitter } from '@PillPez/assets/img/portal/footer_twitter.svg';
import { ReactComponent as Facebook } from '@PillPez/assets/img/portal/footer_fb.svg';
import { ReactComponent as LinkedIn } from '@PillPez/assets/img/portal/footer_ln.svg';
const Footer = () => {
  return (
    <footer className="portal">
      <div className="row">
        <div className="left">
          <FooterBg />
          <p className="desc">
            Powered by PillPez® HIPAA Compliant Cloud Service
          </p>
        </div>
        <div className="right">
          <div className="col">
          </div>
          <div className="col">
            <p className="title">Learn More</p>
            <ul className="menu-footer">
              <li>
                <a href="https://www.pillpez.com/" target="_blank">About</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row rights">
        <div className="rights-block">
          <p className="right">PillPez Patent Pending</p>
          <p className="right">California End-Users Data Rights Notice</p>
          <p className="right">© 2022 Big Sky Labs, Inc. All rights reserved</p>
        </div>
        <div className="follow-us">
          <p className="title">Follow Us</p>
          <ul className="socials">
            <li>
              <a href="https://twitter.com/PillPez" target="_blank">
                <Twitter />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/PillPez" target="_blank">
                <Facebook />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/pillpez" target="_blank">
                <LinkedIn />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export const PortalFooter = observer(Footer);
