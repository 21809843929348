import { httpClient } from '@PillPez/client';
import { types, Instance, flow, toGenerator } from 'mobx-state-tree';
import { AxiosError } from 'axios';
import { apiErrorsToObject } from '@PillPez/helpers/apiErrorsToObject';
import { FieldErrors } from '../Auth';

export type CreateProviderResponse = {
    provider: IProvider;
    status: 'Success' | 'Error';
    message: string;
}

export const Providers = types.model({
    createErrors: types.optional(types.frozen<FieldErrors>(), {}),
    list: types.optional(types.array(types.frozen<IProvider>()), []),
}).actions(self => ({
    setErrors(errors: FieldErrors) {
        self.createErrors = errors;
    }
}))
    .actions(self => {
        function* createProvider({ model }: { model: IProvider }) {
            self.createErrors = {}
            try {
                let response = yield* toGenerator(httpClient.post<CreateProviderResponse>('/providers', model));
                return response.data;
            } catch (error: unknown) {
                if (error instanceof AxiosError) {
                    if (error?.response?.status === 422) {
                        let { errors } = error.response.data
                        if (errors) {
                            const errs = apiErrorsToObject(errors)
                            console.log({ errs })
                            self.setErrors(errs)
                        }
                    }
                }
                return null;
            }
        }

        function* getProviders() {
            try {
                let response = yield* toGenerator(httpClient.get<{ providers: IProvider[] }>('/providers'));
                // @ts-ignore
                self.list = response.data.providers;
            } catch (error: unknown) {
                if (error instanceof AxiosError) {
                    if (error?.response?.status === 422) {
                        let { errors } = error.response.data
                        if (errors) {
                            const errs = apiErrorsToObject(errors)
                            console.log({ errs })
                            self.setErrors(errs)
                        }
                    }
                }
                return null;
            }
        }

        return {
            createProvider: flow(createProvider),
            getProviders: flow(getProviders),
        }
    })

export interface IProviders extends Instance<typeof Providers> { }

export const Provider = types.model({
    id: types.maybeNull(types.integer),
    name: types.optional(types.string, ''),
    description: types.optional(types.maybeNull(types.string), null),
    addressLine1: types.optional(types.string, ""),
    city: types.optional(types.string, ""),
    stateProvince: types.optional(types.string, ""),
    postalCode: types.optional(types.string, ""),
    postalCodePlus4: types.optional(types.string, ""),
}).actions(self => {
    function setName(name: string) {
        self.name = name;
    }
    function setDescription(description: string | null) {
        self.description = description;
    }
    function setAddressLine1(addressLine1: string) {
        self.addressLine1 = addressLine1;
    }
    function setCity(city: string) {
        self.city = city;
    }
    function setStateProvince(stateProvince: string) {
        self.stateProvince = stateProvince;
    }
    function setPostalCode(postalCode: string) {
        self.postalCode = postalCode;
    }
    function setPostalCodePlus4(postalCodePlus4: string) {
        self.postalCodePlus4 = postalCodePlus4;
    }
    return {
        setName,
        setDescription,
        setAddressLine1,
        setCity,
        setStateProvince,
        setPostalCode,
        setPostalCodePlus4,
    }
})

export interface IProvider extends Instance<typeof Provider> { }
