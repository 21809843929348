import { observer } from 'mobx-react-lite';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { SuperIndex } from './screens/Super/Router';
import { CreateProviderScreen } from './screens/Super/CreateProvider';
import { useEffect } from 'react';
import { useMst } from './models/Root';
import { CreateUserScreen } from './screens/Super/CreateUser';
import {
  LoginScreen,
  AccountSetupScreen,
  ProviderAccountScreen,
  CaregiverAccountScreen,
  EnduserSummaryScreen,
  EnduserDetailsScreen,
  CaregiverSummaryScreen,
  CaregiverDetailsScreen,
} from '@PillPez/screens';
import { AccountScreen } from './screens/Account';
import { tokenProtected } from './hooks/tokenProtected';

const _FirstScreen = () => {
  const { auth } = useMst();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.user) {
      console.log(auth.user);
      if (auth.checkRoles('provider')) {
        navigate('/caregiver-summary');
      } else if (auth.checkRoles('caregiver')) {
        navigate('/caregiver-summary');
      } else if (auth.checkRoles('superAdmin')) {
        navigate('/super/create-provider');
      } else {
        navigate('/account');
      }
    }
  }, [auth]);
  return <></>;
};
const FirstScreen = tokenProtected(_FirstScreen);

const _Router = () => {
  const x = useMst();
  useEffect(() => {
    if (x.auth.user && x.auth.user.id) {
      x.auth.getUserDetails();
    }
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<FirstScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/caregiver-register" element={<AccountSetupScreen />} />
        <Route path="/provider-account" element={<ProviderAccountScreen />} />
        <Route path="/caregiver-account" element={<CaregiverAccountScreen />} />
        <Route path="/enduser-summary" element={<EnduserSummaryScreen />} />
        <Route
          path="/enduser/:enduserId/*"
          element={<EnduserDetailsScreen />}
        />
        <Route path="/caregiver-summary" element={<CaregiverSummaryScreen />} />
        <Route
          path="/caregiver/:caregiverId"
          element={<CaregiverDetailsScreen />}
        />
        <Route path="/account" element={<AccountScreen />} />
        {/* super admin routes */}
        <Route path="/super">
          <>
            <Route path="/super" element={<SuperIndex />} />
            <Route
              path="/super/create-provider"
              element={<CreateProviderScreen />}
            />
            <Route path="/super/create-user" element={<CreateUserScreen />} />
          </>
        </Route>
      </Routes>
    </>
  );
};

export const PillPezRouter = observer(_Router);
