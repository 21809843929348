import { httpClient } from '@PillPez/client';
import { types, toGenerator, flow, Instance } from 'mobx-state-tree';

export type IEvents = {

}

export type IEventsResponse = {
  data: IEvents[];
}

export type EventsResponse = {
  data: IEventsResponse;
}

export const EventsData = types.model({
})

export const Events = types.model({
  data: types.optional(types.array(EventsData), []),
  // pagination: types.maybe(PaginationData),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* events() {
    self.loading = true;
    try {
      // const response = yield* toGenerator(httpClient.get(`events?enduserId=${id}`));
      const response = yield* toGenerator(httpClient.get(`events`));
      self.loading = false;
      return { data: response.data } as EventsResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    events: flow(events),
  }
})



export type IAddEvent = {
}

export type IAddEventResponse = {
  data: IAddEvent[];
  status: string;
  response: any;
}

export type AddEventResponse = {
  data: IAddEventResponse;
  success: boolean;
  error: any;
}

export const AddEventData = types.model({
})

export const AddEventPayload = types.model({
  eventType: types.optional(types.string, ''),
  startDate: types.optional(types.string, ''),
  endDate: types.maybeNull(types.string),
  enduserId: types.maybe(types.number),
  title: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  everyDay: types.maybe(types.number),
  onTime: types.optional(types.string, ''),
  earlyTime: types.optional(types.string, ''),
  lateTime: types.optional(types.string, ''),
  discard: types.maybe(types.number),
  days: types.array(types.number),
  repeatDays: types.maybe(types.number),
  includeInAdherence: types.optional(types.string, ''),
}).actions(self => ({
  setEventType(eventType: string) {
    self.eventType = eventType;
  },
  setStartDate(startDate: string) {
    self.startDate = startDate;
  },
  setEndDate(endDate: string) {
    self.endDate = endDate;
  },
  setEnduserId(enduserId: number) {
    self.enduserId = enduserId;
  },
  setTitle(title: string) {
    self.title = title;
  },
  setdescription(description: string) {
    self.description = description;
  },
  setEveryDay(everyDay: number) {
    self.everyDay = everyDay;
  },
  setOnTime(onTime: string) {
    self.onTime = onTime;
  },
  setEarlyTime(earlyTime: string) {
    self.earlyTime = earlyTime;
  },
  setLateTime(lateTime: string) {
    self.lateTime = lateTime;
  },
  setDiscard(discard: number) {
    self.discard = discard;
  },
  setDays(days: any) {
    self.days = days;
  },
  setRepeatDays(repeatDays: number) {
    self.repeatDays = repeatDays;
  },
  setIncludeInAdherence(includeInAdherence: string) {
    self.includeInAdherence = includeInAdherence;
  },

  payload() {
    return {
      eventType: self.eventType,
      startDate: self.startDate,
      endDate: self.endDate,
      enduserId: self.enduserId,
      title: self.title,
      description: self.description,
      everyDay: self.everyDay,
      onTime: self.onTime,
      earlyTime: self.earlyTime,
      lateTime: self.lateTime,
      discard: self.discard,
      days: self.days,
      repeatDays: self.repeatDays,
      includeInAdherence: self.includeInAdherence,
    }
  }
}));

export interface IAddDevicePayload extends Instance<typeof AddEventPayload> { }


export const AddEvent = types.model({
  data: types.optional(types.array(AddEventData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* addEvent(model: IAddDevicePayload) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.post('events/', model.payload()));
      self.loading = false;
      return { data: response.data } as AddEventResponse
    } catch (error: unknown) {
      self.loading = false;
      console.log(error);
      return { success: false, error: error } as AddEventResponse
    }
  }
  return {
    addEvent: flow(addEvent),
  }
})


export type IGetEvent = {
  days: any;
}

export type IGetEventResponse = {
  data: IGetEvent[];
  event: IGetEvent[];
  days: IGetEvent[];
}

export type GetEventResponse = {
  data: IGetEventResponse;
}

export const GetEventData = types.model({
})

export const GetEvent = types.model({
  data: types.optional(types.array(GetEventData), []),
  // pagination: types.maybe(PaginationData),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* getEvent(id: string) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.get(`events/${id}`));
      self.loading = false;
      return { data: response.data } as GetEventResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    getEvent: flow(getEvent),
  }
})

export type IDeleteEvent = {
  status: string;
}

export type DeleteEventResponse = {
  data: IDeleteEvent;
}

export const DeleteEventData = types.model({
})

export const DeleteEvent = types.model({
  data: types.optional(types.array(DeleteEventData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* deleteEvent(id: string | undefined) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.delete(`events/${id}`));
      self.loading = false;
      return { data: response.data } as DeleteEventResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    deleteEvent: flow(deleteEvent),
  }
})


export type IUpdateEvent = {
  status: string;
}

export type UpdateEventResponse = {
  data: IUpdateEvent;
  response: any;
  error: any;
}

export const UpdateEventData = types.model({
})

export const UpdateEvent = types.model({
  data: types.optional(types.array(UpdateEventData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* updateEvent(id: string | undefined, payload: any) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.put(`events/${id}`, payload));
      self.loading = false;
      return { data: response.data } as UpdateEventResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
      return { error: error } as UpdateEventResponse
    }
  }
  return {
    updateEvent: flow(updateEvent),
  }
})