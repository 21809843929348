import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { ConfirmationMessage, SecondaryMessage, TertiaryMessage, ConfirmationButtons } from './styles/popup-modal.style';

interface WhatsThisProps {
  onSubmit: () => void;
  onCancel: () => void;
  caregiverData?: any;
}

const EndusersSection = styled.div`
  width: 403px;
  min-height: 156px;
  background: rgba(196, 196, 196, 0.15);
  border-radius: 31px;
  margin: 38px auto 60px auto;
  padding: 18px 30px;
  overflow-y: auto;
`;

const Enduser = styled.div`
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
`;

const EnduserLabel = styled.label`
  padding 2px 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: #002E6F;
  cursor: pointer;
  width 100%;
`;

const EnduserInput = styled.input`
  position: absolute;
  opacity: 0;

  &[type]:checked + label {
    color: white;
    background: #002E6F;
  }
`;

const ModalWrapper = styled.div`
  width: 1035px;
  height: 650px;
  padding: 43px 115px 28px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CannotDeleteCaregiver = ({ onSubmit, onCancel, caregiverData }: WhatsThisProps) => {
  const navigate = useNavigate();
  const [enduserId, setEnduserId] = useState<number>();
  useEffect(() => {
    setEnduserId(caregiverData?.endusers[0].id)
  }, [])

  return (
    <ModalWrapper>
      <ConfirmationMessage>Cannot Delete!</ConfirmationMessage>
      <SecondaryMessage><span>{caregiverData.firstName} {caregiverData.lastName}</span> is assigned to <span>({caregiverData.endusers.length})</span> End-User{caregiverData.endusers.length > 1 && 's'}</SecondaryMessage>
      <TertiaryMessage>In order to delete this caregiver, you must first unassign them as a caregiver of the following end-users:</TertiaryMessage>
      <EndusersSection>
        {caregiverData.endusers.map((enduser: any, i: any) => {
          return (
            <Enduser
              key={enduser.id}
            >
              <EnduserInput
                key={enduser.id}
                id={enduser.id}
                onClick={() => { setEnduserId(enduser?.id) }}
                type="radio"
                name="enduser-list"
              />
              <EnduserLabel htmlFor={enduser.id}>
                {enduser.firstName}, {enduser.lastName}
              </EnduserLabel>
            </Enduser>
          )
        })}
      </EndusersSection>
      <ConfirmationButtons>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
          className="portal-cancel"
        >
          Not Right Now
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
            navigate(`/enduser/${enduserId}/details`);
          }}
          className="portal-button"
        >
          Go to End-User's Page
        </a>
      </ConfirmationButtons>

    </ModalWrapper>
  );
};