import { PortalFooter, PortalHeader } from '@PillPez/components';
import { roleProtected } from '@PillPez/hooks/roleProtected';
import { tokenProtected } from '@PillPez/hooks/tokenProtected';
import { IProvider, Provider } from '@PillPez/models/Super/Providers';
import { observer } from 'mobx-react-lite';
import { FieldErrors, useForm, UseFormRegisterReturn } from 'react-hook-form';
import { useMst } from '../../models/Root';
import classNames from 'classnames';
import styled from 'styled-components';
import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

type FormData = {
  name: string;
  description: string;
  addressLine1: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  postalCodePlus4: string;
};

const CreateProviderDiv = styled.div`
  .content {
    background-color: #fff;
    padding-bottom: 100px;
  }
`;

type LocationState = {
  provider?: IProvider;
  message?: string;
};

const CreateProvider = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const { superStore } = useMst();

  const [msg] = useState(state?.message || '');
  const [newlyCreatedProvider] = useState<IProvider | undefined>(
    Provider.create(state?.provider || {})
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      name: '',
    },
    shouldFocusError: false,
  });

  const submit = async (data: FormData) => {
    let provider = Provider.create(data);
    let resp = await superStore.providers.createProvider({ model: provider });
    reset();
    if (resp?.status === 'Success') {
      navigate('/super/create-provider', {
        state: { message: resp.message, provider: resp.provider },
        replace: true,
      });
    }
  };

  return (
    <CreateProviderDiv className="portal-screen create-provider">
      <PortalHeader />
      <div className="content">
        <h1>Create Provider</h1>

        {msg && msg.length > 0 && (
          <div className="bg-green-400 p-3 rounded my-3">
            <div>{msg}</div>
            {newlyCreatedProvider && (
              <div>
                <Link
                  to={`/super/create-user`}
                  state={{ provider_id: newlyCreatedProvider.id }}
                  className="mt-2 underline"
                >
                  Create a user for this new provider
                </Link>
              </div>
            )}
          </div>
        )}

        <form onSubmit={handleSubmit(submit)}>
          <div className="residents-block">
            <TextInput
              errors={errors}
              register={register('name', { required: true })}
              name="Name"
              field="name"
            />
            <TextInput
              errors={errors}
              register={register('description', { required: true })}
              name="Description"
              field="description"
            />
            <TextInput
              errors={errors}
              register={register('addressLine1', { required: true })}
              name="Address Line 1"
              field="addressLine1"
            />
            <TextInput
              errors={errors}
              register={register('city', { required: true })}
              name="City"
              field="city"
            />
            <TextInput
              errors={errors}
              register={register('stateProvince', { required: true })}
              name="State/Province"
              field="stateProvince"
            />
            <TextInput
              errors={errors}
              register={register('postalCode', { required: true })}
              name="Postal Code"
              field="postalCode"
            />

            <button type="submit" className="mt-4">
              Submit
            </button>
          </div>
        </form>
      </div>

      <PortalFooter />
    </CreateProviderDiv>
  );
};

const TextInput = ({
  errors,
  register,
  name,
  field,
}: {
  errors: FieldErrors<FormData>;
  register: UseFormRegisterReturn;
  name: string;
  field: Partial<keyof FormData>;
}) => {
  const { superStore } = useMst();
  return (
    <div
      className={classNames('input--group', {
        'input--group--error':
          errors[field] || superStore.providers.createErrors[field],
      })}
    >
      <label htmlFor="">{name}</label>
      <input type="text" {...register} />
      {errors[field] && errors[field]?.type === 'required' && (
        <span className="error">{name} is required</span>
      )}
      {superStore.providers.createErrors &&
        superStore.providers.createErrors[field] &&
        superStore.providers.createErrors?.[field]?.map((err, idx) => {
          return (
            <span className="error" key={`error${field}${idx}`}>
              {err}
            </span>
          );
        })}
    </div>
  );
};

export const CreateProviderScreen = observer(
  tokenProtected(roleProtected(CreateProvider, 'superAdmin'))
);
// export const EnduserSummaryScreen = observer(EnduserSummary);
