import { useModal } from '@PillPez/components/Modals/DefaultModal/useModal';
import { Modal } from '@PillPez/components/Modals/DefaultModal/Modal';
import { DeleteScheduleEventConfirmationModal } from '@PillPez/components/Modals/ScheduleEventConfirmationModal';
import { EditPillScheduleModal } from '@PillPez/components/Modals/EditPillScheduleModal';
import { ReactComponent as TableBasket } from '@PillPez/assets/img/portal/table-basket.svg';
import { ReactComponent as EditBtn } from "@PillPez/assets/img/portal/edit-table.svg";
import { ReactComponent as EditClose } from "@PillPez/assets/img/portal/edit-close.svg"
import { ReactComponent as Pill } from '@PillPez/assets/img/portal/pill.svg';
import { ReactComponent as Aux } from '@PillPez/assets/img/portal/aux1.svg';
import { ReactComponent as RedClose } from '@PillPez/assets/img/portal/red-close.svg';
import { useEffect, useState } from 'react';
import { firstDayOfCurrentWeek, lastDayOfCurrentWeek, stringDecoder } from '../../../helpers/functions';
import { EditPopupAuxItem, EditPopupAux, EditPopup, EditPopupCommon, SaveButton } from '../styles'
import { useMst } from '@PillPez/models';

type Props = {
  id: string;
  enduserDetails?: string;
  action?: () => void;
  actionType?: string;
  onClickEdit?: (id?: string) => void;
};

export const ScheduleAction = ({ id, enduserDetails }: Props) => {
  const { isShown: modalDeleteOpened, toggle: modalDeleteToggle } = useModal();
  const { isShown: modalEditPillOpened, toggle: modalEditPillToggle } = useModal();
  const [edit, setEdit] = useState(false);
  const [eventDetails, setEventDetails] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string>();
  const [time, setTime] = useState('');
  const [days, setDays] = useState<any>([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const { getEvent, updateEvent, addEvent } = useMst();
  const [trigger, setTrigger] = useState(false)

  useEffect(() => {
    getScheduleEvent(id);
  }, [])

  const getScheduleEvent = async (id: string) => {
    let resp = await getEvent.getEvent(id);
    // console.log(resp);
    // console.log(id);
    if (resp) {
      setEventDetails(resp?.data?.event);
      setDays(eventDetails.days);
      setTitle(eventDetails.title);
      setDescription(eventDetails.description);
    }
  }

  const formatDays = (days: any) => {
    eventDetails.onTime && setTime(eventDetails.onTime);
    eventDetails.title && setTitle(eventDetails.title);
    eventDetails.description && setDescription(eventDetails.description);

    for (let i = 0; i < 7; i++) {
      if (days[i] != i) {
        days.splice(i, 0, "")
      }
    }
    console.log(days)
  }

  const updateScheduleEvent = async (id: string, payload: any) => {
    console.log(payload);
    let resp = await updateEvent.updateEvent(id, payload);
    if (resp?.data?.status === "Success") {
      setEdit(false);
      window.location.reload()
    } else {
      setErrorMessage(resp?.error?.response?.data?.message)
    }
    console.log(days);
    console.log(payload);
    console.log(resp);
  }

  return (
    <>
      <div className="schedule-action">
        <a
          href=""
          onClick={e => {
            e.preventDefault();
            formatDays(eventDetails.days);
            setEdit(!edit)
          }}
          className="schedule-edit">
          <EditBtn />
        </a>
        <a href=""
          onClick={(e) => {
            e.preventDefault();
            eventDetails.eventType === 'task' ? modalDeleteToggle() : modalEditPillToggle()
          }}
          className="schedule-delete">
          <TableBasket />
        </a>
        {edit && (
          <>
            <EditPopup action="">
              <EditPopupCommon>
                <div className="col time-col">
                  <span>TIME</span>
                  <input
                    type="time"
                    defaultValue={eventDetails.onTime}
                    onChange={(e) => {
                      setTime(e.target.value);
                      console.log(time);
                    }}
                  />
                </div>
                {eventDetails.eventType === 'task' ?
                  (

                    eventDetails.days && eventDetails.days.map((day: any, index: number) => {
                      return (
                        <div key={index} className="col aux">
                          {typeof (day) === "number" ?
                            (
                              <>
                                <Aux />
                                <RedClose
                                  className="red-close"
                                  onClick={() => {
                                    eventDetails.days[index] = "";
                                    setTrigger(!trigger);
                                    console.log(eventDetails.days);
                                  }}
                                />
                              </>
                            ) :
                            (
                              <div className="add-aux"
                                onClick={() => {
                                  eventDetails.days[index] = index;
                                  setTrigger(!trigger);
                                  console.log(eventDetails.days);
                                }}
                              >+</div>
                            )}
                        </div>
                      )
                    })
                  ) :
                  (
                    eventDetails.days && eventDetails.days.map((day: any, index: number) => {
                      return (
                        <div key={index} className="col"><Pill /></div>
                      )
                    })

                  )}
                <SaveButton
                  onClick={() => {
                    updateScheduleEvent(id, {
                      ...eventDetails,
                      onTime: time,
                      days: eventDetails.days.filter((day: any) => day != ''),
                      everyDay: eventDetails.days.filter((day: any) => day != '').length == 7 ? 1 : 0,
                      endDate: null,
                      title: title,
                      description: description,
                    });
                  }}
                >
                  Save
                </SaveButton>
                <EditClose
                  className="close-edit"
                  onClick={() => setEdit(false)}
                />
              </EditPopupCommon>

              {eventDetails.eventType == 'task' && (
                <>
                  <EditPopupAux>
                    <EditPopupAuxItem>
                      <p>Auxiliary Reminder Title<span>*</span></p>
                      <input
                        type="text"
                        defaultValue={stringDecoder(eventDetails.title)}
                        onChange={e => {
                          setTitle(e.target.value);
                        }}
                      />
                    </EditPopupAuxItem>

                    <EditPopupAuxItem>
                      <p>Notification Message</p>
                      <textarea
                        defaultValue={stringDecoder(eventDetails.description)}
                        // defaultValue={eventDetails.description}
                        maxLength={280}
                        onChange={e => {
                          setDescription(e.target.value);
                        }}
                      />
                    </EditPopupAuxItem>
                  </EditPopupAux>
                  {errorMessage && <span className="error">{errorMessage}</span>}
                </>
              )}
            </EditPopup>
          </>
        )}
      </div>
      <Modal
        isShown={modalDeleteOpened}
        hide={modalDeleteToggle}
        modalContent={<DeleteScheduleEventConfirmationModal
          onConfirm={modalDeleteToggle}
          onCancel={modalDeleteToggle}
          id={id}
        />
        }
      />
      {eventDetails.eventType === "package" && (
        <Modal
          isShown={modalEditPillOpened}
          hide={modalEditPillToggle}
          modalContent={<EditPillScheduleModal
            onSubmit={() => {
              modalDeleteToggle()
              modalEditPillToggle()
            }}
            onCancel={modalEditPillToggle}
            enduserDetails={enduserDetails}
          />
          }
        />
      )}

    </>
  )
}