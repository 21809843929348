import { ReactComponent as Pill } from '@PillPez/assets/img/portal/pill.svg';

interface Summary {
  userData?: any;
}

export const ScheduleSummary = ({ userData }: Summary) => {
  return (
    <div className="left">
      <div className="row">
        <p className="row-title">
          Schedule Summary
        </p>
        <div className="pill-packs">
          <div className="row">
            <p className="title">
              Pill packets dispensed
            </p>
            <span className="cumber">{userData?.adherenceSummary?.totalDispenseEvents}</span>
          </div>
          <div className="row">
            <p className="title">
              Pill packets missed
            </p>
            <span className="cumber">{userData?.adherenceSummary?.dispenseEventsMissed}</span>
          </div>
          {/* <div className="row total">
            <p className="title">
              Total Pill packets left
            </p>
            <span className="cumber">{props.total}</span>
          </div> */}
        </div>
      </div>
      <div className="row">
        <p className="row-title">
          Legend
        </p>
        <div className="legend-row">
          <p>
            <span>
              <Pill />
            </span>
            Pill Packet
          </p>
          <p>
            <span>
              aux
            </span>
            Auxiliary Reminder
          </p>
        </div>
      </div>
    </div>
  );
}