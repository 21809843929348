import { useMst } from "@PillPez/models";
import { useEffect, useState } from "react"
import { useParams } from "react-router";

export const EventType = {
  PillPack: "package",
  Auxiliary: "task",
}

export const useEnduserSchedule = (date: string) => {
  const [scheduleEvents, setScheduleEvents] = useState<any>({
    events: new Map(),
    uniqueDays: [],
  });
  const { endUserSchedule } = useMst();
  const { enduserId } = useParams();

  useEffect(() => {
    const getEndUserScheduleEvent = async (id: string | undefined, startDate: string) => {
      if (endUserSchedule.loading) return;
      let resp = await endUserSchedule.getEndUserSchedule(id, startDate);
      let times = new Map();
      let uniqueDays: string[] = [];
      let days = resp?.data?.schedule?.days || [];
      let orderedTime: any[] = [];
      for (let day of days) {
        let events = day.events || [];
        for (let event of events) {
          if (!orderedTime.includes(event.time)) {
            orderedTime.push(event.time);
          }
        }
      }
      orderedTime = orderedTime.map(time => ({ original: time, int: parseInt(time.replace(':', '')) })).sort((a, b) => a.int - b.int);
      for (let time of orderedTime) {
        if (!times.has(time.original)) {
          let collection = new Map();
          collection.set(EventType.PillPack, []);
          collection.set(EventType.Auxiliary, []);
          times.set(time.original, collection);
        }
      }
      for (let day of days) {
        let dayOfEvent = day.date;
        if (!uniqueDays.includes(dayOfEvent)) {
          uniqueDays.push(dayOfEvent);
        }
        let events = day.events || [];
        for (let event of events) {
          if (event.type == EventType.PillPack) {
            times.get(event.time).get(EventType.PillPack).push({ ...event, dayOfEvent });
          } else if (event.type == EventType.Auxiliary) {
            times.get(event.time).get(EventType.Auxiliary).push({ ...event, dayOfEvent });
          }
        }
      }
      for (let [time, events] of times) {
        //console.log({ t: time, e: events })
        for (let type of Object.values(EventType)) {
          let mapOfEvents = new Map();
          for (let event of events.get(type)) {
            //console.log({ event })
            if (!mapOfEvents.has(event.dayOfEvent)) {
              mapOfEvents.set(event.dayOfEvent, []);
            }
            mapOfEvents.get(event.dayOfEvent).push(event);
          }
          // console.log({ toSet: times.get(time), [type]: mapOfEvents })
          times.get(time).set(type, mapOfEvents);
        }
      }
      setScheduleEvents({ events: times, uniqueDays });
    };
    getEndUserScheduleEvent(enduserId, date);
  }, [])

  return { scheduleEvents }
}
