import { httpClient } from '@PillPez/client';
import { types, toGenerator, flow } from 'mobx-state-tree';

export type ISearch = {
}

export type ISearchResponse = {
  data: ISearch[]
}

export type SearchResponse = {
  data: ISearchResponse;
}

export const SearchData = types.model({
})

export const Search = types.model({
  data: types.optional(types.array(SearchData), []),
  loading: types.optional(types.boolean, false),
}).actions((self) => {
  function* search(text: string) {
    self.loading = true;
    try {
      const response = yield* toGenerator(httpClient.get(`search?searchText=${text}`));
      self.loading = false;
      return { data: response.data } as SearchResponse
    } catch (error) {
      self.loading = false;
      console.log(error);
    }
  }
  return {
    search: flow(search),
  }
})