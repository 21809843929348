import { useEffect, useState } from 'react';
type Props = {
  text?: string;
  info?: string;
  state?: boolean;
  onChange?: (state: boolean) => void;
  children?: undefined | React.ReactNode;
};
export const Notification = ({
  text = '',
  info = '',
  state = false,
  onChange = (state) => { },
  children,
}: Props) => {
  const [notif, setNotif] = useState(true);

  useEffect(() => {
    setNotif(state);
  }, [state]);

  return (
    <div className="inner notification-block">
      <div className="inner-heading">
        <p className="title">Notifications</p>
      </div>
      {children}
      <div className="notifications">
        <label htmlFor="notifications-checkbox">
          {text || 'Toggle to turn off SMS notifications about this end-user'}
        </label>
        <div
          className="notif-block"
          onClick={(e) => {
            e.preventDefault();
            setNotif(!notif);
            onChange(!notif);
          }}
        >
          <label
            id="notifications-checkbox-status"
            htmlFor="notifications-checkbox"
            className="status"
          >
            {notif ? 'on' : 'off'}
          </label>
          <input
            id="notifications-checkbox"
            type="checkbox"
            checked={notif}
            onChange={() => { }}
          />
          <div className="checkbox"></div>
        </div>
      </div>
      <p className="desc-notif">
        {info ||
          'You will not receive sms messages if your notifications are turned off in your account profile'}
      </p>
    </div>
  );
};
