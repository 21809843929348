import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import login_bg from '@PillPez/assets/img/portal/login_bg.png';
import { ReactComponent as LoginLogo } from '@PillPez/assets/img/portal/login-logo.svg';
import { ReactComponent as LockGray } from '@PillPez/assets/img/portal/lock-gray.svg';
import { PortalFooter, PasswordField } from '@PillPez/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMst } from '@PillPez/models';
import { useForm, FormProvider } from 'react-hook-form';
import { httpClient } from '@PillPez/client';
import { ICaregiver } from '../models/Auth';
import { AxiosError } from 'axios';
import { apiErrorsToObject } from '@PillPez/helpers/apiErrorsToObject';
const LoginBg = styled.div`
  background-image: url(${login_bg});
`;
export const Spinner = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 9px solid;
  border-color: #dbdcef;
  border-right-color: #002d74;
  animation: spinner-d3wgkg 1s infinite linear;

  @keyframes spinner-d3wgkg {
    to {
      transform: rotate(1turn);
    }
  }
`;

type FormData = {
  password: string;
  confirmPassword: string;
};

const AccountSetup = () => {
  const { search } = useLocation();
  const { auth } = useMst();
  const navigate = useNavigate();

  const [inviteId, setInviteId] = useState('');
  const [validatingId, setValidatingId] = useState(false);
  const [validId, setValidId] = useState(false);

  const [caregiver, setCaregiver] = useState<ICaregiver | null>();
  const [errorSettingPassword, setErrorSettingPassword] = useState('');

  const [validationErrors, setValidationErrors] = useState<any>({});

  const formContext = useForm<FormData>({
    defaultValues: {
      password: process.env.NODE_ENV === 'development' ? 'PillPez2022' : '',
      confirmPassword:
        process.env.NODE_ENV === 'development' ? 'PillPez2022' : '',
    },
    shouldFocusError: false,
    mode: 'onBlur',
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = formContext;

  const onSubmit = async (data: FormData) => {
    setValidatingId(true);
    setErrorSettingPassword('');
    setValidationErrors({});
    try {
      let resp = await httpClient.put(
        `caregivers/${caregiver?.id}/password?uniqueId=${inviteId}`,
        {
          ...caregiver,
          password: data.password,
        },
        {
          timeout: 10000,
        }
      );
      console.log('resp', resp);
      if (resp.data.status == 'Success') {
        navigate('/login', {
          state: {
            caregiverPasswordSet: true,
          },
        });
      }
    } catch (e: any | AxiosError) {
      setValidatingId(false);
      if (e?.response?.status === 422) {
        let errors = apiErrorsToObject(e.response.data.errors);
        setValidationErrors(errors);
      } else {
        setErrorSettingPassword('Error setting password. Please try again.');
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (inviteId?.length > 0) {
        setValidId(false);
        setValidatingId(true);
        const resp = await auth.validateCaregiverUniqueId(inviteId);
        if (resp.status === 'Fail') {
          setValidId(false);
        } else {
          setValidId(true);
          setCaregiver(resp.caregiver);
        }
        setValidatingId(false);
      }
    })();
  }, [inviteId, auth]);

  useEffect(() => {
    let params = new URLSearchParams(search);
    let q = params.get('q');
    if (q) {
      setInviteId(q);
    }
  }, [search]);

  return (
    <div id="account-set-up" className="portal-screen login account-set-up">
      <div className="first-row">
        <LoginBg className="left login_bg">
          <LoginLogo />
        </LoginBg>
        <div className="right">
          <div className="inner">
            <p className="title">Account Set Up</p>
            <p className="secondary">Welcome to the PillPez Client Portal.</p>
            {(!inviteId || validatingId) && (
              <div className="flex flex-col items-center justify-center mt-32">
                <Spinner />
              </div>
            )}
            {inviteId && !validatingId && !validId && (
              <div className="mt-10">
                <p className="error bg-red-400 p-3 rounded my-3">
                  Sorry, that invite code is not valid. Please try again.
                </p>
              </div>
            )}
            {errorSettingPassword.length > 0 && (
              <div className="mt-10">
                <p className="error bg-red-400 p-3 rounded my-3">
                  {errorSettingPassword}
                </p>
              </div>
            )}
            {validId && !validatingId && (
              <FormProvider {...formContext}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <PasswordField
                    label="Set Password"
                    onChange={(e) => {}}
                    value=""
                    placeholder="Minimum 8 Characters"
                    required
                    validation={register('password', { required: true })}
                    hasError={
                      errors.password?.type === 'required' ||
                      validationErrors?.password?.length > 0
                    }
                  >
                    {errors.password &&
                      errors.password?.type === 'required' && (
                        <span className="error">Password is required</span>
                      )}
                    {validationErrors.password &&
                      validationErrors.password.length > 0 &&
                      validationErrors.password.map(
                        (error: any, idx: number) => {
                          return (
                            <span className="error" key={idx}>
                              {error}
                            </span>
                          );
                        }
                      )}
                  </PasswordField>
                  <PasswordField
                    label="Confirm Password"
                    onChange={(e) => {}}
                    value=""
                    placeholder="Minimum 8 Characters"
                    required
                    validation={register('confirmPassword', {
                      required: true,
                      validate: (value) => value === getValues().password,
                    })}
                    hasError={
                      errors.confirmPassword?.type === 'required' ||
                      errors.confirmPassword?.type === 'validate'
                    }
                  >
                    {errors.confirmPassword &&
                      errors.confirmPassword?.type === 'required' && (
                        <span className="error">Password is required</span>
                      )}
                    {errors.confirmPassword &&
                      errors.confirmPassword?.type === 'validate' && (
                        <span className="error">Passwords do not match</span>
                      )}
                  </PasswordField>
                  <button className="portal-button">Submit</button>
                </form>
              </FormProvider>
            )}
            <p className="secured">
              <LockGray />
              Your Info is safely secured
            </p>
          </div>
        </div>
      </div>
      <PortalFooter />
    </div>
  );
};

export const AccountSetupScreen = observer(AccountSetup);
