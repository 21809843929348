import { useMst } from '@PillPez/models';
import { useParams } from 'react-router';
import {
  ConfirmationMessage,
  SecondaryMessage,
  ConfirmationButtons,
  ModalWrapper,
} from './styles/confirmation-modal.style';

interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  message?: string;
  id?: string;
  actionFunc: () => void;
}

export const DeleteCareTeamConfirmationModal = ({ onConfirm, onCancel, id, actionFunc }: ConfirmationModalProps) => {
  const { caregiverDetachToEnduser } = useMst();
  const { enduserId } = useParams();
  const detachCaregiver = async (id: string | undefined) => {
    let resp = await caregiverDetachToEnduser.detachCaregiverToEnduser(id, enduserId);
    console.log(resp);
    actionFunc();
  }

  return (
    <ModalWrapper>
      <ConfirmationMessage>Are you sure you want to unassign caregiver?</ConfirmationMessage>
      <SecondaryMessage>This action cannot be undone</SecondaryMessage>
      <ConfirmationButtons>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
          className="portal-white-button"
        >
          Cancel
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onConfirm();
            detachCaregiver(id)
            console.log(id)
          }}
          className="portal-button"
        >
          Unassign
        </a>
      </ConfirmationButtons>
    </ModalWrapper>
  );
};