import { ModalWrapper, ConfirmationMessage, SecondaryMessage, ConfirmationButtons } from './styles/popup-modal.style';

interface WhatsThisProps {
  onSubmit: () => void;
}

export const DeviceWhatsThisPopup = (props: WhatsThisProps) => {

  return (
    <ModalWrapper>
      <ConfirmationMessage>Device ID:</ConfirmationMessage>
      <SecondaryMessage>The <span>device ID</span> is the 16-character ID attached to each PillPez Device. You can find this on the inside lid of each device.</SecondaryMessage>
      <ConfirmationButtons>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.onSubmit();
          }}
          className="portal-button"
        >
          Ok
        </a>
      </ConfirmationButtons>

    </ModalWrapper>
  );
};