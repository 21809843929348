import moment from 'moment';

export let getCurrentDate = () => {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.toLocaleString('default', { month: 'long' });
    let year = newDate.getFullYear();

    return `${month} ${date}, ${year}`
}


export let getCurrentDateNew = () => {
    let newDate = new Date()
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}-${month}-${day}`
}

export const firstDayOfCurrentWeek = () => {
    const curr = new Date; // get current date
    const first = curr.getDate() - curr.getDay();

    const year = new Date(curr.setDate(first)).getFullYear();
    const month = new Date(curr.setDate(first)).getMonth() + 1;
    const day = new Date(curr.setDate(first)).getDate();

    return `${year}-${month}-${day}`
}

export const firstDayOfCurrentWeekNew = (d: any, raw: boolean = false) => {
    d = new Date(d);
    const day = d.getDay()
    const diff = d.getDate() - day + (day == 0 ? -6 : 0); // adjust when day is sunday
    const diffdate = new Date(d.setDate(diff))
    if (raw) return diffdate
    const date = diffdate.toLocaleDateString("en-GB");

    return date.split('/').reverse().join("-");
}

export const todayDate = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();


    return `${yyyy}-${mm}-${dd}`
}

export const lastDayOfCurrentWeek = () => {
    const curr = new Date; // get current date
    const first = curr.getDate() - curr.getDay();
    const last = first + 6;

    const year = new Date(curr.setDate(last)).getFullYear();
    const month = new Date(curr.setDate(last)).getMonth() + 1;
    const day = new Date(curr.setDate(last)).getDate();

    return `${year}-${month}-${day}`
}

export const getDayName = (date: string) => {

    const dayName = moment(date).format('ddd')
    //console.log("Moment day name:", dayName);

    return dayName;
}

export const dayMonthDateFormat = (date: string) => {
    const newDate = date.split('-');
    return `${newDate[1]}/${newDate[2]}`
}

// function to get a time in format hh:mm:ss and format it to hh:mm AM/PM
export const getTimeMeridian = (time: string) => {
    const newTime = time.split(':');
    const hour = parseInt(newTime[0]);
    const minute = newTime[1];
    const ampm = hour >= 12 ? 'PM' : 'AM';
    let hour12: number | string = hour % 12;
    if (hour12 > 0 && hour12 < 10) {
        hour12 = '0' + hour12;
    } else if (hour12 === 0) {
        hour12 = '12';
    }
    return `${hour12}:${minute} ${ampm}`
}

export const stringDecoder = (html: any) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}
