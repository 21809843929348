import { ModalWrapper, ConfirmationMessage, SecondaryMessage, ConfirmationButtons } from './styles/popup-modal.style';

interface WhatsThisProps {
  onSubmit: () => void;
}

export const EnduserWhatsThisPopup = (props: WhatsThisProps) => {

  return (
    <ModalWrapper>
      <ConfirmationMessage>End-User ID:</ConfirmationMessage>
      <SecondaryMessage>The <span>end-user ID</span> is the unique ID your institution assigns to its residents or patients</SecondaryMessage>
      <ConfirmationButtons>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.onSubmit();
          }}
          className="portal-button"
        >
          Ok
        </a>
      </ConfirmationButtons>

    </ModalWrapper>
  );
};