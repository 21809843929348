import { useEffect, useRef, useState } from 'react';
import { useMst } from '@PillPez/models';
import { useModal } from '@PillPez/components/Modals/DefaultModal/useModal';
import { useTableState } from '@PillPez/hooks/useTableState';
import { Table, Columns } from '@PillPez/components';
import { Modal } from '@PillPez/components/Modals/DefaultModal/Modal';
import { AddCareTeamModal } from '@PillPez/components/Modals/AddCareTeamModal';
import { ICareTeam } from '@PillPez/models/Endusers';
import { ReactComponent as Add } from '@PillPez/assets/img/portal/add.svg';
import { ReactComponent as AddGray } from '@PillPez/assets/img/portal/add-gray.svg';

interface CareTeam {
  userData?: any;
  actionFunc: () => void;
};

export const CareTeam = ({ userData, actionFunc }: CareTeam) => {
  const { isShown: modalDeleteOpened, toggle: modalDeleteToggle } = useModal();
  const { isShown: modalCareTeamOpened, toggle: modalCareTeamToggle } = useModal();

  const state = useRef({
    columns: [
      { path: "firstName", name: "First Name" },
      { path: "lastName", name: "Last Name" },
      { path: "primaryPhone", name: "Primary Phone" },
      { path: "altPhone", name: "Alternate Phone" },
      { path: "emailAddress", name: "Email" },
    ] as Columns<ICareTeam>[],
  });

  const tableState = useTableState(state.current);
  const { auth } = useMst();
  const [careteam, setCareteam] = useState<ICareTeam[]>([]);

  useEffect(() => {
    setCareteam(userData?.caregivers || [])
  }, [userData])

  return (
    <>
      <Table
        id="id"
        tableState={tableState}
        customStyle="gray-header"
        columns={tableState.columns}
        resetView={false}
        data={careteam}
        action={actionFunc}
        actionType="deleteCareTeam"
        hidePagination={true}
        noDataMessage="A caregiver is not  assigned to this end-user"
      />

      {auth.checkRoles('provider') ? (
        careteam.length < 1 ? (
          <div className="add-device"
            onClick={() => {
              modalCareTeamToggle();
            }}>
            <Add />
            <span>Assign Caregiver</span>
          </div>
        ) : (
          <div className="add-device disabled">
            <AddGray />
            <span>Assign Caregiver</span>
          </div>
        )
      ) : <></>}

      <Modal
        isShown={modalCareTeamOpened}
        hide={modalCareTeamToggle}
        modalContent={<AddCareTeamModal
          onSubmit={modalCareTeamToggle}
          onCancel={modalCareTeamToggle}
          actionFunc={actionFunc}
        />}
      />
    </>
  );
}