import { observer } from 'mobx-react-lite';
import { ReactComponent as HeaderSearchBtn } from '@PillPez/assets/img/portal/header_search_btn.svg';
import { ReactComponent as CloseIcon } from '@PillPez/assets/img/close-icon.svg';
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { useOnClickOutside } from '@PillPez/hooks';
import { useMst } from '@PillPez/models';
import { useNavigate } from 'react-router-dom'


type SearchProps = {
  data: any;
}

const Search = ({ data }: SearchProps) => {
  const [wordEntered, setWordEntered] = useState("");
  const [caregivers, setCaregivers] = useState<any>([]);
  const [endusers, setEndusers] = useState<any>([]);
  const navigate = useNavigate();
  const inner = useRef<HTMLElement | null>();
  const close = useCallback(() => {
    clearInput();
  }, []);

  const { auth, search } = useMst();

  const searchText = (text: string) => {
    search.search(text).then((res: any) => {
      console.log(res);
      if (auth.checkRoles('provider')) {
        setCaregivers(res.data.caregivers);
        setEndusers(res.data.endusers);
      } else if (auth.checkRoles('caregiver')) {
        setEndusers(res.data.endusers);
      }
    });
  }

  const clearInput = () => {
    setCaregivers([]);
    setEndusers([]);
    setWordEntered("");
  };

  const placeholder = auth.checkRoles('provider') ? 'Search for end-users or caregivers' : 'Search for end-users';

  useOnClickOutside(inner, close);

  return (
    <form className={classNames('search', { activeSearch: caregivers.length !== 0 || endusers.length !== 0 })}
      ref={(r) => {
        inner.current = r;
      }}
    >
      <div className="searchInputs">
        <input
          type="text"
          placeholder={placeholder}
          value={wordEntered}
          onChange={(e) => {
            setWordEntered(e.target.value);
            searchText(wordEntered);
          }}
        />
        <div className="searchIcon">
          <HeaderSearchBtn />
        </div>
        {(caregivers.length !== 0 || endusers.length !== 0) && <CloseIcon className="clearSearch" onClick={clearInput}>X</CloseIcon>}
      </div>
      {
        (caregivers.length !== 0 || endusers.length !== 0) && (
          <div className="searchResults">
            {caregivers.map((value: any, key: any) => {
              return (
                <a
                  className="dataItem"
                  href={value['link']}
                  onClick={() => {
                    navigate(`/caregiver/${value['id']}`)
                  }}
                  target="_blank"
                  key={key}>
                  <p>caregiver#{value['id']} {value['firstName']} {value['lastName']}</p>
                </a>
              );
            }).slice(0, 10)}
            {endusers.map((value: any, key: any) => {
              return (
                <a
                  className="dataItem"
                  href={value['link']}
                  onClick={() => {
                    navigate(`/enduser/${value['id']}/details`)
                  }}
                  target="_blank"
                  key={key} >
                  <p>end-user#{value['id']} {value['firstName']} {value['lastName']}</p>
                </a>
              );
            }).slice(0, 10)}
          </div>
        )
      }
    </form >
  );
}


export const SearchComponent = observer(Search);
