import { useMst } from '@PillPez/models';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CreateCaregiverPayload } from '@PillPez/models/Caregivers';
import { ModalWrapper } from './styles/addresident-modal.style'
import classNames from 'classnames';

interface AddCaregiverProps {
  onSubmit: (name: string) => void;
  onCancel: () => void;
};

type FormData = {
  firstName: string;
  lastName: string;
  emailAddress: string;
};

export const AddCaregiverModal = ({ onSubmit, onCancel }: AddCaregiverProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const { caregiverCreate } = useMst();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    shouldFocusError: false,
  });

  const onFormSubmit = handleSubmit(async (data) => {
    console.log(data);
    if (caregiverCreate.loading) return;
    const model = CreateCaregiverPayload.create(data);
    let resp = await caregiverCreate.createCaregiver(model);
    console.log(resp);
    if (resp?.data?.status === 'Success') {
      onSubmit(`${firstName} ${lastName}`);
      setErrorMessage('');
    } else {
      setErrorMessage(resp?.error?.response?.data?.message);
    }
  });

  return (
    <ModalWrapper>
      <div className="inner">
        <p className="heading">Add A Caregiver</p>
        <form action="" onSubmit={onFormSubmit}>
          <div className="form-row">
            <div className="left">
              <p className="title">
                Name<span>*</span>
              </p>
            </div>
            <div className="inputs">
              <div
                className={classNames('input--group', {
                  'input--group--error':
                    errors.firstName
                })}
              >
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  {...register('firstName', {
                    required: true,
                    onChange: (e) => setFirstName(e.target.value),
                  })}
                  placeholder="Jasmine"
                />
                {errors.firstName && errors.firstName.type === 'required' && (
                  <span className="error">First Name is required</span>
                )}
              </div>
              <div
                className={classNames('input--group', {
                  'input--group--error':
                    errors.lastName
                })}
              >
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  {...register('lastName', {
                    required: true,
                    onChange: (e) => setLastName(e.target.value),
                  })}
                  placeholder="Jasmine"
                />
                {errors.lastName && errors.lastName.type === 'required' && (
                  <span className="error">Last Name is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="left">
              <p className="title">
                Email<span>*</span>
              </p>
            </div>
            <div className="inputs single">
              <div
                className={classNames('input--group', {
                  'input--group--error':
                    errors.emailAddress
                })}
              >
                <input
                  type="email"
                  {...register('emailAddress', {
                    required: true,
                  })}
                  placeholder="email@domain.com"
                />
                {errors.emailAddress && errors.emailAddress.type === 'required' && (
                  <span className="error">Email is required</span>
                )}
              </div>
            </div>
          </div>
          {errorMessage && <span className="error">ERROR: {errorMessage}</span>}
          <div className="buttons-row">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onCancel();
              }}
              className="cancel close-add-resident-modal"
            >
              Cancel
            </a>
            <button className="portal-button">
              Save
            </button>
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};