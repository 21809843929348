import { Notification, PortalFooter, PortalHeader } from '@PillPez/components';
import { roleProtected } from '@PillPez/hooks/roleProtected';
import { tokenProtected } from '@PillPez/hooks/tokenProtected';
import { useMst } from '@PillPez/models';
import { IUser } from '@PillPez/models/Auth';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, useCallback } from 'react';
import { useForm, useFormContext, FormProvider } from 'react-hook-form';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { FieldErrors } from '../models/Auth';
import { ActionButton } from '@PillPez/components/Enduser/styles';
import { ReactComponent as EditBtnImg } from "@PillPez/assets/img/portal/edit-table.svg";

export type AccountFormData = {
  firstName: string;
  lastName: string;
  primaryPhone: string;
  altPhone: string;
  password: string;
  confirmPassword: string;
};

const Content = styled.div`
  .right.busy {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .input--group--error {
    label {
      color: #ff0000;
    }
    input {
      border-color: #ff0000;
    }
    .error {
      color: #ff0000;
      margin-top: 5px;
    }
  }
`;

type LocationState = {
  message?: string;
  counter?: 0;
  status?: 'Success' | 'Error';
};

const ConfirmPasswordInput = () => {
  let { register, unregister, watch, setValue, getValues, trigger } =
    useFormContext<AccountFormData>();

  useEffect(() => {
    register('confirmPassword', {
      required: true,
      validate: (value) => value === getValues().password,
    });
    return () => {
      unregister('confirmPassword');
    };
  }, [register, unregister]);

  return (
    <div className="input--group mt-3">
      <input
        type="password"
        placeholder="Confirm New Password"
        name="confirmPassword"
        onChange={(e) => {
          setValue('confirmPassword', e.target.value);
          trigger('confirmPassword');
        }}
        autoComplete="off"
      />
    </div>
  );
};

const Account = () => {
  const { auth } = useMst();
  const formContext = useForm<AccountFormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      primaryPhone: '',
      altPhone: '',
      password: '',
      confirmPassword: '',
    },
    shouldFocusError: false,
    mode: 'onBlur',
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = formContext;

  const [loading, setLoading] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  const [changeError, setChangeError] = useState<FieldErrors>({});

  const [notifications, setNotifications] = useState<boolean>(false);
  const [editingMode, setEditingMode] = useState(false);
  const [editingPasswordMode, setEditingPasswordMode] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;

  const [msg, setMsg] = useState(state?.message || '');
  const [smsUpdated, setSmsUpdated] = useState(false);

  const [save, setSave] = useState(state?.counter || 1);

  useEffect(() => {
    if (msg) {
      setTimeout(() => {
        setMsg('');
      }, 5000);
    }
  }, [msg]);

  const resetToUser = (user?: AccountFormData | IUser) => {
    reset({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      altPhone: user?.altPhone || '',
      primaryPhone: user?.primaryPhone || '',
      password: '',
      confirmPassword: '',
    });
  };
  const loadCaregiver = async () => {
    const caregiver = await auth.getCurrentCaregiver();
    setNotifications(caregiver?.data?.user?.allowsNotifications === 1);
  };
  useEffect(() => {
    if (auth.user) {
      resetToUser(auth.user);
      if (auth.checkRoles('caregiver')) {
        loadCaregiver();
      }
    }
  }, [auth.user]);

  const onSubmit = async (data: AccountFormData) => {
    console.log(data);
    setLoading(true);
    let resp;
    resp = await auth.updateCurrentUser(data);
    // if (auth.checkRoles('provider')) {

    // } else {
    //   resp = await auth.updateCurrentProvider(data);
    // }
    setLoading(false);
    if (resp.status === 'Success') {
      navigate('/account', {
        state: { message: 'Account updated successfully', counter: save },
      });
    }
  };

  const changePassword = useCallback(() => {
    setChangingPassword(true);
    setChangeError({});
    console.log('changing password to ', watch('password'));
    auth.changePassword(watch('password')).then((resp) => {
      setChangingPassword(false);
      if (resp.status === 'Success') {
        navigate('/account', {
          state: { message: resp.message },
        });
      } else {
        setChangeError(
          resp.errors
            ? resp.errors
            : { password: ['An error occurred while changing password'] }
        );
      }
    });
  }, [watch('password')]);

  return (
    <div id="provider" className="portal-screen provider">
      <PortalHeader />
      <Content
        className={classNames('content', {
          busy: loading,
        })}
      >
        <FormProvider {...formContext}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="inner">
              <div className="inner-heading" style={{ display: "flex", justifyContent: "space-between"}}>
                <p className="title">
                  {auth.checkRoles('provider')
                    ? 'Your provider account details'
                    : 'Your caregiver account details'}
                </p>

                <div className="buttons-block">
                  
                    <ActionButton
                      className="edit-btn"
                      onClick={(e) => {
                        setEditingMode(!editingMode);
                      }}
                    >
                      <EditBtnImg />
                    </ActionButton>
            
                  
                </div>

              </div>
              {msg && msg.length > 0 && (
                <div className="bg-green-400 p-3 rounded my-3">{msg}</div>
              )}
              <div className="form-inner">
                {auth.checkRoles('provider') && (
                  <div className="form-row">
                    <p className="title">PROVIDER NAME</p>
                    <div className="inputs">
                      <div className="input--group">
                        <p className="account-data">
                          {auth.user?.providerName}</p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-row two-block">
                  <p className="title">Your Name</p>
                  <div className="inputs">
                    <div
                      className={classNames('input--group', {
                        'input--group--error':
                          errors.firstName || auth.updateUserErrors.firstName,
                      })}
                    >
                      <label htmlFor="">
                        First Name <span className="required">*</span>
                      </label>
                      { editingMode === true ? (
                        <>
                          <input
                            type="text"
                            {...register('firstName', { required: true })}
                          />
                          { errors.firstName &&
                            errors.firstName?.type === 'required' && (
                              <span className="error">First Name is required</span>
                            )}
                          { auth.updateUserErrors &&
                            auth.updateUserErrors.firstName &&
                            auth.updateUserErrors?.firstName?.map((err, idx) => {
                              return <span className="error">{err}</span>;
                            })}
                          </>
                      ) : (
                        <p className="account-data">
                          { auth.user?.firstName }
                        </p>
                      )}
                      
                    </div>
                    <div
                      className={classNames('input--group', {
                        'input--group--error':
                          errors.lastName || auth.updateUserErrors.lastName,
                      })}
                    >
                      <label htmlFor="">
                        Last Name <span className="required">*</span>
                      </label>
                      { editingMode === true ? (
                        <>

                        <input
                          type="text"
                          {...register('lastName', { required: true })}
                        />
                        {errors.lastName &&
                          errors.lastName?.type === 'required' && (
                            <span className="error">Last Name is required</span>
                          )}
                        {auth.updateUserErrors &&
                          auth.updateUserErrors.lastName &&
                          auth.updateUserErrors?.lastName?.map((err, idx) => {
                            return <span className="error">{err}</span>;
                          })}
                        </>
                      ) : (
                        <p className="account-data">
                          { auth.user?.lastName }
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <p className="title">Email address</p>
                  <div className="inputs">
                    <a className="account-email">
                      {auth.user?.emailAddress}
                    </a>
                  </div>
                </div>
                <div className="form-row two-block">
                  <p className="title">Phone Numbers</p>
                  <div className="inputs">
                    <div
                      className={classNames('input--group', {
                        'input--group--error':
                          errors.primaryPhone ||
                          auth.updateUserErrors.primaryPhone,
                      })}
                    >
                      <label htmlFor="">
                        Primary Number <span className="required">*</span>
                      </label>

                      { editingMode === true ? (
                        <>

                          <InputMask
                            className="masked"
                            type="tel"
                            mask="(999)-999-9999"
                            {...register('primaryPhone', { required: true })}
                          />
                          {errors.primaryPhone &&
                            errors.primaryPhone?.type === 'required' && (
                              <span className="error">
                                Primary Number is required
                              </span>
                            )}
                          {auth.updateUserErrors &&
                            auth.updateUserErrors.primaryPhone &&
                            auth.updateUserErrors?.primaryPhone?.map((err, idx) => {
                              return <span className="error">{err}</span>;
                            })}

                        </>
                      ) : (
                        <p className="account-data">
                          { auth.user?.primaryPhone }
                        </p>
                      )}

                    </div>
                    <div
                      className={classNames('input--group', {
                        'input--group--error':
                          errors.altPhone || auth.updateUserErrors.altPhone,
                      })}
                    >
                      <label htmlFor="">
                        Alternate Number
                      </label>

                      { editingMode === true ? (
                        <>
                          <InputMask
                            className="masked"
                            type="tel"
                            mask="(999)-999-9999"
                            {...register('altPhone', { required: false })}
                          />
                          {errors.altPhone &&
                            errors.altPhone?.type === 'required' && (
                              <span className="error">
                                Alternate Number is required
                              </span>
                            )}
                          {auth.updateUserErrors &&
                            auth.updateUserErrors.altPhone &&
                            auth.updateUserErrors?.altPhone?.map((err, idx) => {
                              return <span className="error">{err}</span>;
                            })}

                        </>
                      ) : (
                        <p className="account-data">
                          { auth.user?.altPhone }
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {changeError && Object.keys(changeError).length > 0 && (
                  <div className="bg-red-400 p-3 rounded my-3">
                    {changeError &&
                      changeError.password &&
                      changeError?.password?.map((err, idx) => {
                        return (
                          <div className="error" key={`errorP${idx}`}>
                            {err}
                          </div>
                        );
                      })}
                  </div>
                )}

              { editingMode && (

                <div
                  className={classNames('form-row password relative', {
                    'cursor-not-allowed': changingPassword,
                  })}
                >
                  {changingPassword && (
                    <div className="bg-gray-200 opacity-50 absolute top-0 left-0 right-0 bottom-0" />
                  )}
                  <p className="title">Password</p>

                  { editingPasswordMode === true ? (
                  <div className="inputs">
                    <div>
                      <div className="input--group">
                        <input type="password" {...register('password')} autoComplete="off"/>
                      </div>
                      {watch('password') && watch('password').length > 0 && (
                        <ConfirmPasswordInput />
                      )}
                    </div>
                    {watch('password') &&
                      watch('password').length > 0 &&
                      watch('confirmPassword') &&
                      watch('confirmPassword').length > 0 &&
                      !errors?.confirmPassword?.type && (
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            changePassword();
                          }}
                          className={classNames(
                            'portal-white-button change-pass-btn',
                            {
                              'disabled cursor-not-allowed':
                                errors.confirmPassword?.type,
                            }
                          )}
                        >
                          change
                        </button>
                      )}
                  </div>
                  ) :(
                    <>
                    
                    <button
                      className="portal-button"
                      onClick={() => {
                        setEditingPasswordMode(!editingPasswordMode)
                      }}
                      style={{ paddingRight: "20px", paddingLeft: "20px"}}
                    >
                      Change Password 
                    </button>
                    
                    </>
                  )}
                </div>
              )}
              </div>
            </div>


              <div className="btns-block">
              { editingMode === true && (
                <>
                  <a
                    href="#"
                    className="cancel"
                    onClick={(e) => {
                      e.preventDefault();
                      resetToUser(auth.user ? auth.user : ({} as AccountFormData));
                      navigate(-save);
                    }}
                  >
                    Cancel
                  </a>
                  <button
                    type="submit"
                    className="portal-button"
                    onClick={() => {
                      setSave(prev => prev + 1)
                    }}
                  >
                    Save
                  </button>
                </>
                )}
              </div>

         

            {auth.checkRoles('caregiver') && (
              <Notification
                text="Receive SMS notifications"
                info={
                  'If ON, you will receive SMS notifications when any of your end-users have not dispensed a scheduled pills'
                }
                state={notifications}
                onChange={async (e) => {
                  let res = await auth.updateCaregiverNotifications(e);
                  if (res.status == 'Success') {
                    setSmsUpdated(true);
                  }
                }}
              >
                {smsUpdated && (
                  <div className="bg-green-400 p-3 rounded mt-3">
                    Notification settings updated
                  </div>
                )}
              </Notification>
            )}
          </form>
        </FormProvider>
      </Content>

      <PortalFooter />
    </div>
  );
};

export const AccountScreen = observer(
  tokenProtected(roleProtected(Account, ['provider', 'caregiver']))
);
