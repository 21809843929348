import { getEnv } from 'mobx-state-tree';
import { createBrowserHistory } from 'history';

export const globalHistory = createBrowserHistory({ window });

export type IRootEnv = {
    history: typeof globalHistory;
}

export const rootEnv = {
    history: globalHistory
} as IRootEnv

export const getRootEnv = (self: any) => {
    return getEnv<IRootEnv>(self)
}
