import { ModalWrapper } from './styles/addscheduleitem-modal.style'
import { ReactComponent as Pill } from '@PillPez/assets/img/portal/pill.svg';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useMst } from '@PillPez/models';
import { useForm } from 'react-hook-form';
import { AddEventPayload } from '@PillPez/models/Events';
import { useParams } from 'react-router';
import { getCurrentDateNew, firstDayOfCurrentWeek } from '../../helpers/functions'
import { ChangePillScheduleModal } from '@PillPez/components/Modals/ChangePillScheduleModal';
import { useModal } from './DefaultModal/useModal';
import { Modal } from '@PillPez/components/Modals/DefaultModal/Modal';


interface AddScheduleItem {
  onSubmit: () => void;
  onCancel: () => void;
  enduserDetails: any;
  pillPack: any;
};

type FormData = {
  eventType: string;
  startDate: string;
  endDate: string | null;
  enduserId: number;
  title: string;
  description: string;
  everyDay: number;
  onTime: string;
  earlyTime: string;
  lateTime: string;
  discard: number;
  days: any;
  repeatDays: number;
  includeInAdherence: string;
};

export const AddScheduleItemModal = ({ onSubmit, onCancel, enduserDetails, pillPack }: AddScheduleItem) => {
  const [switcher, setSwitcher] = useState(pillPack);
  const [message, setMessage] = useState('');
  const { addEvent } = useMst();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { enduserId }: { enduserId?: string } = useParams();
  const { isShown: modalChangePillOpened, toggle: modalChangePillToggle } = useModal();

  let weekDays: any = [];

  useEffect(() => {
    modalChangePillToggle();
    pillPack ? setValue('eventType', 'package') : setValue('eventType', 'task');
    pillPack ? setValue('includeInAdherence', '1') : setValue('includeInAdherence', '0');
  }, [])

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    shouldFocusError: false,
    defaultValues: {
      "eventType": "package",
      "startDate": getCurrentDateNew(),
      // "startDate": firstDayOfCurrentWeek(),
      "endDate": null,
      "enduserId": parseInt(enduserId!),
      "title": '',
      "description": "",
      "everyDay": 0,
      "onTime": "09:00:00",
      "earlyTime": "07:00:00",
      "lateTime": "10:00:00",
      "discard": 0,
      "days": [],
      "repeatDays": 0,
    }
  });
  const selectAll = watch('everyDay');
  const allDays = selectAll == 1 ? true : false;

  const onFormSubmit = handleSubmit(async (data) => {
    if (addEvent.loading) return;
    const model = AddEventPayload.create(data);
    let resp = await addEvent.addEvent(model);
    console.log(resp);
    if (resp?.data?.status === 'Success') {
      onSubmit();
      setErrorMessage('');
      window.location.reload();
    } else {
      setErrorMessage(resp?.error?.response?.data?.message);
    }
  });

  return (
    <ModalWrapper>
      <div
        className='modal-add-item-pill-pack'
      >
        <div className="inner">
          <p className="heading">Add Item</p>
          <form action="" onSubmit={onFormSubmit}>
            <div className="form-row">
              <p className="row-title">
                Item Type<span>*</span>
              </p>
              <input
                {...register('eventType', {
                  required: true,
                })}
                hidden
              />
              <input
                {...register('enduserId', {
                  required: true,
                })}
                hidden
              />
              <div className='switcher-small'>
                {pillPack && (
                  <div
                    className={classNames('item pill-pack', {
                      active: switcher,
                    })}
                  >
                    <Pill />
                    <p>pill pack</p>
                  </div>
                )}
                {!pillPack && (
                  <div
                    className={classNames('item pill-pack', {
                      active: !switcher,
                    })}
                  >
                    <span>AUX</span>
                    <p>Auxiliary Action</p>
                  </div>
                )}
              </div>
            </div>
            <div className="form-row dose">
              <p className="row-title">
                Dose Time<span>*</span>
              </p>
              <div className="dose-date-block">
                <input
                  type="time"
                  className="date-pill-pack"
                  placeholder="__:__"
                  {...register('onTime',)}
                />
              </div>
            </div>
            <div className="form-row days">
              <p className="row-title">
                Days<span>*</span>
              </p>
              <p className="secondary">
                Set days for weekly recurrence
              </p>
              <div className="select-days all">
                <input
                  id="days-all"
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setValue('everyDay', 1)
                      setValue('days', [0, 1, 2, 3, 4, 5, 6]);
                    } else {
                      setValue('everyDay', 0)
                      setValue('days', []);
                    }
                    console.log(getValues())
                  }}
                />
                <label htmlFor="days-all">Select All</label>
              </div>
              <div className="days-block">
                <div className="select-days day-item">
                  <label htmlFor="day-su">Su</label>
                  <input id="day-su" type="checkbox"
                    checked={allDays ? true : undefined}
                    onChange={(e) => {
                      e.target.checked ?
                        weekDays.push(0) :
                        weekDays = weekDays.filter((el: any) => el !== 0)
                      setValue('days', weekDays)
                      console.log(getValues())
                    }}
                  />
                </div>
                <div className="select-days day-item">
                  <label htmlFor="day-m">M</label>
                  <input id="day-m" type="checkbox"
                    checked={allDays ? true : undefined}
                    onChange={(e) => {
                      e.target.checked ?
                        weekDays.push(1) :
                        weekDays = weekDays.filter((el: any) => el !== 1)
                      setValue('days', weekDays)
                      console.log(getValues())
                    }}
                  />
                </div>
                <div className="select-days day-item">
                  <label htmlFor="day-t">T</label>
                  <input id="day-t" type="checkbox"
                    checked={allDays ? true : undefined}
                    onChange={(e) => {
                      e.target.checked ?
                        weekDays.push(2) :
                        weekDays = weekDays.filter((el: any) => el !== 2)
                      setValue('days', weekDays)
                      console.log(getValues())
                    }}
                  />
                </div>
                <div className="select-days day-item">
                  <label htmlFor="day-w">W</label>
                  <input id="day-w" type="checkbox"
                    checked={allDays ? true : undefined}
                    onChange={(e) => {
                      e.target.checked ?
                        weekDays.push(3) :
                        weekDays = weekDays.filter((el: any) => el !== 3)
                      setValue('days', weekDays)
                      console.log(getValues())
                    }}
                  />
                </div>
                <div className="select-days day-item">
                  <label htmlFor="day-th">Th</label>
                  <input id="day-th" type="checkbox"
                    checked={allDays ? true : undefined}
                    onChange={(e) => {
                      e.target.checked ?
                        weekDays.push(4) :
                        weekDays = weekDays.filter((el: any) => el !== 4)
                      setValue('days', weekDays)
                      console.log(getValues())
                    }}
                  />
                </div>
                <div className="select-days day-item">
                  <label htmlFor="day-f">F</label>
                  <input id="day-f" type="checkbox"
                    checked={allDays ? true : undefined}
                    onChange={(e) => {
                      e.target.checked ?
                        weekDays.push(5) :
                        weekDays = weekDays.filter((el: any) => el !== 5)
                      setValue('days', weekDays)
                      console.log(getValues())
                    }}
                  />
                </div>
                <div className="select-days day-item">
                  <label htmlFor="day-s">Sa</label>
                  <input id="day-s" type="checkbox"
                    checked={allDays ? true : undefined}
                    onChange={(e) => {
                      e.target.checked ?
                        weekDays.push(6) :
                        weekDays = weekDays.filter((el: any) => el !== 6)
                      setValue('days', weekDays)
                      console.log(getValues())
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-buttons">
              <a href=""
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
                className="cancel modal-add-item-pill-pack-close-btn">Cancel</a>
              <button
                onClick={() => {
                  console.log(getValues())
                }}
                className="portal-button">Save</button>
            </div>
            {errorMessage && <span className="error">{errorMessage}</span>}
            {!switcher &&
              <div className="add-item-aux">
                <p className="title">Auxiliary Reminder Title<span>*</span></p>
                <div className={classNames('', {
                  'input--group--error':
                    errors.title
                })}>
                  <input type="text" {...register('title', {
                    required: true,
                  })} />
                  {errors.title && errors.title.type === 'required' && (
                    <span className="error">Title is required</span>
                  )}

                </div>
                <div className={classNames('', {
                  'input--group--error':
                    errors.description
                })}>
                  <p className="title">Notification Message</p>
                  <textarea
                    maxLength={280}
                    {...register('description',
                      {
                        onChange: (e) => setMessage(e.target.value),
                      })
                    }
                  />
                </div>
                <p className="max-length">{message.length}/280</p>
                <p className="title">Track for Adherence<span>*</span></p>
                <div className="track-for-adherence">
                  <div className="item">
                    <input id="track-for-adherence-yes" type="radio"
                      {...register('includeInAdherence', {
                      })}
                      value="1"
                    />
                    <label htmlFor="track-for-adherence-yes">Yes</label>
                  </div>
                  <div className="item">
                    <input id="track-for-adherence-no" type="radio"
                      {...register('includeInAdherence', {
                      })}
                      value="0"
                    />
                    <label htmlFor="track-for-adherence-no">No</label>
                  </div>
                </div>
              </div>}
            {pillPack && (
              <Modal
                isShown={modalChangePillOpened}
                hide={modalChangePillToggle}
                modalContent={<ChangePillScheduleModal
                  onSubmit={() => {
                    modalChangePillToggle();
                  }}
                  onCancel={modalChangePillToggle}
                  enduserDetails={enduserDetails}
                />
                }
              />
            )}
          </form>
        </div>
      </div >
    </ModalWrapper >
  );
};