import { useMst } from '@PillPez/models';
import {
  ConfirmationMessage,
  SecondaryMessage,
  ConfirmationButtons,
  ModalWrapper,
} from './styles/confirmation-modal.style';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from '@PillPez/components/Modals/DefaultModal/Modal';
import { CannotDeleteCaregiver } from '@PillPez/components/Modals/CaregiverCannotDeleteModal';
import { useModal } from '@PillPez/components/Modals/DefaultModal/useModal';
import { useState } from 'react';

interface SummaryConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  message?: string;
  id?: string;
  actionFunc?: () => void;
}

export const SummaryConfirmationModal = (props: SummaryConfirmationModalProps) => {
  const { endUserDelete, caregiverDelete, viewCaregiver } = useMst();
  const navigate = useNavigate();
  const url = useLocation().pathname;
  const [caregiverDetails, setCaregiverDetails] = useState({});
  const {
    isShown: modalCannotDeleteCaregiverOpened,
    toggle: modalCannotDeleteCaregiverToggle
  } = useModal();

  const deleteEntity = (id: string) => {
    if (url.includes('caregiver')) {
      caregiverDelete.deleteCaregiver(id).then((res: any) => {
        if (res?.data?.status === 'Success') {
          props.onConfirm();
          navigate(`/`);
        } else if (res?.message?.data?.message.includes('Cannot delete caregiver')) {
          viewCaregiver.viewCaregiver(id).then((res: any) => {
            if (res.success === true) {
              setCaregiverDetails(res.data.caregiver);
              modalCannotDeleteCaregiverToggle();
            }
          })
        }
      });
    } else {
      endUserDelete.deleteEndUser(id).then((res: any) => {
        if (res?.data?.status === 'Success') {
          navigate(`/enduser-summary`)
        }
      });
    }
  }

  return (
    <ModalWrapper>
      <ConfirmationMessage>Are you sure you want to delete this?</ConfirmationMessage>
      <SecondaryMessage>This action cannot be undone</SecondaryMessage>
      <ConfirmationButtons>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            props.onCancel();

          }}
          className="portal-white-button"
        >
          Cancel
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            deleteEntity(props.id as string);
          }}
          className="portal-button"
        >
          Delete
        </a>
      </ConfirmationButtons>

      <Modal
        isShown={modalCannotDeleteCaregiverOpened}
        hide={modalCannotDeleteCaregiverToggle}
        modalContent={
          <CannotDeleteCaregiver
            caregiverData={caregiverDetails}
            onCancel={modalCannotDeleteCaregiverToggle}
            onSubmit={() => {
              props.onCancel()
              modalCannotDeleteCaregiverToggle()
            }}
          />
        }
      />
    </ModalWrapper>
  );
};