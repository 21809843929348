import { useForm } from 'react-hook-form';
import { useMst } from '../../models/Root';
import { CreateDevicePayload } from '@PillPez/models/Devices';
import classNames from 'classnames';
import { useState } from 'react';
import { useParams } from 'react-router';


interface AddCaregiverSuccess {
  onSubmit: () => void;
  onCancel: () => void;
  actionFunc: any;
};

type FormData = {
  id: string;
  type: string;
  status: string;
  active: string;
};

export const AddDeviceModal = ({ onSubmit, onCancel, actionFunc }: AddCaregiverSuccess) => {
  const { attachUniqueDevice } = useMst();
  const [errorMessage, setErrorMessage] = useState(false);
  const { enduserId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    shouldFocusError: false,
  });

  const onFormSubmit = handleSubmit(async (data) => {
    if (attachUniqueDevice.loading) return;
    let resp = await attachUniqueDevice.attachUniqueDevice(enduserId, data.id);
    console.log(resp?.data);
    if (resp?.data?.status === 'Success') {
      onSubmit();
      actionFunc()
      setErrorMessage(false)
    } else {
      setErrorMessage(true);
      console.log(resp);
    }
  });

  return (
    <>
      <div
        className={classNames('modal-add-device', {
          busy: attachUniqueDevice.loading,
        })}
      >
        <div className="inner">
          <p className="title">Add A Device</p>
          <form action="" onSubmit={onFormSubmit}>
            <div className="row">
              <p className="name">
                Device ID<span>*</span>
              </p>
              <div className={classNames('input--group', {
                'input--group--error':
                  errors.id
              })}>
                <input
                  type="text"
                  {...register('id', {
                    required: true,
                  })}
                ></input>
                <input value="hub" hidden {...register('type', {
                  required: false,
                })} />
                <input value="active" hidden {...register('active', {
                  required: false,
                })} />
                <input value="online" hidden {...register('status', {
                  required: false,
                })} />
                {errors.id && errors.id.type === 'required' && (
                  <span className="error">Device ID is required</span>
                )}
              </div>
            </div>
            {errorMessage && <span className="error">ERROR adding a new Device</span>}
            <div className="buttons-block">
              <a href="" className="cancel" onClick={(e) => {
                e.preventDefault();
                onCancel();
              }}>Cancel</a>
              <button className="portal-button">Save</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};