import { observer } from 'mobx-react-lite';
import { ReactComponent as HeaderLogo } from '@PillPez/assets/img/portal/header-logo.svg';
import { ReactComponent as HeaderAuthUser } from '@PillPez/assets/img/portal/header-auth-user.svg';
import { SearchComponent } from './Search';
import { Link, useNavigate } from 'react-router-dom';
import { useMst } from '@PillPez/models';
import SerachData from '../helpers/SearchData.json';
import styled from 'styled-components';

const SuperAdminDiv = styled.div`
  justify-self: flex-start;
`;

const RightDiv = styled.div`
  margin-left: auto;
`;

const Header = () => {
  const navigate = useNavigate();
  const { auth } = useMst();
  return (
    <div className="portal-top-header">
      <Link to="/" className="header-logo">
        <HeaderLogo />
      </Link>
      {auth.isSuperAdmin() && (
        <SuperAdminDiv className="bg-red-600 p-2 rounded-full font-bold text-white">
          Super Admin
        </SuperAdminDiv>
      )}
      <RightDiv className="right">
        <SearchComponent data={SerachData} />
        {!auth.isSuperAdmin() && (
          <Link to="/account" className="auth-user">
            <HeaderAuthUser />
            {auth.checkRoles('caregiver') &&
              `${auth.user?.firstName} ${auth.user?.lastName}`}
            {auth.checkRoles('provider') && auth.user?.providerName}
          </Link>
        )}
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            auth.logout();
            navigate('/login');
          }}
          className="log-out"
        >
          <button>Log Out</button>
        </form>
      </RightDiv>
    </div>
  );
};

export const PortalHeader = observer(Header);
