type Props = {
  type?: string;
  action?: string;
  message?: string;
  time?: string;
  repeats?: string;
  editBy?: string;
  editOn?: string;
  children?: any;
  coordsx?: any;
  coordsy?: any;
};

export const PopUp = ({
  type,
  action,
  message,
  time,
  repeats,
  editBy,
  editOn,
  children,
  coordsx,
  coordsy
}: Props) => {
  return typeof children !== 'undefined' ? (
    <div className="modal-message-schedule" style={{ position: "fixed", maxHeight: "200px", top: `${coordsy}px`, left: `${coordsx}px`, zIndex: 20}}>{children}</div>
  ) : (
    <div className="modal-message-schedule">
      <div className="row">
        <p className="title">Action:</p>
        <p className="value">{action}</p>
      </div>
      {type === 'aux' && message && (
        <div className="row row-message">
          <p className="title">Message:</p>
          <p className="value">{message}</p>
        </div>
      )}
      {time && (
        <div className="row">
          <p className="title">Time:</p>
          <p className="value">{time}</p>
        </div>
      )}
      {/* {type === "pill" && repeats && (
        <div className="row">
          <p className="title">Repeats:</p>
          <p className="value">{repeats}</p>
        </div>
      )} */}

      <div className="row row-edit">
        <div className="item">
          <p className="title">Edited by:</p>
          <p className="value">{editBy}</p>
        </div>
        <div className="item">
          <p className="title">on:</p>
          <p className="value">{editOn}</p>
        </div>
      </div>
    </div>
  );
};
