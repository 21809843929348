import { useState, useEffect, useRef } from 'react';
import { Modal } from '@PillPez/components/Modals/DefaultModal/Modal';
import { useModal } from '@PillPez/components/Modals/DefaultModal/useModal';
import { AddEndUserModal } from '@PillPez/components/Modals/AddEndUserModal';
import { AddEndUserSuccessModal } from '@PillPez/components/Modals/AddEndUserSuccessModal';
import {
  PortalFooter,
  PortalHeader,
  Table,
  ItemBox,
  Columns,
} from '@PillPez/components';
import { getCurrentDate } from '@PillPez/helpers/functions';
import { observer } from 'mobx-react-lite';
import { useMst } from '@PillPez/models/Root';
import { tokenProtected } from '@PillPez/hooks/tokenProtected';
import { useTableState } from '@PillPez/hooks/useTableState';
import { IEndUser } from '@PillPez/models/Endusers';
import { IPaginationData } from '@PillPez/types';
import { ProgressBar } from '@PillPez/components/UI/ProgressBar';

const EnduserSummary = () => {
  const [enduserData, setEnduserData] = useState<IEndUser[]>([]);
  const [paginationData, setPaginationData] = useState<IPaginationData>({
    recordsTotal: 0,
    recordsFiltered: 0,
  });
  const { isShown: modalEndUserOpened, toggle: modalEndUserToggle } =
    useModal();
  const { isShown: modalOpened, toggle: modalSuccessToggle } = useModal();
  const { isShown: modalDeleteOpened, toggle: modalDeleteToggle } = useModal();
  const [residentName, setResidentName] = useState('');
  const [id, setId] = useState('');
  const createEndUser = (name: string, id: string) => {
    modalSuccessToggle();
    modalEndUserToggle();
    setResidentName(name as string);
    setId(id as string);
    console.log(id);
  }

  const { endUsers, auth } = useMst();

  const state = useRef({
    columns: [
      { path: 'firstName', name: 'First Name', searchable: true, orderable: true, },
      { path: 'lastName', name: 'Last Name', searchable: true, orderable: true, },
      { path: 'uniqueId', name: 'End-User ID', orderable: true },
      { path: 'id', name: 'ID', orderable: true },
      { path: 'caregiver', name: 'Primary Caregiver', orderable: true },
      { path: 'adherence', hide: true },
      { path: (row) => <ProgressBar progress={row.adherence} />, name: 'Adherence Score' },
    ] as Columns<IEndUser>[],
  });

  const tableState = useTableState(state.current);

  useEffect(() => {
    endUsers.getEndUsersTable(tableState.payload).then((res) => {
      setEnduserData((res?.data || []) as IEndUser[]);
      setPaginationData({
        recordsTotal: res?.recordsTotal || 0,
        recordsFiltered: res?.recordsFiltered || 0,
      });
    });
  }, [tableState.payload, modalOpened]);

  return (
    <div id="resident-summary" className="portal-screen resident-summary">
      <PortalHeader />
      <div className="content">
        <h1>
          The dashboard for {auth?.user?.firstName} {auth?.user?.lastName}
        </h1>
        {/* <p className="secondary">Your Dashboard for {getCurrentDate()}</p> */}
        {!auth.checkRoles('caregiver') && (
          <div className="add-new-block">
            <ItemBox
              title="Add New End-User"
              secondaryTitle="Have End-User ID & Device ID ready"
              buttonText="+ Add End-User"
              action={(e: any) => {
                e.preventDefault();
                modalEndUserToggle();
              }}
            />
            <ItemBox
              title="Your Caregivers"
              secondaryTitle="Add, edit, and view your caregivers"
              buttonText="Manage Caregivers"
              href="/caregiver-summary"
            />
          </div>
        )}
        <div className="residents-block">
          <h2>Your End-Users</h2>
          <Table
            id="id"
            columns={tableState.columns}
            loadingData={endUsers.loading}
            data={enduserData}
            action={modalDeleteToggle}
            tableState={tableState}
            actionType="review"
            recordName="end-user"
            recordNamePlural="end-users"
            pagination={paginationData}
          />
        </div>
      </div>

      <PortalFooter />
      <Modal
        isShown={modalEndUserOpened}
        hide={modalEndUserToggle}
        modalContent={
          <AddEndUserModal
            onSubmit={createEndUser}
            onCancel={modalEndUserToggle}
          />
        }
      />

      <Modal
        isShown={modalOpened}
        hide={modalSuccessToggle}
        modalContent={
          <AddEndUserSuccessModal
            name={residentName}
            onSubmit={() => {
              modalSuccessToggle();
            }}
            onCancel={modalSuccessToggle}
            id={id}
          />
        }
      />
    </div>
  );
};

export const EnduserSummaryScreen = observer(tokenProtected(EnduserSummary));
