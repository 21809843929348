import styled from 'styled-components';
import { ConfirmationMessage, SecondaryMessage, ConfirmationButtons } from './styles/popup-modal.style';

interface WhatsThisProps {
  onSubmit: () => void;
  onCancel: () => void;
  enduserDetails?: any;
}

const ModalWrapper = styled.div`
  width: 1065px;
  height: 650px;
  padding: 43px 115px 28px;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const ChangePillScheduleModal = ({ onSubmit, onCancel, enduserDetails }: WhatsThisProps) => {

  return (
    <ModalWrapper>
      <ConfirmationMessage>You are changing the pill packet schedule.</ConfirmationMessage>
      <SecondaryMessage><span>
        {enduserDetails?.firstName} {enduserDetails?.lastName}
      </span> will be prompted to reload their medication if PillPez Device is not empty</SecondaryMessage>
      <ConfirmationButtons>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
          className="portal-cancel"
        >
          Cancel
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="portal-button"
        >
          Continue
        </a>
      </ConfirmationButtons>
    </ModalWrapper>
  );
};