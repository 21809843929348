import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from "react-router-dom";
import InputMask from 'react-input-mask';
import {
  InputField,
  InputFieldContent,
  Label,
  InputWrapper,
  ButtonsWrapper,
  ActionButton,
} from './styles/caregiverdetails.style';
import { PortalFooter, PortalHeader } from '@PillPez/components';
import { useModal } from '@PillPez/components/Modals/DefaultModal/useModal';
import { Modal } from '@PillPez/components/Modals/DefaultModal/Modal';
import { SummaryConfirmationModal } from '@PillPez/components/Modals/SummaryConfirmationModal'
import { ReactComponent as EditBtnImg } from "@PillPez/assets/img/portal/edit-table.svg";
import { ReactComponent as DeleteBtnImg } from "@PillPez/assets/img/portal/table-basket.svg";
import { useMst } from '@PillPez/models';

const CaregiverDetails = () => {
  const { caregiverId } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [altPhone, setAltPhone] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const { isShown: modalDeleteOpened, toggle: modalDeleteToggle } = useModal();
  const Input = editMode ? InputField : InputFieldContent;
  const edit = new URLSearchParams(useLocation().search).get('editMode');
  const { viewCaregiver, updateCaregiver, auth } = useMst();
  const [payload, setPayload] = useState({})

  const updateCaregiverDetails = (caregiverId: string, payload: object) => {
    updateCaregiver.updateCaregiver(caregiverId, payload).then((res: any) => {
      if (res.data.status === 'Success') {
        setSuccessMessage(true);
        console.log(res);
        console.log(payload);
      }
    });
  }

  useEffect(() => {
    viewCaregiver.viewCaregiver(caregiverId as string).then((res: any) => {
      setFirstName(res?.data?.caregiver?.firstName);
      setLastName(res?.data?.caregiver?.lastName);
      setEmail(res?.data?.caregiver?.emailAddress);
      setPhone(res?.data?.caregiver?.primaryPhone);
      setAltPhone(res?.data?.caregiver?.altPhone);
      setPayload(res?.data?.caregiver);
    });

    edit && setEditMode(true);
    auth.checkRoles('caregiver') && setEditMode(false);
  }, [viewCaregiver, caregiverId])

  return (
    <div
      id="caregiver-details"
      className="portal-screen provider caregiver-details"
    >
      <PortalHeader />

      <div className="content">
        <div className="inner">
          <div className="inner-heading">
            <p className="title">Caregiver Details</p>
            <div className="buttons-block">
              {!auth.checkRoles('caregiver') && (
                <ActionButton
                  className="edit-btn"
                  onClick={(e) => {
                    setEditMode(!editMode);
                  }}
                >
                  <EditBtnImg />
                </ActionButton>
              )}
              <ActionButton onClick={() => modalDeleteToggle()}>
                <DeleteBtnImg />
              </ActionButton>
            </div>
          </div>

          <div className="form-inner">
            <div className="form-row two-block">
              <p className="title">Name</p>
              <div className="inputs">
                <InputWrapper>
                  <Label margin={editMode} htmlFor="">
                    First Name <span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    value={firstName ? firstName : ''}
                    placeholder={editMode ? "Jasmine" : ""}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    aria-autocomplete="list"
                    disabled={!editMode} />
                </InputWrapper>
                <InputWrapper>
                  <Label margin={editMode} htmlFor="">
                    Last name <span className="required">*</span>
                  </Label>
                  <Input
                    type="text"
                    value={lastName ? lastName : ''}
                    placeholder={editMode ? "Jasmine" : ""}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    aria-autocomplete="list"
                    disabled={!editMode} />
                </InputWrapper>

              </div>
            </div>
            <div className="form-row">
              <p className="title">Email address</p>
              <div className="inputs single">
                <Input
                  type="email"
                  value={email ? email : ''}
                  placeholder={editMode ? "email@domain.com" : ""}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  aria-autocomplete="list"
                  disabled={!editMode}
                />
              </div>
            </div>
            <div className="form-row two-block">
              <p className="title">Phone Numbers</p>
              <div className="inputs">
                <InputWrapper>
                  <Label margin={editMode} htmlFor="">
                    Primary Number <span className="required">*</span>
                  </Label>
                  <Input
                    as={InputMask}
                    className="masked"
                    type="tel"
                    value={phone ? phone : ''}
                    placeholder={editMode ? "(999) 867 - 5309" : ""}
                    mask="(999)-999-9999"
                    onChange={(e: any) => {
                      setPhone(e.target.value);
                    }}
                    aria-autocomplete="list"
                    disabled={!editMode}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label margin={editMode} htmlFor="">Alternate Number </Label>
                  <Input
                    type="text"
                    value={altPhone ? altPhone : ''}
                    placeholder={editMode ? "(999) 867 - 5309" : ""}
                    onChange={(e) => {
                      setAltPhone(e.target.value);
                    }}
                    aria-autocomplete="list"
                    disabled={!editMode}
                  />
                </InputWrapper>
              </div>
            </div>
          </div>
        </div>
        {editMode &&
          <ButtonsWrapper>
            <button className="portal-cancel" onClick={() => setEditMode(false)}>Cancel</button>
            <button
              className="portal-button"
              onClick={() => {
                updateCaregiverDetails(
                  caregiverId as string,
                  {
                    ...payload,
                    firstName,
                    lastName,
                    emailAddress: email,
                    primaryPhone: phone,
                    altPhone: altPhone,
                  } as object
                );
                setEditMode(false);
              }}
            >Save</button>
          </ButtonsWrapper>
        }
        {successMessage && <h2>Caregiver Succesfully Updated</h2>}
      </div>

      <Modal
        isShown={modalDeleteOpened}
        hide={modalDeleteToggle}
        modalContent={<SummaryConfirmationModal
          id={caregiverId}
          onConfirm={() => {
            modalDeleteToggle();
          }}
          onCancel={modalDeleteToggle}
        />}
      />

      <PortalFooter />
    </div >
  );
};

export const CaregiverDetailsScreen = observer(CaregiverDetails);
