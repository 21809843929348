import { types, Instance } from 'mobx-state-tree';
export type PaginatedResponse<T> = {
    data: T[];
    recordsTotal: number;
    recordsFiltered: number;
    draw: number;
}

export const PaginationData = types.model({
    recordsTotal: types.number,
    recordsFiltered: types.number,
})

export type IPaginationData = Instance<typeof PaginationData>
