import { useModal } from '@PillPez/components/Modals/DefaultModal/useModal';
import { Modal } from '@PillPez/components/Modals/DefaultModal/Modal';
import { AddScheduleItemModal } from '@PillPez/components/Modals/AddScheduleItemModal';
import { useMst } from "@PillPez/models/Root";
import { ScheduleSummary } from '@PillPez/components/Enduser/components/ScheduleSummary';
import { ScheduleInfo } from '@PillPez/components/Enduser/components/ScheduleInfo';
import { ScheduleWeekly } from '@PillPez/components/Enduser/components/ScheduleWeekly';

import { ReactComponent as Add } from '@PillPez/assets/img/portal/add.svg';
import { useEffect, useState } from 'react';

interface CareTeam {
  userData?: any;
  actionFunc: any;
};

export const Scheduler = ({ userData, actionFunc }: CareTeam) => {
  const { isShown: modalScheduleItemOpened, toggle: modalScheduleItemToggle } = useModal();
  const user = useMst();
  const [userDetails, setUserDetails] = useState('');
  const [pillPack, setPillPack] = useState(true);

  useEffect(() => {
    user.auth.getUserDetails().then((res: any) => {
      setUserDetails(`${res.firstName} ${res.lastName}`)
      console.log(res);
    });
    console.log(userData);
  }, [])

  return (
    <>
      <div className="schedule-container">
        <div className="row-header">
          <p className="title">Device Schedule</p>
        </div>
        <div className="schedule-inner">
          <ScheduleSummary
            userData={userData}
          />
          <div className="right">
            <ScheduleInfo
              userDetails={userDetails}
              enduserDetails={userData}
              actionFunc={actionFunc}
            />
            <p className="heading">
              Weekly Recurring Schedule
            </p>
            <ScheduleWeekly
              userDetails={userDetails}
              enduserDetails={userData}
            />
          </div>
        </div>
      </div>

      <div className="add-schedule-item"
        onClick={() => {
          modalScheduleItemToggle();
          setPillPack(true);
        }}>
        <Add />
        <span>Add Packet Dispense</span>
      </div>

      <div className="add-schedule-item"
        onClick={() => {
          modalScheduleItemToggle();
          setPillPack(false);
        }}>
        <Add />
        <span>Add Aux Reminder</span>
      </div>

      <Modal
        isShown={modalScheduleItemOpened}
        hide={modalScheduleItemToggle}
        modalContent={<AddScheduleItemModal
          onSubmit={modalScheduleItemToggle}
          onCancel={modalScheduleItemToggle}
          enduserDetails={userData}
          pillPack={pillPack}
        />}
      />
    </>
  );
}