import React from 'react';
import { useMst } from '@PillPez/models';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function guests(Component: React.FunctionComponent | JSX.Element, redirectTo?: string): any {
    function Wrapper() {
        const { auth } = useMst();
        const location = useLocation()
        const navigate = useNavigate();
        useEffect(() => {
            if (auth.token) {
                navigate(redirectTo ? redirectTo : '/');
            }
        }, [auth, navigate, location])
        if (auth.token) {
            return React.createElement(React.Fragment)
        }
        return React.createElement(Component as any);
    }
    return typeof Component === 'function' ? Wrapper : React.createElement(Wrapper as any);
}
