import { useEffect, useRef, useState } from 'react';
import { useMst } from '@PillPez/models';
import { ReactComponent as Add } from '@PillPez/assets/img/portal/add.svg';
import { ReactComponent as AddGray } from '@PillPez/assets/img/portal/add-gray.svg';
import { useTableState } from '@PillPez/hooks/useTableState';
import { Table, Columns } from '@PillPez/components';
import { Modal } from '@PillPez/components/Modals/DefaultModal/Modal';
import { useModal } from '@PillPez/components/Modals/DefaultModal/useModal';
import { ConfirmationModal } from '@PillPez/components/Modals/ConfirmationModal'
import { AddDeviceModal } from '@PillPez/components/Modals/AddDeviceModal';
import { IDevicesList } from '@PillPez/models/Devices';
interface Device {
  userData?: any;
  actionFunc?: any;
};

export const Device = ({ userData, actionFunc }: Device) => {
  const { isShown: modalDeleteOpened, toggle: modalDeleteToggle } = useModal();
  const { isShown: modalDeviceOpened, toggle: modalDeviceToggle } = useModal();

  const stateDevices = useRef({
    columns: [
      {
        path: "id",
        name: "Device ID#",
        orderable: true
      },
      {
        path: "uniqueId",
        name: "Device ID#",
        orderable: true
      },
      {
        path: "status",
        name: "Status",
        orderable: true
      },

    ] as Columns<IDevicesList>[],
  });

  const tableStateDevices = useTableState(stateDevices.current);
  const { devicesList, auth } = useMst();
  const [devices, setDevices] = useState<any>([]);

  useEffect(() => {
    setDevices(userData?.devices || []);
  }, [userData])

  return (
    <>
      <Table
        id="id"
        customStyle="light-gray-header device-table"
        columns={tableStateDevices.columns}
        resetView={false}
        action={actionFunc}
        actionType="deleteDevice"
        tableState={tableStateDevices}
        loadingData={devicesList.loading}
        data={devices}
        pagination={devicesList.pagination}
        hidePagination={true}
        noDataMessage="A PillPez Device is not  connected to this end-user"
      />
      {auth.checkRoles('provider') ? (
        devices.length < 1 ? (
          <div className="add-device"
            onClick={() => {
              modalDeviceToggle();
            }}>
            <Add />
            <span>Add Device</span>
          </div>
        ) : (
          <div className="add-device disabled">
            <AddGray />
            <span>Add Device</span>
          </div>
        )
      ) : <></>}

      <Modal
        isShown={modalDeviceOpened}
        hide={modalDeviceToggle}
        modalContent={<AddDeviceModal
          onSubmit={modalDeviceToggle}
          onCancel={modalDeviceToggle}
          actionFunc={actionFunc}
        />}
      />
      <Modal
        isShown={modalDeleteOpened}
        hide={modalDeleteToggle}
        modalContent={<ConfirmationModal
          onConfirm={modalDeleteToggle}
          onCancel={modalDeleteToggle}
          actionFunc={() => { }}
        />}
      />
    </>
  );
}