import { useMst } from '@PillPez/models';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '@PillPez/components/Modals/DefaultModal/Modal';
import { useModal } from '@PillPez/components/Modals/DefaultModal/useModal';
import { EnduserWhatsThisPopup } from '@PillPez/components/Modals/WhatsThisEnduserModal';
import { DeviceWhatsThisPopup } from '@PillPez/components/Modals/WhatsThisDeviceModal';
import { CreateEndUserPayload } from '@PillPez/models/Endusers';
import { ModalWrapper } from './styles/addresident-modal.style';
import classNames from 'classnames';
interface AddEndUserProps {
  onSubmit: (name: string, id: string) => void;
  onCancel: () => void;
};

type FormData = {
  firstName: string;
  lastName: string;
  uniqueId: string;
  deviceId: string;
};

export const AddEndUserModal = ({ onSubmit, onCancel }: AddEndUserProps) => {
  const { endUserCreate } = useMst();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { isShown: modalEndUserWTOpened, toggle: modalEndUserWTToggle } =
    useModal();
  const { isShown: modalDeviceWTOpened, toggle: modalDeviceWTToggle } =
    useModal();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    shouldFocusError: false,
  });

  const onFormSubmit = handleSubmit(async (data) => {
    console.log(data);
    if (endUserCreate.loading) return;
    const model = CreateEndUserPayload.create(data);
    let resp = await endUserCreate.createEndUser(model);
    console.log(resp?.data);
    if (resp?.data?.status === 'Success') {
      onSubmit(`${firstName} ${lastName}`, `${resp?.data?.enduser?.id}`);
      setErrorMessage('')
    } else {
      setErrorMessage(resp?.error?.response?.data?.message);
    }
  });

  return (
    <ModalWrapper
      className={endUserCreate.loading ? 'busy' : ''}
    >
      <div className="inner">
        <p className="heading">Add A End-user</p>
        <form action="" onSubmit={onFormSubmit}>
          <div className="form-row">
            <div className="left">
              <p className="title">
                Name<span>*</span>
              </p>
            </div>
            <div className="inputs">
              <div
                className={classNames('input--group', {
                  'input--group--error':
                    errors.firstName
                })}
              >
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  {...register('firstName', {
                    required: true,
                    onChange: (e) => setFirstName(e.target.value)
                  })}
                />
                {errors.firstName && errors.firstName.type === 'required' && (
                  <span className="error">First Name is required</span>
                )}
              </div>
              <div
                className={classNames('input--group', {
                  'input--group--error':
                    errors.lastName
                })}
              >
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  {...register('lastName', {
                    required: true,
                    onChange: (e) => setLastName(e.target.value)
                  })}
                />
                {errors.lastName && errors.lastName.type === 'required' && (
                  <span className="error">Last Name is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="left">
              <p className="title">
                End-User id #<span>*</span>
              </p>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  modalEndUserWTToggle();
                }}
                className="info"
              >
                what is this?
              </a>
            </div>
            <div className="inputs single">
              <div
                className={classNames('input--group', {
                  'input--group--error':
                    errors.uniqueId
                })}
              >
                <input
                  type="text"
                  placeholder={'124356879'}
                  {...register('uniqueId', {
                    required: true,
                  })}
                />
                {errors.uniqueId && errors.uniqueId.type === 'required' && (
                  <span className="error">End-User ID is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="left">
              <p className="title">
                Device id #<span>*</span>
              </p>
              <a
                href="#"
                className="info"
                onClick={(e) => {
                  e.preventDefault();
                  modalDeviceWTToggle();
                }}
              >
                what is this?
              </a>
            </div>
            <div className="inputs single">
              <div
                className={classNames('input--group', {
                  'input--group--error':
                    errors.deviceId
                })}
              >
                <input
                  type="text"
                  placeholder={'124356879'}
                  {...register('deviceId', {
                    required: true,
                  })}
                />
                {errors.deviceId && errors.deviceId.type === 'required' && (
                  <span className="error">Device ID is required</span>
                )}
              </div>
            </div>
          </div>
          {errorMessage && <span className="error">{errorMessage}</span>}
          <div className="buttons-row">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onCancel();
              }}
              className="cancel close-add-resident-modal"
            >
              Cancel
            </a>
            <button
              className="portal-button"
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <Modal
        isShown={modalEndUserWTOpened}
        hide={modalEndUserWTToggle}
        modalContent={
          <EnduserWhatsThisPopup
            onSubmit={modalEndUserWTToggle}
          />
        }
      />
      <Modal
        isShown={modalDeviceWTOpened}
        hide={modalDeviceWTToggle}
        modalContent={
          <DeviceWhatsThisPopup
            onSubmit={modalDeviceWTToggle}
          />
        }
      />
    </ModalWrapper>
  );
};