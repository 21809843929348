import styled, { keyframes } from 'styled-components';

interface LabelProps {
  margin?: boolean;
}

const showIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const fadeIn = keyframes`
0% {
  background: transparent;
}
100% {
  background: #F5F5F7;
}
`;

const fadeOut = keyframes`
0% {
  background: #F5F5F7;
}
100% {
  background: transparent;
}
`;

export const InputField = styled.input`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #002E6F;
  height: 54px;
  padding: 0 25px;
  background: #F5F5F7;
  border: 2px solid #F5F5F7;
  border-radius: 7px;
  animation: .2s ease-in 0s 1 ${fadeIn};
`;

export const InputFieldContent = styled(InputField)`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  border-color: transparent;
  background: none;
  animation: .2s ease-in 0s 1 ${fadeOut};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 331px

  .masked {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #002E6F;
    height: 54px;
    padding: 0 25px;
    background: #F5F5F7;
    border: 2px solid #F5F5F7;
    border-radius: 7px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    border-color: transparent;
    background: none;
    animation: .2s ease-in 0s 1 ${fadeOut};
  }
`;

export const Label = styled.label<LabelProps>`
  line-height: 19px;
  color: #696F79;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  height: 22px;
  ${props => !props.margin && 'margin-left: 25px; transform: translateY(10px);'}
  transition: all .25s;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  animation: .3s ease-in 0s 1 ${showIn};


  .portal-button {
    width: 90px;
    height: 42px;
    margin-left: 15px;
  }
`;

export const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
`;