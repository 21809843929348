import { PopUp } from '@PillPez/components/UI/PopUp';
import { ReactComponent as Pill } from '@PillPez/assets/img/portal/pill.svg';
import {
  dayMonthDateFormat,
  firstDayOfCurrentWeekNew,
  getDayName,
  getTimeMeridian,
  stringDecoder,
} from '../../../helpers/functions';
import {
  EventType,
  useEnduserSchedule,
} from '../../../hooks/getEndUserSchedule';
import { ScheduleAction } from './ScheduleAction';
import React from 'react';

type Props = {
  userDetails: string;
  enduserDetails: any;
};

export const ScheduleWeekly = ({ userDetails, enduserDetails }: Props) => {
  const { scheduleEvents } = useEnduserSchedule(
    firstDayOfCurrentWeekNew(new Date()) as string
  );

  console.log({ events: scheduleEvents.events });

  return (
    <>
      <div className="weekly-schedule">
        <div className="row-days">
          <div className="col"></div>
          {scheduleEvents?.uniqueDays?.map((day: any, i: any) => {
            return (
                <div className="col" key={i}>
                  <p className="day">{getDayName(day)}</p>
                  <p className="num">{dayMonthDateFormat(day)}</p>
                </div>
            );
          })}
          <div className="col"></div>
        </div>
        <div className="columns">
          {[...scheduleEvents.events.keys()].map((time: any, i: any) => {
            return (
              <React.Fragment key={i}>
                {Object.values(EventType).map((type: any, i: any) => {
                  if (scheduleEvents.events.get(time).get(type).size === 0) {
                    return (
                        <div key={i}></div>
                      );
                  }
                  return (
                    <div className="newcolumn" key={i}>
                      <div className="col">
                        <p className="num">{getTimeMeridian(time)}</p>
                      </div>
                      {scheduleEvents?.uniqueDays?.map((day: any, i: any) => {
                        // console.log({
                        //   c: scheduleEvents.events.get(time).get(type),
                        // });
                        return (
                          <div key={i}>
                            {!scheduleEvents.events
                              .get(time)
                              .get(type)
                              .has(day) && <div className="col"></div>}
                            {scheduleEvents.events
                              .get(time)
                              .get(type)
                              .has(day) &&
                              scheduleEvents.events
                                .get(time)
                                .get(type)
                                .get(day)
                                .map((e: any, i: any) => {
                                  return (
                                    <div className="col" key={i}>
                                      {e.type === 'package' ? (
                                        <div className="schedule-item">
                                          <Pill />
                                          <PopUp
                                            type="pill"
                                            action="Dispense Medication"
                                            time={getTimeMeridian(e.time)}
                                            repeats="Every day"
                                            editBy={userDetails}
                                            editOn="today"
                                          />
                                        </div>
                                      ) : (
                                        <div className="schedule-item">
                                          <div className="aux">aux</div>
                                          <PopUp
                                            type="aux"
                                            action={stringDecoder(e.title)}
                                            time={getTimeMeridian(e.time)}
                                            message={stringDecoder(
                                              e.description
                                            )}
                                            editBy={userDetails}
                                            editOn="today"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                          </div>
                        );
                      })}
                      <div className="col">
                        <ScheduleAction
                          id={
                            scheduleEvents.events
                              .get(time)
                              .get(type)
                              .entries()
                              .next().value?.[1]?.[0].id
                          }
                          enduserDetails={enduserDetails}
                        />
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};
