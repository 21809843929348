import { httpClient } from '@PillPez/client';
import { TableApiPayload } from '@PillPez/hooks/useTableState';
import { PaginatedResponse, PaginationData } from '@PillPez/types';
import { types, toGenerator, flow, Instance } from 'mobx-state-tree';
import { AxiosError } from 'axios';

export type ICaregiver = {
    id?: number;
    numberOfUsers: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
}

export const CaregiverData = types.model({
    id: types.maybe(types.number),
    numberOfUsers: types.maybe(types.number),
    firstName: types.string,
    lastName: types.string,
    emailAddress: types.string,
})
export const CaregiverList = types.model({
    data: types.optional(types.array(CaregiverData), []),
    pagination: types.maybe(PaginationData),
    loading: types.optional(types.boolean, false),
}).actions((self) => {
    function* caregivers(payload: TableApiPayload<ICaregiver>) {
        self.loading = true;
        try {
            const response = yield* toGenerator(httpClient.post<PaginatedResponse<ICaregiver>>('caregivers/table', payload));
            self.loading = false;
            self.data = response.data.data as unknown as any;
            self.pagination = {
                recordsFiltered: response.data.recordsFiltered,
                recordsTotal: response.data.recordsTotal,
            }
            return response.data
        } catch (error) {
            self.loading = false;
            console.log(error);
        }
    }
    const setCaregivers = (data: ICaregiver[]) => {
        self.data = data as any;
    }
    return {
        getCaregiversTable: flow(caregivers),
        setCaregivers,
    }
})


export type IUpdateCaregiverResponse = {
    data: ICaregiver[]
}

export type UpdateCaregiverResponse = {
    data: IUpdateCaregiverResponse;
}

export const UpdateCaregiver = types.model({
    data: types.optional(types.array(CaregiverData), []),
    loading: types.optional(types.boolean, false),
}).actions((self) => {
    function* updateCaregiver(id: string, payload: object) {
        self.loading = true;
        try {
            const response = yield* toGenerator(httpClient.put(`caregivers/${id}`, payload));
            self.loading = false;
            return { data: response.data } as UpdateCaregiverResponse
        } catch (error) {
            self.loading = false;
            console.log(error);
        }
    }
    return {
        updateCaregiver: flow(updateCaregiver),
    }
})

export type ICreateCaregiverResponse = {
    data: ICaregiver[]
    status: string;
}

export type CreateCaregiverResponse = {
    data: ICreateCaregiverResponse;
    success: boolean;
    error: any;
}

export const CreateCaregiverPayload = types.model({
    firstName: types.optional(types.string, ''),
    lastName: types.optional(types.string, ''),
    emailAddress: types.optional(types.string, ''),
}).actions(self => ({
    setFirstName(firstName: string) {
        self.firstName = firstName;
    },
    setLastName(lastName: string) {
        self.lastName = lastName;
    },
    setEmail(emailAddress: string) {
        self.emailAddress = emailAddress;
    },
    payload() {
        return {
            firstName: self.firstName,
            lastName: self.lastName,
            emailAddress: self.emailAddress,
        }
    }
}));

export interface ICreateCaregiverPayload extends Instance<typeof CreateCaregiverPayload> { }

export const CreateCaregiver = types.model({
    data: types.optional(types.array(CaregiverData), []),
    loading: types.optional(types.boolean, false),
}).actions((self) => {
    function* createCaregiver(model: ICreateCaregiverPayload) {
        self.loading = true;
        try {
            const response = yield* toGenerator(httpClient.post(`caregivers/invite`, model.payload()));
            self.loading = false;
            return { data: response.data } as CreateCaregiverResponse
        } catch (error) {
            self.loading = false;
            console.log(error);
            return { success: false, error: error } as CreateCaregiverResponse
        }
    }
    return {
        createCaregiver: flow(createCaregiver),
    }
})

export type IViewCaregiverResponse = {
    data: ICaregiver[]
}

export type ViewCaregiverResponse = {
    data: IViewCaregiverResponse
}

export const ViewCaregiver = types.model({
    data: types.optional(types.array(CaregiverData), []),
    error: types.frozen<AxiosError | any>(),
    loading: types.optional(types.boolean, false),
}).actions((self) => {
    function* viewCaregiver(id: string) {
        self.loading = true;
        try {
            const response = yield* toGenerator(httpClient.get(`caregivers/${id}`));
            self.loading = false;
            return { success: true, data: response.data } as ViewCaregiverResponse
        } catch (error) {
            self.loading = false;
            if (error instanceof AxiosError) {
                return { message: error, status: 'Fail' }
            };
        }
    }
    return {
        viewCaregiver: flow(viewCaregiver),
    }
})


// Delete Caregiver
export type IDeleteCaregiverResponse = {
    data: ICaregiver[]
}

export type DeleteCaregiverResponse = {
    data: IDeleteCaregiverResponse;
}

export const DeleteCaregiverData = types.model({})
export const DeleteCaregiver = types.model({
    data: types.optional(types.array(DeleteCaregiverData), []),
    error: types.frozen<AxiosError | any>(),
    loading: types.optional(types.boolean, false),
}).actions((self) => {
    function* deleteCaregiver(id: string) {
        self.loading = true;
        try {
            const response = yield* toGenerator(httpClient.delete(`caregivers/${id}`));
            self.loading = false;
            return { data: response.data } as DeleteCaregiverResponse
        } catch (error: unknown) {
            self.loading = false;
            if (error instanceof AxiosError) {
                return { message: error.response, status: 'Fail' }
            };
        }
    }
    return {
        deleteCaregiver: flow(deleteCaregiver),
    }
})


export type IGetCaregiversResponse = {
    data: ICaregiver[]
    caregivers: any;
}

export type GetCaregiversResponse = {
    data: IGetCaregiversResponse;
}

export const GetCaregiversData = types.model({})
export const GetCaregivers = types.model({
    data: types.optional(types.array(GetCaregiversData), []),
    loading: types.optional(types.boolean, false),
}).actions((self) => {
    function* getCaregivers() {
        self.loading = true;
        try {
            const response = yield* toGenerator(httpClient.get(`caregivers/`));
            self.loading = false;
            return { data: response.data } as GetCaregiversResponse
        } catch (error) {
            self.loading = false;
            console.log(error);
        }
    }
    return {
        getCaregivers: flow(getCaregivers),
    }
})


export type IAttachCaregiverToEnduserResponse = {
    data: ICaregiver[]
    status: string;
}

export type AttachCaregiverToEnduserResponse = {
    data: IAttachCaregiverToEnduserResponse;
}

export const AttachCaregiverToEnduserData = types.model({})
export const AttachCaregiverToEnduser = types.model({
    data: types.optional(types.array(AttachCaregiverToEnduserData), []),
    loading: types.optional(types.boolean, false),
}).actions((self) => {
    function* attachCaregiverToEnduser(id: string | undefined, enduserid: string | undefined) {
        self.loading = true;
        try {
            const response = yield* toGenerator(httpClient.post(`caregivers/${id}/endusers/${enduserid}`));
            self.loading = false;
            return { data: response.data } as AttachCaregiverToEnduserResponse
        } catch (error) {
            self.loading = false;
            console.log(error);
        }
    }
    return {
        attachCaregiverToEnduser: flow(attachCaregiverToEnduser),
    }
})


export type IDetachCaregiverToEnduserResponse = {
    data: ICaregiver[]
    status: string;
}

export type DetachCaregiverToEnduserResponse = {
    data: IDetachCaregiverToEnduserResponse;
}

export const DetachCaregiverToEnduserData = types.model({})
export const DetachCaregiverToEnduser = types.model({
    data: types.optional(types.array(DetachCaregiverToEnduserData), []),
    loading: types.optional(types.boolean, false),
}).actions((self) => {
    function* detachCaregiverToEnduser(id: string | undefined, enduserid: string | undefined) {
        self.loading = true;
        try {
            const response = yield* toGenerator(httpClient.delete(`caregivers/${id}/endusers/${enduserid}`));
            self.loading = false;
            return { data: response.data } as DetachCaregiverToEnduserResponse
        } catch (error) {
            self.loading = false;
            console.log(error);
        }
    }
    return {
        detachCaregiverToEnduser: flow(detachCaregiverToEnduser),
    }
})
