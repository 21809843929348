import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import login_bg from '@PillPez/assets/img/portal/login_bg.png';
import { ReactComponent as LoginLogo } from '@PillPez/assets/img/portal/login-logo.svg';
import { ReactComponent as LockGray } from '@PillPez/assets/img/portal/lock-gray.svg';
import { PortalFooter } from '@PillPez/components/Footer';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  FieldErrors,
  LoginError,
  LoginErrorModel,
  LoginPayload,
} from '@PillPez/models/Auth';
import { useMst } from '@PillPez/models';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { guests } from '@PillPez/hooks/guests';
const LoginBg = styled.div`
  background-image: url(${login_bg});
`;

const initialState = {
  email:
    process.env.NODE_ENV === 'development' ? 'danz+mary3@actiondigital.co' : '',
  password: process.env.NODE_ENV === 'development' ? 'freddy123' : '',
};

type FormData = {
  email: string;
  password: string;
};

const LoginPortalScreen = styled.div`
  button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .right.busy {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .input--group--error {
    label {
      color: #ff0000;
    }
    input {
      border-color: #ff0000;
    }
    .error {
      color: #ff0000;
      margin-top: 5px;
    }
  }
`;

const Login = () => {
  const { auth } = useMst();
  const location = useLocation();
  const state = location.state as {
    caregiverPasswordSet?: boolean;
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: initialState,
    shouldFocusError: false,
  });
  const onSubmit = handleSubmit(async (data) => {
    if (auth.loading) return;
    const model = LoginPayload.create(data);
    let resp = await auth.login(model);
    if (resp.success && resp?.data?.access_token) {
      if (resp?.data?.user.roles.length === 0) {
        auth.logout();
        auth.setError(
          LoginErrorModel.create({
            error:
              'The user you are trying to login does not have any roles attached. Please try another user or contact us for support.',
            type: LoginError.NoRoles,
            errorPerFields: {} as FieldErrors,
          })
        );
      } else {
        auth.setToken(resp?.data?.access_token);
        auth.setUser(resp?.data?.user);
        let returnTo = null;
        if (auth.returnTo) {
          returnTo = auth.returnTo;
          auth.setReturnTo(null);
        }
        navigate(returnTo ? returnTo : '/');
      }
    }
    // if(resp.)
  });
  const navigate = useNavigate();
  return (
    <LoginPortalScreen id="login" className="portal-screen login">
      <div className="first-row">
        <LoginBg className="left login_bg">
          <LoginLogo />
        </LoginBg>
        <div
          className={classNames('right', {
            busy: auth.loading,
          })}
        >
          <div className="inner">
            <p className="title">Log In</p>
            <p className="secondary">Welcome to the PillPez Client Portal.</p>
            <form action="" onSubmit={onSubmit}>
              {auth?.error?.type === LoginError.InvalidCredentials && (
                <div className="p-4 bg-red-300 mb-4">
                  <p className="font-semibold">
                    {auth.error.error.response.data.message}
                  </p>
                </div>
              )}
              {auth?.error?.type === LoginError.NoRoles && (
                <div className="p-4 bg-red-300 mb-4">
                  <p className="font-semibold">{auth.error.error}</p>
                </div>
              )}
              {auth?.error?.type === LoginError.NotHandled && (
                <div className="p-4 bg-red-300 mb-4">
                  <p className="font-semibold">
                    An error occurred while logging in. Please try again.
                  </p>
                </div>
              )}
              {state?.caregiverPasswordSet && (
                <div className="p-4 bg-green-400 mb-4">
                  <p className="font-semibold">
                    Your caregiver password has been set. You can now log in.
                  </p>
                </div>
              )}
              <div
                className={classNames('input--group', {
                  'input--group--error':
                    errors.email || auth?.error?.errorPerFields?.emailAddress,
                })}
              >
                <label htmlFor="">Email Address</label>
                <input
                  type="email"
                  {...register('email', {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  })}
                />
                {errors.email && errors.email.type === 'required' && (
                  <span className="error">Email is required</span>
                )}
                {errors.email && errors.email.type === 'pattern' && (
                  <span className="error">Enter a valid email</span>
                )}
                {auth.error &&
                  auth.error.errorPerFields.emailAddress &&
                  auth.error.errorPerFields.emailAddress.map((err, idx) => {
                    return (
                      <span className="error" key={`errorEmail${idx}`}>
                        {err}
                      </span>
                    );
                  })}
              </div>
              <div
                className={classNames('input--group', {
                  'input--group--error':
                    errors.password || auth?.error?.errorPerFields?.password,
                })}
              >
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  {...register('password', { required: true })}
                />
                {errors.password && errors.password.type === 'required' && (
                  <span className="error">Password is required</span>
                )}
                {auth.error &&
                  auth.error.errorPerFields.password &&
                  auth.error.errorPerFields.password.map((err, idx) => {
                    return (
                      <span className="error" key={`errorPassword${idx}`}>
                        {err}
                      </span>
                    );
                  })}
              </div>
              <button className="portal-button" disabled={auth.loading}>
                Submit
              </button>
            </form>
            <a className="forgot-pass">
              Need assistance Logging in? Contact your PillPez representative
            </a>
            <p className="secured">
              <LockGray />
              Your Info is safely secured
            </p>
          </div>
        </div>
      </div>
      <PortalFooter />
    </LoginPortalScreen>
  );
};

export const LoginScreen = observer(guests(Login, '/enduser-summary'));
