import styled from 'styled-components';

export const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;

  .portal-white-button,
  .portal-button {
    width: 170px;
    height: 40px;
  }

  .portal-button {
    justify-content: center;
    margin-left: 10px;
  }
`;

export const Message = styled.div`
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: center;
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 43px 35px 28px;

  .inner {
    width: 990px;
    height: 720px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p.success-title {
        font-family: "Libre Baskerville", sans-serif;
        font-weight: 400;
        font-size: 50px;
        line-height: 62px;
    }
    p.title {
        display: inline-block;
        min-width: 100%;
        margin-top: 10px;
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        span {
            font-weight: 700;
        }
    }
    p.caregiver-title {
        display: inline-block;
        margin-top: 10px;
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        max-width: 800px;
        span {
            font-weight: 700;
        }
    }
    p.cause {
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.02em;
        width: 615px;
        margin-top: 10px;
        text-align: left;
    }
    .list {
        margin: 38px 0 -30px;
        width: 403px;
        height: 156px;
        overflow-y: auto;
        background: rgba(196, 196, 196, 0.15);
        border-radius: 31px;
        padding: 18px 30px;
        ul {
            li {
                p {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                }
            }
        }
    }
    .buttons-block {
        display: flex;
        align-items: center;
        margin-top: 90px;
        a.not-right-now-button-caregiver-can-not-delete,
        a.not-right-now-button-caregiver-success,
        a.not-right-now-button {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-decoration-line: underline;
        }
        a.portal-button {
            width: 250px;
            height: 50px;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin-left: 27px;
            justify-content: center;
        }
    }
}
  }
  
`;

export const ConfirmationMessage = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -1px;
  color: #002E6F;
  justify-content: center;
`;

export const SecondaryMessage = styled.p`
  margin-top: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #002E6F;
  letter-spacing: 0.4px;
  text-align: center;
  justify-content: center;
`;

