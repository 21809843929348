import { ReactComponent as Refill } from '@PillPez/assets/img/portal/refill.svg';
import { ReactComponent as Dispense } from '@PillPez/assets/img/portal/legeng-despense.svg';
import { ReactComponent as DispenseLate } from '@PillPez/assets/img/portal/legeng-despense-late.svg';
import { ReactComponent as RactGreen } from '@PillPez/assets/img/portal/ract-green.svg';
import { ReactComponent as RactYellow } from '@PillPez/assets/img/portal/ract-yellow.svg';
import { ReactComponent as Calendar } from '@PillPez/assets/img/portal/calendar.svg';
import arr_prev from '@PillPez/assets/img/portal/arr_prev.png';
import arr_next from '@PillPez/assets/img/portal/arr_next.png';
import arr_top from '@PillPez/assets/img/portal/arr_top.png';
import arr_bottom from '@PillPez/assets/img/portal/arr_bottom.png';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import { httpClient } from '@PillPez/client';
import { useState } from 'react';
import { DateTime, Duration } from 'luxon';
import { useDebounce, useThrottleFn } from 'react-use';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Timetable from 'react-timetable-events';
import { Events } from 'react-timetable-events/dist/types';
import { PopUp } from '../UI/PopUp';
import { firstDayOfCurrentWeekNew } from '@PillPez/helpers/functions';

export const Adherence = () => {
  const { enduserId } = useParams();

  const [summary, setSummary] = useState<Summary>({
    adherenceScore: 0,
    dispenseCount: 0,
    lateEventsCount: 0,
    loadEvents: 0,
    ontimeEventsCount: 0,
    period_days: 7,
  });

  let st = DateTime.fromJSDate(
    firstDayOfCurrentWeekNew(new Date(), true) as Date
  );

  const [period, setPeriod] = useState<Period>({
    start: st,
    end: st.plus({ days: 7 }),
  });

  const [adherence, setAdherence] = useState<Adherence>({});

  const [events, setEvents] = useState<Events>({});
  const [originalDays, setOriginalDays] = useState<any>([]);

  const getTimes = (e: any) => {
    let d = DateTime.fromISO(e.actionTime, {
      locale: 'en-US',
    }).setZone(e.tz);
    if (e.actionType === 'deviceLoadAction') {
      return { actionType: e.actionType, actionTime: d };
    }
    let earlyHour = e.earlyTime.split(':');
    let lateHour = e.lateTime.split(':');
    let onTimeHour = e.onTime.split(':');

    let early = d.set({
      hour: earlyHour[0],
      minute: earlyHour[1],
      second: earlyHour[2],
    });
    let late = d.set({
      hour: lateHour[0],
      minute: lateHour[1],
      second: lateHour[2],
    });
    let onTime = d.set({
      hour: onTimeHour[0],
      minute: onTimeHour[1],
      second: onTimeHour[2],
    });
    return {
      early,
      late,
      onTime,
      actual: d,
      actionType: e.actionType,
    };
  };

  useEffect(() => {
    let _events: any = {};
    if (adherence.days) {
      let originalDays = adherence.days.map((obj) => obj.date);
      for (let day of adherence.days) {
        for (let event of day.events) {
          let d = DateTime.fromISO(event.actionTime, {
            locale: 'en-US',
          }).setZone(adherence.deviceTimeZone);
          // format d as Y-m-d
          let keyToAdd = d
            .setZone(adherence.deviceTimeZone)
            .toFormat('yyyy-MM-dd');
          if (!_events[keyToAdd]) {
            _events[keyToAdd] = [];
          }
          event.tz = adherence.deviceTimeZone;
          _events[keyToAdd].push({
            ...event,
            times: getTimes(event),
            displayHour: d.toFormat('h a').toLowerCase(),
            tz: adherence.deviceTimeZone,
          });
        }
      }
      setEvents(_events);
      setOriginalDays(originalDays);
    }
  }, [adherence]);

  const updatedPeriod = (mode: 'start' | 'end', val: DateTime) => {
    let start, end;
    if (mode === 'start') {
      start = val;
      end = val.plus({ days: 7 });
    } else {
      end = val;
      start = val.minus({ days: 7 });
    }
    let newOne = { ...period };
    if (!start.invalidReason) {
      newOne.start = start;
    }
    if (!end.invalidReason) {
      newOne.end = end;
    }
    setPeriod(newOne);
    setSummary(
      (sum) =>
        ({
          ...sum,
          //@ts-ignore
          period_days: parseInt(newOne.end.diff(newOne.start, 'day').days),
        } as Summary)
    );
  };

  const decideColor = (e: any) => {
    if (
      !['onTime', 'graceEarly', 'late', 'unknown', 'graceLate'].includes(
        e.adherenceStatus
      )
    ) {
      console.info(e.adherenceStatus);
    }
    if (e.actionType === 'deviceLoadAction' || e.adherenceStatus === 'late') {
      return '#FF3C33';
    }
    if (e.adherenceStatus === 'onTime') {
      return '#00C853';
    }
    if (
      e.adherenceStatus === 'graceEarly' ||
      e.adherenceStatus === 'early' ||
      e.adherenceStatus === 'graceLate'
    ) {
      return 'rgb(236, 210, 50)';
    }
    return '#000000';
  };

  useDebounce(
    async () => {
      if (!enduserId) {
        return;
      }
      let resp = await httpClient.get<AdherenceResponse>(
        `/endusers/${enduserId}/adherence`,
        {
          params: {
            startDate: period.start.toFormat('yyyy-MM-dd'),
            numDays: period.end.diff(period.start, 'day').days,
          },
        }
      );
      setSummary({
        ...resp.data.summary,
        period_days: period.end.diff(period.start, 'day').days,
      } as Summary);
      setAdherence(resp.data.adherence as Adherence);
    },
    100,
    // @ts-ignore
    [period.start.toISODate(), period.end.toISODate()]
  );

  // create an array from 12 am to 11 pm
  const hours = Array.from({ length: 24 }, (_, i) => i).map((i) => {
    // format to 12 hour clock
    return DateTime.local()
      .set({ hour: i, minute: 0, second: 0, millisecond: 0 })
      .toFormat('h a')
      .toLowerCase();
  });

  const last7days = Array.from({ length: 7 }, (_, i) => i).map((i) => {
    return st.plus({ days: i }).toFormat('yyyy-MM-dd');
  });

  const [coords, setCoords] = useState({x: 400, y: 400});


  const handleMouseOver = (event:any) => {
    setCoords({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const handleMouseOut = (event:any) => {
    setCoords({
      x: -400,
      y: -400,
    });
  };

  return (
    <div className="schedule-container">
      <div className="row-header">
        <p className="title">Adherence Calendar</p>
      </div>
      <div className="schedule-inner adherence">
        <div className="left">
          <div className="row">
            <p className="row-title">Summary</p>
            <div className="pill-packs">
              <div className="row period-days">
                <p className="title">Period (Days)</p>
                <span className="cumber">
                  {Math.trunc(summary.period_days)}
                </span>
              </div>
              <div className="row">
                <p className="title">Dispense Count</p>
                <span className="cumber">{summary.dispenseCount}</span>
              </div>
              <div className="row">
                <p className="title">On time</p>
                <span className="cumber">{summary.ontimeEventsCount}</span>
              </div>
              <div className="row">
                <p className="title">Late</p>
                <span className="cumber">{summary.lateEventsCount}</span>
              </div>
              <div className="row">
                <p className="title">Refills</p>
                <span className="cumber">{summary.loadEvents}</span>
              </div>
              <div className="row total adherence">
                <p className="title">Adherence %</p>
                <span className="cumber">{summary.adherenceScore}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <p className="row-title">Legend</p>
            <div className="legend-row">
              <p>
                <span style={{ color: '#42C237' }}>
                  <Dispense />
                </span>
                Dispensed on time
              </p>
              <p>
                <span style={{ color: 'rgb(236, 210, 50)' }}>
                  <Dispense />
                </span>
                Grace period
              </p>
              <p>
                <span>
                  <DispenseLate />
                </span>
                Missed
              </p>
              <p>
                <span>
                  <Refill />
                </span>
                Refill
              </p>
              <p>
                <span>aux</span>
                Auxiliary Reminder
              </p>
              
            </div>
          </div>
        </div>
        <div className="right">
          <div className="row">
            <p className="row-title">Date Range</p>
          </div>
          <div className="dates-range">
            <div className="col">
              <label className="date">Start Date:</label>
              <DatePicker
                className="adherence-date"
                selected={period.start.toJSDate()}
                dateFormat="MM/dd"
                onChange={(e) => {
                  if (!e) {
                    return;
                  }
                  setPeriod({
                    start: DateTime.fromJSDate(e),
                    end: period.end,
                  });
                  updatedPeriod('start', DateTime.fromJSDate(e));
                }}
              />
              <Calendar />
            </div>
            <div className="col">
              <label className="date">End Date: </label>
              <DatePicker
                className="adherence-date"
                selected={period.end.toJSDate()}
                dateFormat="MM/dd"
                onChange={(e) => {
                  if (!e) {
                    return;
                  }
                  updatedPeriod('end', DateTime.fromJSDate(e));
                }}
              />
              <Calendar />
            </div>
          </div>

          {/* <pre>{JSON.stringify(events, null, 2)}</pre> */}
          <div className="weekly-schedule-wrapper"></div>
          {/* <Timetable events={events} className="timetable-wrapper" /> */}
          <div className="weekly-schedule">
            <a className="arr-bottom">
              <img src={arr_top} alt="" />
            </a>
            <div className="row-days days-row">
              <div className="col">
                <a className="arr-top">
                  <img src={arr_bottom} alt="" />
                </a>
              </div>
              {(originalDays.length > 0 ? originalDays : last7days).map(
                (day: any) => (
                  <div className="col" key={day}>
                    <p className="day">
                      {DateTime.fromFormat(day, 'yyyy-LL-dd').toFormat('ccc')}
                    </p>
                    <p className="num">
                      {DateTime.fromFormat(day, 'yyyy-LL-dd').toFormat('L/d')}
                    </p>
                  </div>
                )
              )}
              <a className="arr-calendar prev">
                <img src={arr_prev} alt="" />
              </a>
              <a className="arr-calendar next">
                <img src={arr_next} alt="" />
              </a>
            </div>
            <div className="hours">
              {hours.map((hour) => (
                <div className="row-days" key={hour}>
                  <div className="col">
                    <p className="num">{hour}</p>
                  </div>
                  {(originalDays.length > 0 ? originalDays : last7days).map(
                    (day: any) => {

                      
                      return (
                        <div className="col" key={day}
                            style={{flexDirection: "row", justifyContent: "space-evenly"}}
                          >
                          {events[day] &&
                          events[day].filter((e) => e.displayHour === hour)
                            .length > 0 ? (
                            events[day]
                              .filter((e) => e.displayHour === hour)
                              .map((e: any) => {

                                console.log("Event is:", e);


                                if (e.type != 'deviceLoadAction') {
                                  console.log({ e });
                                }
                                return (
                                  <div
                                    className="event schedule-item"
                                    key={e.id}
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={handleMouseOut}

                                  >
                                    <>
                                      {e.type === 'package' ? (
                                        <span style={{ color: decideColor(e) }}>
                                          <Dispense
                                            className="pill"
                                            style={{ left: '50%', top: '50%' }}
                                          />
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      {e.type === 'task' && (
                                        <div>
                                          <span>aux</span>
                                        </div>
                                      )}

                                      {e.actionType === 'deviceLoadAction' && (
                                        <div>
                                          <Refill />
                                        </div>
                                      )}

                                      {e.actionType === 'deviceLoadAction' && (
                                         <PopUp coordsx={coords.x} coordsy={coords.y} >
                                          <> 
                                            <div className="row">
                                              <p className="title">
                                                Event Type:
                                              </p>
                                              <p className="value">
                                                Refill
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="title">
                                                Refill Time:
                                              </p>
                                              <p className="value">
                                                {e.times.actionTime.toFormat('h:mm a')
                                                  .toLowerCase()}
                                              </p>
                                            </div>
                                          </>
                                         </PopUp>
                                      )}

                                      {(e.type === 'package' || e.type === 'task')  && (
                                        <PopUp coordsx={coords.x} coordsy={coords.y} >
                                          <>
                                            {/* <pre>{JSON.stringify(e, null, 2)}</pre> */}
                                            <div className="row">
                                              <p className="title">
                                                Event Type:
                                              </p>
                                              <p className="value">
                                                {e.type === 'package' && (
                                                  <>Pill</>
                                                )}

                                                {e.type === 'task' && (
                                                  <>aux</>
                                                )}
                                              </p>
                                            </div>

                                            <div className="row">
                                              <p className="title">
                                                Early time:
                                              </p>
                                              <p className="value">
                                                {e.times.early
                                                  .toFormat('h:mm a')
                                                  .toLowerCase()}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="title">On time:</p>
                                              <p className="value">
                                                {e.times.onTime
                                                  .toFormat('h:mm a')
                                                  .toLowerCase()}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="title">
                                                Late time:
                                              </p>
                                              <p className="value">
                                                {e.times.late
                                                  .toFormat('h:mm a')
                                                  .toLowerCase()}
                                              </p>
                                            </div>
                                            <div className="row">
                                              <p className="title">
                                                Actual dispense:
                                              </p>
                                              <p className="value">
                                                {e.times.actual
                                                  .toFormat('h:mm a')
                                                  .toLowerCase()}
                                              </p>
                                            </div>
                                          </>
                                        </PopUp>
                                      )}
                                    </>
                                  </div>
                                );
                              })
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              ))}
              {/* <div className="row-days">
                <div className="col">
                  <p className="num">3 am</p>
                </div>
                <div className="col">
                  <Dispense
                    className="pill"
                    style={{ left: '50%', top: '50%' }}
                  />
                </div>
                <div className="col">
                  <Dispense
                    className="pill"
                    style={{ left: '60%', top: '0' }}
                  />
                </div>
                <div className="col"></div>
                <div className="col"></div>
                <div className="col"></div>
                <div className="col">
                  <Dispense
                    className="pill"
                    style={{ left: '40%', top: '50%' }}
                  />
                </div>
                <div className="col"></div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export interface AdherenceResponse {
  adherence?: Adherence;
  summary?: Summary;
}

export interface Adherence {
  startDate?: Date;
  endDate?: Date;
  days?: Day[];
  deviceTimeZone?: string;
}

export interface Day {
  date: string;
  events: Event[];
}

export interface Period {
  start: DateTime;
  end: DateTime;
}

export interface Event {
  id: number;
  eventId: null;
  title: null;
  description: null;
  type: null;
  actionTime: string;
  isAdherent: number;
  tz?: string;
}

export interface Summary {
  dispenseCount: number;
  ontimeEventsCount: number;
  lateEventsCount: number;
  loadEvents: number;
  adherenceScore: number;
  period_days: any;
}
