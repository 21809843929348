import { roleProtected } from '@PillPez/hooks/roleProtected';
import { tokenProtected } from '@PillPez/hooks/tokenProtected';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const SuperIndex = tokenProtected(
  roleProtected(() => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate('/');
    }, []);
    return <></>;
  }, 'superAdmin')
);
