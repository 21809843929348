import styled from 'styled-components';
import checkmark from '@PillPez/assets/img/portal/checkbox_mark.svg'

export const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;

  .portal-white-button,
  .portal-button {
    width: 170px;
    height: 40px;
  }

  .portal-button {
    justify-content: center;
    margin-left: 10px;
  }
`;

export const Message = styled.div`
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: center;
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 43px 35px 28px;

  .inner {
    width: 995px;
    transition: .3s all ease;
    p.heading {
        font-family: "Libre Baskerville", sans-serif;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.25px;
        padding-left: 20px;
    }
    form {
        margin-top: 21px;
        position: relative;

        .error {
            color: #ff0000;
            margin-top: 5px;
          }
        .form-row {
            border-bottom: 1px solid #C4C4C4;
            padding-bottom: 19px;
            padding-left: 20px;
            &.dose {
                padding-top: 21px;
                padding-bottom: 27px;
                p.row-title {
                    margin-bottom: 4px;
                }
                .dose-date-block {
                    border: 1px solid #667080;
                    border-radius: 6px;
                    width: 175px;
                    position: relative;
                    input.date-pill-pack {
                        border: none;
                        height: 48px;
                        border-radius: 6px;
                        width: 165px;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: 3px;
                        color: #002E6F;
                        padding: 0 12px;
                        &:focus {
                            outline: none;
                        }
                    }
                    .am-pm {
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        transform: translateY(-50%);
                        span {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            letter-spacing: -0.02em;
                            color: #002E6F;
                            cursor: pointer;
                            &.active {
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
            &.days {
                margin-top: 12px;
                p.secondary {
                    font-style: italic;
                    margin-top: 11px;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.02em;
                }
                .select-days {
                    display: flex;
                    align-items: center;
                    input {
                        width: 12px;
                        height: 12px;
                        cursor: pointer;
                        position: relative;
                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 16px;
                            height: 16px;
                            border: 1.5px solid #002E6F;
                            border-radius: 3px;
                            background-color: #fff;
                        }
                        &:checked:after {
                            content: '';
                            border-radius: 3px;
                            transform: translate(-50%, -50%);
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            height: 12px;
                            width: 12px;
                            background-image: url(${checkmark});
                            background-position: center center;
                            background-size: contain;
                        }
                    }
                    label {
                        font-weight: 400;
                        font-size: 16px;
                        cursor: pointer;
                        line-height: 22px;
                        letter-spacing: -0.02em;
                        font-family: 'Inter', sans-serif;
                        color: #002E6F;
                    }
                    &.all {
                        margin-top: 27px;
                        input {
                            margin-right: 10px;
                        }
                        label {
                            font-size: 14px;
                        }
                    }
                }
                .days-block {
                    display: flex;
                    margin-top: 24px;
                    margin-bottom: 24px;
                    .select-days {
                        display: flex;
                        flex-direction: column;
                        margin-right: 20px;
                        label {
                            margin-bottom: 4px;
                        }
                    }
                }
            }
            p.row-title {
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.02em;
                span {
                    color: red;
                }
            }
            .switcher-small {
                width: 150px;
            }

            .switcher {
                width: 290px;
            }

            .switcher,
            .switcher-small {
                height: 60px;
                border: 1px solid #002E6F;
                border-radius: 30px;
                margin-top: 13px;
                display: flex;
                justify-content: space-between;
                position: relative;
                .item {
                    cursor: pointer;
                    img {
                        opacity: .3;
                    }
                    p {
                        font-size: 12px;
                        line-height: 15px;
                        margin-top: 5px;
                        text-transform: uppercase;
                        color: #667080;
                    }
                    span {
                        font-weight: 700;
                        color: #667080;
                    }
                    &.pill-pack {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 150px;
                        &.active {
                            background: rgba(37, 159, 218, 0.12);
                            border: 1px solid #002E6F;
                            border-radius: 30px;
                            img {
                                opacity: 1;
                            }
                            p {
                                font-weight: 700;
                                color: #002E6F;
                            }
                        }
                    }
                    &.auxiliary {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        width: 160px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        &.active {
                            background: rgba(37, 159, 218, 0.12);
                            border-left: 1px solid #002E6F;
                            border-radius: 30px;
                            p, span {
                                font-weight: 700;
                                color: #002E6F;
                            }
                        }
                    }
                }
            }
        }
        .form-buttons {
            display: flex;
            align-items: center;
            margin-top: 17px;
            padding-left: 20px;
            a.cancel {
                font-size: 16px;
                line-height: 19px;
                text-decoration-line: underline;
            }
            button {
                height: 42px;
                width: 90px;
                margin-left: 20px;
            }
        }
        .input--group--error {
            & input,
            & textarea {
                border: 1px solid #ff0000 !important;
            }
        }
        .add-item-aux {
            position: absolute;
            top: 0;
            right: 0;
            background: #fafafa;
            border-radius: 31px;
            width: 484px;
            padding: 12px 29px 75px 38px;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: -24px;
                width: 24px;
                height: 100%;
                background-color: #fff;
            }

            & .error {
                color: #ff0000;
                margin-top: 5px;
            }

            p.title {
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.02em;
                span {
                    color: red;
                }
            }
            select,
            input[type="text"] {
                width: 100%;
                background: #FFFFFF;
                border-radius: 6px;
                height: 47px;
                border: 2px solid #fff;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.02em;
                color: #002E6F;
                margin-top: 8px;
                cursor: pointer;
                padding: 0 12px;
                &:focus {
                    outline: none;
                    border: 2px solid #002E6F;
                }
            }
            p.add-aux-reminder-btn {
                margin: 13px 0 25px;
                justify-content: flex-end;
                a {
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.02em;
                    color: #00A0DF;
                    span {
                        font-weight: 700;
                        margin-left: 10px;
                        text-decoration: underline;
                    }
                }
            }
            textarea {
                margin-top: 7px;
                width: 100%;
                border: none;
                height: 95px;
                background: #FFFFFF;
                border-radius: 6px;
                padding: 12px 16px;
                resize: none;
                font-family: 'Inter', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.02em;
                color: #002E6F;
            }
            p.max-length {
                justify-content: flex-end;
                font-size: 14px;
                margin-bottom: 20px;
                line-height: 22px;
                letter-spacing: -0.02em;
                color: #C4C4C4;
            }
            .track-for-adherence {
                margin-top: 20px;
                display: flex;
                .item {
                    margin-right: 40px;
                    input {
                        position: relative;
                        cursor: pointer;
                        &:after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 20px;
                            height: 20px;
                            background-color: #fff;
                            border: 2px solid #002E6F;
                            border-radius: 50%;
                        }
                        &:checked:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 12px;
                            height: 12px;
                            background-color: #002E6F;
                            border-radius: 50%;
                            z-index: 22;
                        }
                    }
                    label {
                        font-family: 'Inter', sans-serif;
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: -0.02em;
                        color: #002E6F;
                        margin-left: 15px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
`;

export const ConfirmationMessage = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -1px;
  color: #002E6F;
  justify-content: center;
`;

export const SecondaryMessage = styled.p`
  margin-top: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #002E6F;
  letter-spacing: 0.4px;
  text-align: center;
  justify-content: center;
`;

