import { observer } from 'mobx-react-lite';

type Props = {
    progress: string;
}

export const ProgressBar = observer(function ({
    progress,
}: Props) {
    return (
        <div className="progress" >
            <div className="progress-text">{progress}%</div >
            <div className="progress-bar" style={{ width: `${progress}%` }}>
                <div className="progress-text">{progress}%</div>
            </div>
        </div>
    )
})

