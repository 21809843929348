import { ReactComponent as EditBtn } from "@PillPez/assets/img/portal/edit-table.svg";
import { useMst } from "@PillPez/models";
import { UpdateEndUserPayload } from "@PillPez/models/Endusers";
import { useEffect, useState } from "react";
import { ButtonsWrapper } from '../styles'
import { DateTime, Duration } from 'luxon';

type Props = {
  userDetails: string;
  enduserDetails: any;
  actionFunc: () => void;
}

const ReadMore = ({ children }: any) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <span className="desc">
      {isReadMore ? text.slice(0, 150) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {text.length > 150 && (
          <span>{isReadMore ? " Read More" : " Show Less"}</span>
        )}
      </span>
    </span>
  );
};

export const ScheduleInfo = ({ userDetails, enduserDetails, actionFunc }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [notes, setNotes] = useState('');

  const [errorMessage, setErrorMessage] = useState<boolean>();
  const { endUserUpdate } = useMst();

  const updateNotes = async (notes: any) => {
    console.log(notes);
    if (endUserUpdate.loading) return;
    const model = UpdateEndUserPayload.create({
      ...enduserDetails,
      wantsNotifications: enduserDetails.wantsNotifications === (0 || false) ? false : true,
      notes: notes,
    });
    console.log(model);
    let resp = await endUserUpdate.updateEndUser(enduserDetails.id as string, model)
    if (resp?.data?.status === 'Success') {
      setEditMode(false);
      setErrorMessage(false);
      actionFunc();
    } else {
      setErrorMessage(true);
    }
    console.log(resp);
  }

  return (
    <>
      <div className="schedule-info">
        <div className="row">
          <p>Last edited on: <span>
            
            { enduserDetails && enduserDetails.updatedAt && (
              <>
               {DateTime.fromISO(enduserDetails.updatedAt, {locale: 'en-US' }).toFormat('yyyy-MM-dd @ h:mm a') }
              </>
          )}
          </span></p>
          <p>Last edited by: <span>{userDetails}</span></p>
        </div>
        <div className="row">
          <p>Refil Reminder: <span>Every 14 days</span></p>
        </div>
        <div className="row">
          <p className="notes">Notes:
            {editMode ? (
              <textarea
                className="schedule-info__note"
                onChange={(e) => {
                  setNotes(e.target.value)
                }}
                defaultValue={enduserDetails.notes}
              ></textarea>
            ) : (
              <ReadMore>{enduserDetails.notes ? enduserDetails.notes : ""}</ReadMore>
            )}
          </p>
        </div>
        <a href="#" onClick={(e) => {
          e.preventDefault();
          setEditMode(!editMode)
        }} className="edit-btn">
          <EditBtn />
        </a>
      </div>
      {errorMessage && <span>ERROR updating note</span>}
      {editMode &&
        <ButtonsWrapper>
          <a href=""
            className="portal-cancel"
            onClick={(e) => {
              e.preventDefault();
              setEditMode(false);
            }
            }>Cancel</a>
          <button
            className="portal-button"
            onClick={() => {
              updateNotes(notes);
            }}
          >
            Save
          </button>
        </ButtonsWrapper>
      }
    </>
  );
}