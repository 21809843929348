import { PortalFooter, PortalHeader } from '@PillPez/components';
import { roleProtected } from '@PillPez/hooks/roleProtected';
import { tokenProtected } from '@PillPez/hooks/tokenProtected';
import {
  Controller,
  FieldErrors,
  useForm,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { useMst } from '../../models/Root';
import classNames from 'classnames';
import styled from 'styled-components';
import { useState, useEffect, useMemo, InputHTMLAttributes } from 'react';
import Select from 'react-select';
import { User } from '@PillPez/models/Auth';
import { useLocation, useNavigate } from 'react-router-dom';

type FormData = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  addressLine1: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  primaryPhone: string;
  smsPhone?: string;
  altPhone?: string;
  roles: any[];
  provider?: any;
};

const CreateUserDiv = styled.div`
  .content {
    background-color: #fff;
    padding-bottom: 100px;
  }
`;

type LocationState = {
  message?: string;
  provider_id?: number;
};

const DefaultValues = {
  firstName: '',
  lastName: '',
};

const CreateUser = () => {
  const { auth, superStore } = useMst();

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState;

  const [msg] = useState(state?.message || '');
  const [didPresetProvider, setDidPresetProvider] = useState(false);

  useEffect(() => {
    auth.getRoles();
    superStore.providers.getProviders();
  }, []);

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: DefaultValues,
    shouldFocusError: false,
  });

  const submit = async (data: FormData) => {
    let user = User.create({ ...data, roles: undefined });
    let resp = await auth.createUser({
      user: user,
      roles: data.roles ? data.roles.map((role) => role.value) : [],
      provider: data.provider ? data.provider.value : undefined,
      caregiver: 1,
    });
    if (resp?.success) {
      reset();
      navigate('/super/create-user', { state: { message: resp?.message } });
    }
  };

  useEffect(() => {
    if (
      !didPresetProvider &&
      state?.provider_id &&
      auth &&
      auth.roles &&
      auth.roles.length > 0 &&
      superStore.providers.list.length > 0
    ) {
      let provider = superStore.providers.list.find(
        (p) => p.id === state.provider_id
      );
      let roles = auth.roles.filter(
        (role) => role.name === 'admin' || role.name === 'provider'
      );
      if (provider) {
        reset(
          {
            ...DefaultValues,
            provider: { value: provider.id, label: provider.name },
            roles: roles.map((role) => ({ value: role.id, label: role.name })),
          },
          { keepDirtyValues: true }
        );
        setDidPresetProvider(true);
      }
    }
  }, [state, auth.roles, superStore.providers.list, didPresetProvider]);

  const availableRoles = useMemo(() => {
    if (!auth.roles) {
      return [];
    }
    return [...auth.roles].map((role) => ({
      value: role.id,
      label: role.name,
      description: role.description,
    }));
  }, [auth.roles]);

  const availableProviders = () => {
    if (!superStore.providers.list) {
      return [];
    }
    return [...superStore.providers.list].map((provider) => ({
      value: provider.id,
      label: provider.name,
      description: provider.description,
    }));
  };

  const hasProvider = useMemo(() => {
    let provider = availableRoles.find((role) => role.label === 'provider');
    if (!provider) {
      return false;
    }
    let roles = watch('roles');
    return roles && roles.find((role) => role.value === provider?.value);
  }, [availableRoles, watch('roles')]);

  return (
    <CreateUserDiv className="portal-screen create-user">
      <PortalHeader />
      <div className="content">
        <h1>Create User</h1>

        {msg && msg.length > 0 && (
          <div className="bg-green-400 p-3 rounded my-3">{msg}</div>
        )}

        <form onSubmit={handleSubmit(submit)}>
          <div className="residents-block">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <TextInput
                errors={errors}
                register={register('firstName', { required: true })}
                name="First Name"
                field="firstName"
              />
              <TextInput
                errors={errors}
                register={register('lastName', { required: true })}
                name="Last Name"
                field="lastName"
              />

              <TextInput
                errors={errors}
                register={register('emailAddress', {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                })}
                type="email"
                name="Email Address"
                field="emailAddress"
              />
              <TextInput
                errors={errors}
                register={register('password', {
                  required: true,
                })}
                name="Password"
                field="password"
                type={'password'}
              />
              <TextInput
                errors={errors}
                register={register('addressLine1', { required: true })}
                name="Address Line 1"
                field="addressLine1"
              />
              <TextInput
                errors={errors}
                register={register('city', { required: true })}
                name="City"
                field="city"
              />
              <TextInput
                errors={errors}
                register={register('stateProvince', { required: true })}
                name="State/Province"
                field="stateProvince"
              />
              <TextInput
                errors={errors}
                register={register('postalCode', { required: true })}
                name="Postal Code"
                autoComplete="postal-code"
                field="postalCode"
              />
              <TextInput
                errors={errors}
                register={register('primaryPhone', { required: true })}
                name="Primary Phone"
                field="primaryPhone"
                autoComplete="tel"
              />
              <TextInput
                errors={errors}
                register={register('smsPhone', { required: false })}
                name="SMS Phone"
                field="smsPhone"
                autoComplete="tel"
              />
              <TextInput
                errors={errors}
                register={register('altPhone', { required: false })}
                name="Alternate Phone"
                field="altPhone"
                autoComplete="tel"
              />
            </div>

            <div
              className={classNames('input--group mt-4', {
                'input--group--error': false,
              })}
            >
              <label htmlFor="">Roles</label>
              <Controller
                name="roles"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Select
                    ref={field.ref}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    options={availableRoles}
                    isMulti
                    closeMenuOnSelect={false}
                  />
                )}
              />
              {errors.roles && errors.roles?.type === 'required' && (
                <span className="error">At least one role is required</span>
              )}
            </div>

            {hasProvider && (
              <div
                className={classNames('input--group mt-4', {
                  'input--group--error': false,
                })}
              >
                <label htmlFor="">Provider</label>
                <Controller
                  name="provider"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <Select
                      ref={field.ref}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      options={availableProviders() as any}
                      closeMenuOnSelect={false}
                    />
                  )}
                />
                {errors.provider && errors.provider?.type === 'required' && (
                  <span className="error">Provider is required</span>
                )}
              </div>
            )}

            <button type="submit" className="mt-4">
              Submit
            </button>
          </div>
        </form>
      </div>

      <PortalFooter />
    </CreateUserDiv>
  );
};

const TextInput = ({
  errors,
  register,
  name,
  field,
  type = 'text',
  autoComplete,
}: {
  errors: FieldErrors<FormData>;
  register: UseFormRegisterReturn;
  name: string;
  field: Partial<keyof FormData>;
} & InputHTMLAttributes<HTMLInputElement>) => {
  const { auth } = useMst();
  return (
    <div
      className={classNames('input--group', {
        'input--group--error': errors[field] || auth.createUserErrors[field],
      })}
    >
      <label htmlFor="">{name}</label>
      <input type={type} {...register} autoComplete={autoComplete} />
      {errors[field] && errors[field]?.type === 'required' && (
        <span className="error">{name} is required</span>
      )}
      {errors[field] &&
        errors[field]?.type === 'pattern' &&
        field === 'emailAddress' && (
          <span className="error">{name} must be a valid email address</span>
        )}
      {auth.createUserErrors &&
        auth.createUserErrors[field] &&
        auth.createUserErrors?.[field]?.map((err, idx) => {
          return (
            <span className="error" key={`error${field}${idx}`}>
              {err}
            </span>
          );
        })}
    </div>
  );
};

export const CreateUserScreen = tokenProtected(
  roleProtected(CreateUser, 'superAdmin')
);

// export const EnduserSummaryScreen = observer(EnduserSummary);
