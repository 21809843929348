import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PortalFooter, PortalHeader } from '@PillPez/components';
import { observer } from 'mobx-react-lite';
import { Route, Routes, NavLink } from 'react-router-dom';
import { Details } from '@PillPez/components/Enduser/Details';
import { Adherence } from '@PillPez/components/Enduser/Adherence';
import { Scheduler } from '@PillPez/components/Enduser/Scheduler';
import { CareTeam } from '@PillPez/components/Enduser/CareTeam';
import { Device } from '@PillPez/components/Enduser/Device';
import { useMst } from '@PillPez/models';

export const EnduserDetailsScreen = () => {
  const { enduserId } = useParams();
  const { endUserView } = useMst();
  const [userDetails, setUserDetails] = useState<any>([]);

  const getEndUser = async (id: string) => {
    if (endUserView.loading) return;
    let resp = await endUserView.viewEndUser(id);
    setUserDetails(resp?.data.enduser || []);
  };

  const actionFunc = () => {
    getEndUser(enduserId as string)
  }

  useEffect(() => {
    getEndUser(enduserId as string);
    console.log(userDetails);
  }, [enduserId]);

  return (
    <div
      id="resident-details"
      className="portal-screen provider resident-details"
    >
      <PortalHeader />

      <div className="content">
        <div className="heading">
          <p className="title">
            {userDetails?.firstName} {userDetails?.lastName}
          </p>
          <p className="id">ID# {userDetails?.uniqueId}</p>
        </div>
        <div className="details-navbar">
          <ul>
            <li>
              <NavLink to="details">End-User Details</NavLink>
            </li>
            <li>
              <NavLink to="adherence">Adherence</NavLink>
            </li>
            <li>
              <NavLink to="schedule">Schedule</NavLink>
            </li>
            <li>
              <NavLink to="care-team">Caregiver</NavLink>
            </li>
            <li>
              <NavLink to="device">Device</NavLink>
            </li>
          </ul>
        </div>

        <Routes>
          <Route path="details" element={<Details
            userData={userDetails}
            actionFunc={actionFunc}
          />} />
          <Route path="adherence" element={<Adherence />} />
          <Route path="schedule" element={<Scheduler
            userData={userDetails}
            actionFunc={actionFunc}
          />} />
          <Route path="care-team" element={<CareTeam
            userData={userDetails}
            actionFunc={actionFunc}
          />} />
          <Route path="device" element={<Device userData={userDetails} actionFunc={actionFunc} />} />
        </Routes >
      </div >
      <PortalFooter />
    </div >
  );
};

// export const EnduserDetailsScreen = observer(EnduserDetails);
