import axios from 'axios';
import { getEnv } from 'mobx-state-tree';
import { API_URL } from './config';
import { rootStore } from './models/Root';
import { IRootEnv } from './models/env';

export const httpClient = axios.create({
  baseURL: API_URL,
});

httpClient.interceptors.request.use((config) => {
  const token = rootStore?.auth?.token;
  if (token && config.headers?.common) {
    config.headers.common.Authorization = `Bearer ${token}`;
  }
  return config;
});

// watch for the X-Access-Token header and console log it
httpClient.interceptors.response.use(
  (response) => {
    if (response.headers['x-access-token']) {
      rootStore.auth.setToken(response.headers['x-access-token']);
    }
    return response;
  },
  (error) => {
    let doNotRedirect = ['/caregiver-register', '/login'];
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      rootStore.auth.logout();
      let currentUrl = window.location.pathname;
      if (!doNotRedirect.includes(currentUrl)) {
        getEnv<IRootEnv>(rootStore).history.push('/login');
      }
    }
    return Promise.reject(error);
  }
);
