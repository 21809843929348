import styled from 'styled-components';

export const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;

  .portal-white-button,
  .portal-button {
    width: 170px;
    height: 40px;
  }

  .portal-button {
    justify-content: center;
    margin-left: 10px;
  }
`;

export const Message = styled.div`
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: center;
`;

export const ModalWrapper = styled.div`

  width: 525px;
  height: 193px;
  padding: 43px 35px 28px;
`;

export const ConfirmationMessage = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -1px;
  color: #002E6F;
  justify-content: center;
`;

export const SecondaryMessage = styled.p`
  margin-top: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #002E6F;
  letter-spacing: 0.4px;
  text-align: center;
  justify-content: center;
`;

