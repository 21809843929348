import React from 'react';
import { useMst } from '@PillPez/models';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

export function roleProtected(Component: React.FunctionComponent | JSX.Element, roles: string | string[]): any {
    function Wrapper() {
        const { auth } = useMst();
        const location = useLocation()
        const navigate = useNavigate();
        useEffect(() => {
            if (location.pathname === '/login') {
                return;
            }
            if (!auth.user || !auth.user.isActive) {
                let path = location.pathname;
                auth.setReturnTo(path);
                navigate('/login?returnTo=' + path);
            }

            if (!auth.checkRoles(roles)) {
                navigate('/')
            }

        }, [auth, navigate, location])
        if (!auth.token || !auth.user || !auth.user.isActive) {
            return React.createElement(React.Fragment)
        }
        return React.createElement(observer(Component as any) as any);
    }
    return typeof Component === 'function' ? Wrapper : React.createElement(Wrapper as any);
}
